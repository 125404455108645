<template>
    <div class="collapse_box" :class="customClass">
        <el-collapse :accordion="accordion" v-model="activeNames">
            <el-collapse-item v-for="(item, index) in collapseList" :key="index" :name="index">
                <template slot="title">
                    <div class="collapse_title-container" @click.stop="toggle(index)">
                        <i
                            v-if="showIcons"
                            :class="['title_icon', { 'el-icon-minus': activeNames.includes(index), 'el-icon-plus': !activeNames.includes(index) }]"></i>
                        <span v-html="item.titleRichText"></span>
                    </div>
                </template>
                <div v-html="item.contentRichText"></div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
export default {
    props: {
        accordion: {
            type: Boolean,
            default: false
        },
        collapseList: {
            type: Array,
            default: () => []
        },
        customClass: {
            type: String,
            default: ''
        },
        showIcons:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            activeNames: []
        }
    },
    methods: {
        toggle(index) {
            const idx = this.activeNames.indexOf(index);
            if (idx === -1) {
                this.activeNames.push(index);
            } else {
                this.activeNames.splice(idx, 1);
            }
        }
    }
}
</script>

<style lang="scss">
.collapse_title-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .title_icon {
        margin-right: 20px;
        transition: all .3s;
    }
}
</style>