<template>
	<basic-container>
		<div class="box">
			<div class="swiper_box">
				<el-carousel height="400px" 　width='100%'>
					<el-carousel-item v-for="(item, itemIndex) in bannerList" :key="itemIndex">
						<el-link :underline='false' :href="item.fieldB" target="view_window">
							<el-image :src="item.fieldA" fit="cover"
								style="width: 100%; height: 400px;min-height: 400px;"></el-image>
						</el-link>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="box_content">
				<div>
					<el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: 14px;">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>学术会议</el-breadcrumb-item>
						<el-breadcrumb-item>{{ yearList[tagIndex1] }}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<!-- 会议类型 -->
				<div class="top">
					<el-card class="box-card">
						<div style="display: flex;">
							<div>
								<p style="width: 80px;text-align: center; font-weight: normal;color: black;">年份</p>
							</div>
							<div>
								<div class="metting-card" style="padding-top: 10px;">
									<div>
										<div v-for="(item, index) in yearList" :key="index" class="tagC"
											style="margin-right: 10px;" @click="tagCheck1(item, index)"
											:class="{ tagChecked: tagIndex1 == index }">{{ item }}</div>
									</div>
								</div>
							</div>
						</div>
					</el-card>
				</div>
				
				<div class="top metting-card card-b meetingLoading">
					<el-card shadow="hover" class="box-card card-c" :body-style="{ padding: '0px' }"
						v-for="(item, index) in meetintFilterList" :key="index">
						<el-image style="width: 100%;height: 140px;cursor: pointer;"
							:src="'https://iamset.cn/file/' + item.imageUrl"
							@click="meetingDetails(item.website)"></el-image>
						<div style="padding: 14px;position: relative;">
							<div class="two" style="margin-bottom: 6px;min-height: 42px;">
								<span style="font-weight: bold;">{{ item.meetingName }}</span>
								<el-image :src="require('@/assets/img/hot.gif')" class="index-meeting"
									v-if="item.state == '火'">
								</el-image>
							</div>
							<div class="tag-sci" v-if="item.dbType != ''">
								{{ item.dbType }}
							</div>
							<div class="two" style="margin: 8px 0;">
								<i class="el-icon-time" style="margin-right: 8px;"></i>
								<span>{{ item.duringTime }}</span>
							</div>
							<div class="two" v-if="item.address != '会后征稿中...'">
								<i class="el-icon-location-outline" style="margin-right: 8px;"></i>
								<span>{{ item.address }}</span>
							</div>
							<div class="two" v-else>
								<i class="el-icon-copy-document" style="margin-right: 8px;"></i>
								<span>{{ item.address }}</span>
							</div>
						</div>
					</el-card>
				</div>
			</div>
		</div>
	</basic-container>
</template>

<script>
import {
	getBanner1,


} from '@/api/index/index'
import {
	// getMType1,
	getMeetingList,
	getType
} from '@/api/meeting/index'
export default {
	name: 'meeting',
	data() {
		return {
			bannerList: [], //轮播图
			meetingType: [], //会议类型
			yearList: [],//年份
			meetingSearch: "",
			meetintList: [],
			meetintFilterList: [],
			meetingYear: [{
				value: '0',
				name: '2024',
			},
			{
				value: '1',
				name: '2023',
			},
			{
				value: '2',
				name: '2022',
			}
			], //会议年份
			tagIndex: 0, //会议类型下标
			tagIndex1: 0, //会议年份下标
			paramMap: {
				pageNo: 1,
				pageSize: 200,
				meetingType: '',
				year: '',
				classification: "meetingBanner"
			}
		}
	},
	mounted() {
		this.getBanner()
		this.getMeeting()
		this.getMeetingType()
	},

	methods: {
		//获取轮播图数据
		getBanner() {
			getBanner1(this.paramMap).then((res) => {
				console.log(res);
				this.bannerList = res.data
			})

		},
		//获取会议类型
		getMeetingType() {
			getType().then((res) => {
				// this.meetingType = res.data.map(item => (item.fieldF))
				// this.meetingType.splice(0, 0, '全部')
				res.data.typeList.splice(0, 0, '全部')
				res.data.year.reverse()
				res.data.year.splice(0, 0, '全部')
				this.meetingType = res.data.typeList

				this.yearList = res.data.year
			})
		},
		//获取会议列表
		getMeeting() {
			const loading = this.$loading({
				lock: true,
				text: '加载中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.1)'
			});
			this.paramMap.classification = '111111'
			getMeetingList(this.paramMap).then((res) => {

				this.meetintList = res.data;
				this.meetintFilterList = res.data;
				loading.close();
			})
		},
		//打开会议列表图片
		meetingDetails(index) {
			window.open(index);
		},
		//会议类型选择
		tagCheck(item, index) {
			if (index === 0) {
				this.paramMap.meetingType = ''
			} else {
				this.paramMap.meetingType = item
			}
			this.tagIndex = index;
			// this.createFilter();
			this.getMeeting()
		},
		// 会议年份选择
		tagCheck1(item, index) {
			if (index === 0) {
				this.paramMap.year = ''
			} else {
				this.paramMap.year = item
			}
			this.tagIndex1 = index;
			// this.createFilter();
			this.getMeeting()
		},
		//筛选会议类型
		createFilter() {

			/* let _this = this
			let nowDate = new Date()
			let filter1 = new Array();
			let filter2 = new Array();
			let filter3 = new Array();
			if (_this.tagIndex != 0) {
				_this.meetintList.filter(function (item) {
					if (item.fieldF == _this.meetingType[_this.tagIndex]) {
						filter1.push(item)
					}
				})
			} else {
				filter1 = _this.meetintList
			}
			_this.meetintFilterList = []
			_this.meetintFilterList = filter1 */
		},
	}
}
</script>

<style scoped lang="scss">
.box {
	width: 100%;
	min-width: 1200px;
	display: flex;
	flex-direction: column;
}

.tag-sci {
	font-size: 14px;
	background-color: #375691;
	display: inline-block;
	color: white;
	padding: 4px 10px;
	margin: 4px 0;
	border-radius: 2px;
}

.swiper_box {}


.box_content {
	width: 1200px;
	min-width: 1200px;
	margin: 0 auto;
	margin-top: 30px;
}

.top {
	margin-top: 30px;
}


.metting-card {
	display: flex;
	flex-wrap: wrap;
	// padding-bottom: 20px;
}

.tagC {
	height: 32px;
	border: none;
	background-color: #ededed;
	border-radius: 4px;
	color: #464646;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	line-height: 21px;
	padding: 5px 15px;
	font-weight: 700;
	box-sizing: border-box;
	white-space: nowrap;
}

.tagChecked {
	background-color: #375691;
	color: white;
}

.meetingLoading {
	display: flex;
	margin-bottom: 30px;
}

.card-b {

	.el-card {
		width: calc(calc(100% - 88px)/4);
		margin: 20px 10px;

		::v-deep .el-image__inner {
			transition: all .3s ease;
		}

		&:hover {
			::v-deep .el-image__inner {
				cursor: pointer;
				transition: all .3s;
				transform: scale(1.1);
			}
		}
	}

	/* 
	.card-c {
		margin-top: 20px;
		margin-right: 15px;
	} */

}

.index-meeting {
	position: absolute;
	right: 10px;
	top: 0;
}
</style>