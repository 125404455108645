<template>
    <div class="contactus_box">
        <div class="contactus_box_box">
            <div class="contactus_box_left">
                <div class="left_title">联系我们</div>
                <div class="left_desc">
                    <div>请联系我们的会议小编，获取更合适您的服务方案</div>
                </div>
            </div>
            <div class="contactus_box_right">
                <span class="image_name">扫码添加会议小编</span>
                <img src="../../assets/img/xueshuhuiyixiaobian.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
}
</script>
<style lang='scss'>
.contactus_box {
   background: linear-gradient(rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.2)), url(../../assets/img/call_us_bj.jpg) no-repeat;

    .contactus_box_box {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 1200px;
        margin: 0 auto;
        padding: 50px 0;

        .contactus_box_left {
            margin-right: 67px;

            .left_title {
                font-size: 30px;
                color: black;
                font-weight: 600;
            }

            .left_desc {
                font-size: 16px;
                margin: 20px 0;
                margin-bottom: 40px;
            }

            .left_content {

                .left_phone,
                .left_email,
                .left_qq {
                    margin-bottom: 10px;
                    line-height: 1.6;

                    a {
                        background-color: #18305b;
                        color: white;
                        font-size: 14px;
                        padding: 10px 20px;
                        text-decoration: none;
                        border: 1px solid #d9d9d9;
                        border-radius: 5px;
                        transition: all .3s;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }

                .sm_title {
                    font-size: 18px;
                    font-weight: 600;
                }

                .strong_text {
                    font-size: 17px;
                    color: #666;
                }
            }
        }

        .contactus_box_right {
            transform: translateY(-27px);
            display: flex;
            flex-direction: column;
            align-items: center;

            .image_name {
                margin-bottom: 10px;
            }

            img {
                width: 120px;
                vertical-align: bottom;
            }
        }
    }
}
</style>