<template>
    <basic-container>
        <bannerVue :item="3"></bannerVue>
        <service-menus></service-menus>
        <div class="diff_box">
            <div class="main">
                <div class="business_box_d">
                    <serviceContent>
                        <template #content>
                            <div class="content_1">
                                <div class="content_title">业务简介</div>
                                <div class="content_desc">
                                    我们提供本科毕业论文、期刊论文、硕、博研究生论文、会议论文降低重复率，格式调整等服务。<br>
                                    10年来，已经帮助数万位学子解决论文抄袭率，论文格式，论文重复率高等难题！<br>
                                    强大的一手老师资源，首创安全保密降重流程，充分保障客户，隐私和利益！
                                </div>
                            </div>
                            <div class="content_2">
                                <div class="content_title">
                                    适合稿件
                                </div>
                                <div class="content_desc">
                                    适合本硕博毕业论文或学术论文，各类中高级职称论文及学术研究报告。
                                </div>
                            </div>
                        </template>
                    </serviceContent>
                </div>
            </div>

            <div style="margin: 0 auto;" class="sele_form_box">
                <div class="loading" style="width: 960px;margin: 10px auto;">
                    <div>
                        <div style="margin: 40px auto;margin-bottom:20px;">
                            <div style="margin-top: 40px;">
                                <div style="text-align: center;">
                                    <p class="sel_title form_title">论文查重委托书</p>
                                    <!-- <el-divider style="margin: auto;width: 80%;"></el-divider> -->
                                </div>
                                <div style="margin: 20px auto;" class="diff_form">
                                    <el-form ref="releaseForm" :model="releaseForm" :rules="rules" :inline="true"
                                        label-width="100px">
                                        <el-form-item label="委托人" style="width: 48%;" required prop="authorName">
                                            <el-input v-model="releaseForm.authorName"></el-input>
                                        </el-form-item>
                                        <el-form-item label="委托单位" style="width: 48%;" required prop="unitName">
                                            <el-input v-model="releaseForm.unitName"></el-input>
                                        </el-form-item>
                                        <el-form-item label="联系邮箱" style="width: 48%;" required prop="eMail">
                                            <el-input v-model="releaseForm.eMail"></el-input>
                                        </el-form-item>
                                        <el-form-item label="联系电话" style="width: 48%;" required prop="phone">
                                            <el-input v-model="releaseForm.phone"></el-input>
                                        </el-form-item>
                                        <div class="tips_boxs">
                                            <el-form-item style="width: 100%; ">
                                                <el-upload class="upload-demo" ref="upload" action="/joi/upload/file"
                                                    :on-remove="handleRemove" :on-success="onSuccess" multiple
                                                    :limit="1" :on-exceed="handleExceed" :file-list="fileList">
                                                    <el-button size="medium" class="form_box_btn">上传文档</el-button>
                                                    <template #tip>
                                                        <div class="el-upload__tip" style="color: #606266">
                                                            注：上传内容建议去除封面、目录、附录，仅上传正文
                                                        </div>
                                                    </template>
                                                </el-upload>
                                            </el-form-item>
                                        </div>
                                        <el-form-item class="submit_btn">
                                            <el-button type="primary" @click="onSubmit('releaseForm')"
                                                style="background-color: #18305b; width: 150px">提交</el-button>
                                        </el-form-item>
                                    </el-form>
                                    <div style="margin-left: 100px;">
                                        <p class="desc_tips_title_">
                                            <img src="../../assets/img/tips.gif" alt="">
                                            注意事项
                                        </p>
                                        <div class="desc_tips_">
                                            <p>1. 检测系统支持.zip .rar .doc .docx .txt .pdf等格式</p>
                                            <p>2. 上传的论文文档，请按此格式命名：“作者姓名_文档名称”</p>
                                            <p>3. 单篇文章大小不得超过10M，字符数不得超过10W，批量检测压缩包不得大于100M</p>
                                            <p>4. 检测结束后，工作人员会把检测报告发送至委托人邮箱，请注意查收</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-top: 80px;">
                        <keepSecret></keepSecret>
                    </div>
                </div>
            </div>

           
        </div>
    </basic-container>
</template>
<script>
import serviceMenus from "@/components/common/serviceMenus"
import keepSecret from "@/components/common/keepSecret.vue";
import serviceTitle from "@/components/common/serviceTitle.vue";
import serviceContent from "@/components/common/serviceContent.vue";
import bannerVue from "./banner.vue"
import {
    translateUp
} from '@/api/service/translate'
import { get } from "jquery";
export default {
    components: {
        'service-menus': serviceMenus,
        keepSecret,
        serviceTitle,
        serviceContent,
        bannerVue
    },
    data() {
        return {
            releaseForm: {
                authorName: "",
                eMail: "",
                phone: "",
                unitName: "",
                spareFieldO: "",
                spareFieldT: "cc"
            },
            rules: {
                authorName: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入委托人"
                },],

                eMail: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入联系邮箱"
                },],
                phone: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入联系电话"
                },],
                unitName: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入委托单位"
                },],

            },
            fileList: [],
        };
    },
    methods: {
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        onSuccess(esponse, file, fileList) {
            this.fileList = fileList;
            this.releaseForm.spareFieldO = this.fileList[0].response.data
        },
        handleRemove(file, fileList) {
            this.fileList = fileList;
            this.releaseForm.spareFieldO = null
        },
        onSubmit(releaseForm) {
            if (!this.$store.getters.getUserInfo) {
                this.$router.push({ path: '/login' });
                return
            }
            this.$refs[releaseForm].validate((valid) => {
                if (valid) {
                    let _this = this;

                    translateUp("addOnlineSubmission", this.releaseForm, "loading")
                        .then(function (res) {
                            console.log(res)
                            if (res.code == 200) {
                                _this.$message.success({
                                    showClose: true,
                                    duration: 1500,
                                    message: res.msg,
                                    type: "success",
                                    onClose: function () {
                                        _this.$refs[releaseForm].resetFields();
                                        _this.$refs['upload'].clearFiles();
                                    },
                                });
                            }

                        })
                        .catch(function () { });
                } else {
                    //   this.$message.error("请检查必填项！");
                    this.$notify.error({
                        title: "错误",
                        message: "请检查必填项！",
                    });
                    return false;
                }
            });
        },
        getUserInfo() {
            let userInfo = this.$store.getters.getUserInfo
        }

    }
}
</script>

<style lang="scss" scoped>
.diff_box {
    min-width: 1200px;
}

.sele_form_box {
    background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(../../assets/img/selective_form_bj.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 0;
}

.form_box_btn {
    background-color: white;
    color: #666666;

    &:hover {
        background-color: #18305b;
        color: white;
    }
}

.desc_tips_ {
    line-height: 1.2;
    color: rgb(51, 51, 51);
    font-size: 14px;
}

.desc_tips_title_ {
    color: #b70000;
    margin: 16px 0;
    display: flex;
    align-items: flex-start;

    img{
        margin-right: 10px;
    }
}

.bm_xy {
    text-indent: 35px;
    margin-top: 0px;
    font-size: 14px;
    color: rgb(96, 98, 102);
}

.diff_desc {
    width: 75%;
    margin: 0 auto;
    color: #252B3A;
    font-size: 14px;
    text-align: center;
    padding: 0;
    line-height: 1.5;
}

::v-deep .diff_form .el-form-item__content {
    width: 75%;
}

.diff_form {
    .tips_boxs {
        margin-left: 100px;
    }

    .submit_btn {
        width: 100%;

        ::v-deep .el-form-item__content {
            width: 100%;
            text-align: right;

            .el-button {
                margin-right: 38px;
            }
        }
    }
}

.swiper_box {
    min-width: 1200px;
    box-sizing: border-box;
}

.sel_title,
.form_title {
    color: #333333;
    text-align: center;
    font-size: 28px;
    margin: 50px 0;
    font-weight: 600;
}

.form_title {
    font-size: 20px;
    font-weight: 400;
}

.main {
    min-width: 1000px;
    width: 1200px;
    padding: 20px;
    padding-top: 0;
    margin: 0px auto;
    max-width: 1600px;
    background-color: #fff;
    box-sizing: border-box;

    .title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }

    .select_box {
        width: 200px;
        margin-left: 400px;
        margin-top: 20px;
    }

    .dashed {
        border-style: dashed;
        border-width: 1px;
        border-color: #C4C4C4;
        // margin: 0 200px;
    }
}

.business_box_d {
    .box_title {
        color: #333333;
        text-align: center;
        font-size: 28px;
        // padding: 20px 0;
        margin: 50px 0;
        margin-top: 20px;
        font-weight: 600;
    }

    .content_1{
        margin-top: 40px;
    }

    .content_2 {
        margin-top: 40px;
    }
}
</style>