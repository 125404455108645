<template>
	<div class="headerContainer">
		<div class="header_top">
			<div class="headerContainer_top">
				<div class="headerContainer_top1">
					<div style="margin-right: 30px;">
						<img :src="require('@/assets/img/phone.png')" alt="">
						0371-61319290 / 0371-63360580
					</div>
				</div>
				<div style="width: 10%;"></div>
				<!-- 佘：修改文字与div的宽度 -->
				<div class="headerContainer_top1">
					<div><img :src="require('@/assets/img/clock.png')" alt="" style="width: 18px;">
						工作时间 Mon - Fri：08:30-17:30</div>
				</div>
			</div>
		</div>
		<div class="header_bottom_box" v-sticky-top="{ topOffset: 0 }">
			<div class="headerContainer_bottom">
				<div class="metting-head-left pointer" @click="handleSelect('/')">
					<el-image :src="require('@/assets/img/logo.png')" style="width: 200px;"></el-image>
				</div>
				<!-- <img src="../../assets/img/logo.png" alt="" width="200px"> -->
				<div class="menu">
					<!-- 佘：更改期刊索引与会议的顺序， 期刊索引更改为期刊搜索-->
					<el-menu :default-active="activeIndex" @select="handleSelect" :text-color="'#262626'"
						class="el-menu-demo" active-text-color="#1b5faa" mode="horizontal" menu-trigger="hover"
						:unique-opened="true">
						<el-menu-item index="/index">首页</el-menu-item>
						<el-submenu index="/journal">
							<template #title class="/journal">期刊搜索</template>
							<el-menu-item index="/journalSci">SCI期刊</el-menu-item>
							<el-menu-item index="/journalAHCI">AHCI期刊</el-menu-item>
							<el-menu-item index="/journalEsci">ESCI期刊</el-menu-item>
						</el-submenu>
						<el-menu-item index="/meeting">学术会议</el-menu-item>
						<el-submenu index="/service" popper-class="custom-submenu">
							<template #title>学术服务</template>
							<!-- <el-menu-item index="onlineMatching">在线匹配</el-menu-item> -->
							<el-menu-item index="/translate">论文翻译</el-menu-item>
							<el-menu-item index="/polishing">论文润色</el-menu-item>
							<el-menu-item index="/selective">选刊服务</el-menu-item>
							<el-menu-item index="/difference">查重服务</el-menu-item>
							<el-menu-item index="/retrieval">检索证明</el-menu-item>
							<!-- <el-menu-item index="/contribution">在线匹配</el-menu-item> -->
						</el-submenu>
						<el-menu-item index="/news">
							新闻动态
						</el-menu-item>
						<el-menu-item index="/about">关于我们</el-menu-item>
					</el-menu>
				</div>
				<div class="menu_popover">
					<el-popover placement="bottom" title="" width="158" trigger="hover"
						:popper-class="'index_popover_box'">
						<div class="index_popover" slot="reference">
							<img src="../../assets/img/qr-code-nav-icon.svg" alt="">
							<i class="el-icon-caret-bottom"></i>
						</div>

						<div class="popover_content">
							<span>扫码添加学术服务小编 获取更适合您的服务方案</span>
							<img src="../../assets/img/xueshuxiaobian.jpg" alt="">
						</div>
					</el-popover>
				</div>
				<div class="metting-head-right" v-if="!isLogin">
					<el-button @click="login">登录</el-button>
				</div>
				<div class="metting-head-right" v-else>
					<div class="users" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
						<div v-if="users && users.avatar" style="display: flex; align-items: center;">
							<div
								style="padding-right: 10px; width: 60px; white-space: nowrap;  overflow: hidden; text-overflow: ellipsis;">
								{{ users.nickName }}
							</div>
							<el-avatar :size="50"
								:src="'https://iamset.cn/file/' + users.avatar"></el-avatar>
						</div>
						<div v-else class="flex_box">
							<div class="avatar" :style="{ backgroundColor: selectedColor }">
								{{ name }}
							</div>
							<div v-if="isMouseOver">
								<i class="el-icon-arrow-up"></i>
							</div>
							<div v-else>
								<i class="el-icon-arrow-down"></i>
							</div>
						</div>
						<div class="user_hover pointer">
							<div class="hover_item" @click="tipUser">
								<div>
									<i class="el-icon-user-solid"></i>个人主页
								</div>
							</div>
							<div class="hover_item" @click="loginOut">
								<div>
									<i class="el-icon-position"></i> 退出登录
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { loginOut } from '@/api/login/login'
export default {
	directives: {
		'sticky-top': {
			inserted: function (el, binding) {
				const topOffset = binding.value.topOffset || 0; // 获取传入的topOffset参数，默认为0
				const stickyClass = 'sticky';
				const stickyTop = el.offsetTop - topOffset;

				const scrollHandler = function () {
					if (window.pageYOffset > stickyTop) {
						el.classList.add(stickyClass);
					} else {
						el.classList.remove(stickyClass);
					}
				};

				window.addEventListener('scroll', scrollHandler);
			}
		}
	},
	name: 'influenceHeader',
	data() {
		return {
			activeIndex: '1',
			//是否登录
			isLogin: this.$store.getters.getIsLogin,
			userInfo: this.$store.getters.getTokenInfo,
			users: null,
			name: null,
			backList: [
				"#5470c6",
				"#91cc75",
				"#fac858",
				"#ee6666",
				"#73c0de",
				"#3ba272",
				"#fc8452",
				"#9a60b4",
				"#ea7ccc"
			],
			selectedColor: '#5470c6',
			isMouseOver: false
		}

	},
	mounted() {
		const users = localStorage.getItem('userInfo'); // 使用localStorage或sessionStorage
		if (users) {
			this.users = JSON.parse(users)
		}
		this.getName()

	},
	watch: {
		// 监听路由变化
		"$route.path": {
			handler(routePath) {
				this.initMenuActive(routePath);
			},
			immediate: true,
		},
		//监听登录状态
		"$store.getters.getIsLogin": {
			handler(isLogin) {
				this.isLogin = isLogin
			},
			immediate: true,
		},

	},
	methods: {
		handleMouseOver() {
			this.isMouseOver = true;
		},
		handleMouseOut() {
			this.isMouseOver = false;
		},
		randomSelect() {
			const randomIndex = Math.floor(Math.random() * this.backList.length); // 随机生成一个索引
			this.selectedColor = this.backList[randomIndex]; // 通过索引选取一个颜色
		},
		initMenuActive(routePath) {
			this.activeIndex = routePath;
			if (routePath == '/') {
				this.activeIndex = '/index'

			}

		},
		//退出登录
		loginOut() {
			loginOut()
				.then(res => {
					this.$store.commit('setTokenInfo', "")
					this.$store.commit('setUserInfo', "")
					this.$store.commit('setIsLogin', "")
					this.$message.success({
						showClose: true,
						duration: 1500,
						message: "成功退出",
					});

				})
				.catch(function () { });
		},
		// 导航栏跳转
		handleSelect(index) {
			// 获取当前路由的路径
			const currentPath = this.$route.path;
			// 如果当前路由路径不等于目标路由路径，则进行路由跳转
			if (currentPath !== index) {
				this.$router.push(index);

				window.scroll({
					top: 0, left: 0, behavior: 'smooth'
				});
			} else {
				// 当前路由路径与目标路由路径相同，无需重复导航
				console.log('Already on the same route');
			}
		},
		//登录
		login() {
			this.$router.push('/login')
		},
		//跳个人中心
		tipUser() {
			this.$router.push('/personal')
		},

		getName() {

			if (!this.users.avatar) {
				if (this.users.name) {
					this.name = this.users.nickName.substr(0, 1);
				} else {
					// 如果 this.users.name 不存在或为空的处理逻辑
				}
				this.randomSelect();
			}
		}

	}

}
</script>

<style lang="scss" scoped>
.headerContainer {
	width: 100%;
	min-width: 1200px;
	display: flex;
	flex-direction: column;
}

.header_top {
	width: 100%;
	background-color: rgb(24, 48, 91);
}

.headerContainer_top {
	width: 1200px;
	padding: 15px 0px;
	margin: 0 auto;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.headerContainer_top1 {
	display: flex;
	text-wrap: nowrap;
}

.headerContainer_top1>div {
	display: flex;
	align-items: center;
}

.headerContainer_top1 img {
	width: 20px;
	margin-right: 5px;
}

.header_bottom_box {
	width: 100%;
}

.headerContainer_bottom {
	padding: 10px 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 1200px;
	margin: 0 auto;
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	background-color: #fff;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.menu {
	display: flex;
	// width: 60%;
}

.menu_popover {
	margin-left: 80px;

	.index_popover {
		display: flex;
		align-items: center;

		i {
			margin-left: 4px;
		}
	}
}

.popover_content {
	display: flex;
	flex-direction: column;
	align-items: center;

	span {
		width: 154px;
		text-align: center;
	}

	img {
		margin-top: 10px;
		width: 154px;
		height: 154px;
	}
}

.metting-head-left {
	// width: 18%;
	display: flex;
	align-items: center;
	position: relative;
}

/* .metting-head-right {
	width: 16%;
} */

.headerContainer_bottom img {
	// width: 14%;
}

.menu>.el-menu.el-menu--horizontal {
	border: none;
}

.menu>.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
	color: #1b5faa !important;
}

.menu .el-menu-item {
	font-size: 16px;
	padding: 0 30px;
}

.menu>.el-menu--horizontal>.el-submenu:hover .el-submenu__title {
	color: rgb(24, 48, 91);
}

::v-deep .menu .el-submenu__title {
	font-size: 16px;
}

::v-deep .metting-head-right .el-button {
	border: none;
	padding: 10px 26px;
	color: #ffffff;
	background-color: #bf7c16;

}

// .el-dropdown-menu__item,
.el-menu-item {
	font-size: 16px;
	text-align: center;
}

.el-submenu {
	min-width: none;
}

.users {
	position: relative;

	.flex_box {
		display: flex;
		align-items: center;
	}

	.avatar {
		border-radius: 50%;
		background-color: #ccc;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-evenly;
		font-size: 20px;
		color: #fff;
		position: relative;
		margin-right: 10px;
	}

	img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
	}
}

.user_hover {
	position: absolute;
	// top: 100%;
	// left: -50%;
	padding: 10px;
	background-color: #fff;
	z-index: 999;
	color: #000;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
	box-sizing: border-box;
	white-space: nowrap;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s ease, transform 0.3s ease;

	·:nth-child(1) {
		border-bottom: 1px solid #000;

	}

	.hover_item>div {
		padding: 10px;
		font-size: 16px;
	}
}

.hover_item :hover {
	background-color: #e7e7e7;
	/* 鼠标移入时背景颜色改变 */
}

.users:hover .user_hover {
	opacity: 1;
	pointer-events: auto;
}




/*  ::v-deep .el-menu--horizontal .el-menu .el-menu-item{
	font-size: 30px;
} */
</style>
<style>
.el-menu--popup {
	z-index: 100;
	min-width: 105px!important;
	border: none;
	padding: 5px;
	border-radius: var(--el-border-radius-small);
	-webkit-box-shadow: var(--el-box-shadow-light);
	box-shadow: var(--el-box-shadow-light);
}
</style>
