<template>
    <div class="service_menus">
        <div class="menus">
            <div class="menus_item" @click="getUrl('/translate')">
                <!-- <div class="menu-img">
                    <img src="@/assets/img/translate-icon.png" alt="">
                </div> -->
                <div class="title" :class="index === '/translate' ? 'active' : 'title'">
                    论文翻译
                </div>
            </div>
            <div class="menus_item" @click="getUrl('/polishing')">
                <!-- <div class="menu_img">
                    <img src="@/assets/img/polish-icon.png" alt="">
                </div> -->
                <div class="title" :class="index === '/polishing' ? 'active' : 'title'">
                    论文润色
                </div>
            </div>
            <div class="menus_item" @click="getUrl('/selective')">
                <!-- <div class="menu_img">
                    <img src="@/assets/img/select-icon.png" alt="">
                </div> -->
                <div class="title" :class="index === '/selective' ? 'active' : 'title'">
                    选刊服务
                </div>
            </div>
            <div class="menus_item" @click="getUrl('/difference')">
                <!-- <div class="menu_img">
                    <img src="@/assets/img/check-icon.png" alt="">
                </div> -->
                <div class="title" :class="index === '/difference' ? 'active' : 'title'">
                    查重服务
                </div>
            </div>
            <div class="menus_item" style="border: none;" @click="getUrl('/retrieval')">
                <div class="title" :class="index === '/retrieval' ? 'active' : 'title'">
                    检索证明
                </div>
            </div>
            <!-- <div class="menus_item" style="border: none;" @click="getUrl('/contribution')">
                <div class="title" :class="index === '/contribution' ? 'active' : 'title'">
                    在线投稿
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'serviceMenus',
    data() {
        props: ['menusIndex']
        return {
            index: '/translate'
        };
    },
    watch: {
        // 监听路由变化
        "$route.path": {
            handler(routePath) {
                this.initMenuActive(routePath);
            },
            immediate: true,
        },

    },
    methods: {
        initMenuActive(routePath) {
            this.index = routePath;

        },
        getUrl(url) {
            // 获取当前路由的路径
            const currentPath = this.$route.path;

            // 如果当前路由路径不等于目标路由路径，则进行路由跳转
            if (currentPath !== url) {
                this.$router.push(url);

                window.scroll({
					top: 380, left: 0, behavior: 'smooth'
				});
            } else {
                // 当前路由路径与目标路由路径相同，无需重复导航
                console.log('Already on the same route');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.service_menus{
    background-color: white;
    z-index: 999;
}
.menus {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 30px 20px 0;
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #efefef;

    .menus_item {
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        border-color: transparent;
        width: 15%;
        font-size: 20px;
        transition: all .3s;

        &:hover{
            cursor: pointer;
        }
    }

    .menu_img {
        width: 48px;
        height: 48px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .title {
        padding-bottom: 8px;
    }

    .active {
        border-bottom: 2px solid #1b5faa;
        color: rgb(27, 95, 170);
    }
}
</style>