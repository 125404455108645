<template>
    <basic-container>
        <div class="user_agreement_page">
            <div class="title">用户协议</div>
            <div>
                <div>
                    欢迎使用IAMSET（以下简称“本网站”）。通过访问和使用本网站，您（即“用户”）同意遵守以下服务协议（以下简称“本协议”）。如果您不同意这些条款，请勿使用本网站。
                </div>
                <div>
                    1. 账户注册
                </div>
                <div>
                    为了使用本网站的某些功能，用户需要注册一个账户。用户需提供准确、完整的信息，并及时更新。用户有责任保护其账户信息的安全，并对通过其账户发生的所有活动承担责任。
                </div>
                <div>
                    2. 用户行为规范
                </div>
                <div>
                    用户同意不从事以下行为：
                    发布任何非法、虚假、误导、或侵犯他人权利的内容；
                    上传病毒、恶意软件或任何可能干扰本网站正常运营的内容；
                    以任何形式滥用本网站的服务。
                </div>
                <div>
                    3. 隐私政策
                </div>
                <div>
                    本网站尊重用户的隐私权，并致力于保护用户的个人信息。请参阅我们的隐私政策，了解我们如何收集、使用、分享和保护您的个人信息。用户同意我们按照隐私政策的规定处理其个人信息。
                </div>
                <div>
                    4. 知识产权
                </div>
                <div>
                    本网站及其内容（包括但不限于文字、图像、软件、商标）归IAMSET及其许可方所有。未经书面许可，用户不得复制、修改、分发或以其他方式使用本网站的任何内容。
                </div>
                <div>
                    5. 内容使用
                </div>
                <div>
                    用户在本网站发布的内容仍归用户所有，但用户授予本网站非独占、全球范围、免版税的许可，允许本网站使用、展示、复制和分发该内容。用户保证其发布的内容不侵犯任何第三方的权利。
                </div>
                <div>
                    6. 服务变更
                </div>
                <div>
                    本网站保留在任何时间修改、暂停或终止服务的权利，而无需事先通知用户。对于因这些变动而导致的任何损失，本网站不承担责任。
                </div>
                <div>
                    7. 第三方链接
                </div>
                <div>
                    本网站可能包含第三方网站的链接。用户访问这些链接需自担风险。本网站对任何第三方网站的内容、隐私政策或做法不承担责任。
                </div>
                <div>
                    8. 免责声明
                </div>
                <div>
                    本网站及其内容按“现状”提供。本网站不对内容的准确性、完整性或适用性作任何明示或默示的保证。用户使用本网站所产生的任何风险由用户自行承担。
                </div>
                <div>
                    9. 责任限制
                </div>
                <div>
                    在适用法律允许的最大范围内，本网站对因使用或无法使用本网站服务而引起的任何间接、附带、特殊、后果性或惩罚性损害不承担责任。
                </div>
                <div>
                    10. 终止
                </div>
                <div>
                    如果用户违反本协议的任何条款，本网站有权立即终止用户的账户和使用权限，而无需事先通知。
                </div>
                <div>
                    11. 修订条款
                </div>
                <div>
                    本网站保留随时修订本协议的权利。修订后的协议将在本网站发布后立即生效。用户继续使用本网站即表示接受修订后的协议。
                </div>
                <div>
                    12. 联系信息
                </div>
                <div>
                    如有任何问题或需要支持，请联系学术服务小编。
                </div>
            </div>
        </div>
    </basic-container>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.user_agreement_page {
    width: 1200px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 2;
    padding: 40px 0;

    .title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
    }
}
</style>