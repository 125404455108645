import request from '@/utils/request';

export const login = (form) => {
  return request({
    url: '/joi/login',
    method: 'post',
    data: form
  })
}

export const loginOut = () => {
  return request({
    url: '/joi/loginOut',
    method: 'post',
    data: ''
  })
}


export const getRegister = (form) => {
  return request({
    url: '/joi/registerByPhone',
    method: 'post',
    data: form
  })
}

export const getVerificationCode = (form) => {
  return request({
    url: '/joi/sendVerificationCodeByPhone',
    method: 'post',
    data: form
  })
}


export const reset = (form) => {
  return request({
    url: '/joi/resetPasswordByPhone',
    method: 'post',
    data: form
  })
}

export const loginByPhone = (form) => {
  return request({
    url: '/joi/loginByPhone',
    method: 'post',
    data: form
  })
}