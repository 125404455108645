<template>
    <div>
        <el-dialog title="用户协议" :visible.sync="dialogVisible" width="30%">
            <div>
                <div>
                    欢迎使用IAMSET（以下简称“本网站”）。通过访问和使用本网站，您（即“用户”）同意遵守以下服务协议（以下简称“本协议”）。如果您不同意这些条款，请勿使用本网站。
                </div>
                <div>
                    1. 账户注册
                </div>
                <div>
                    为了使用本网站的某些功能，用户需要注册一个账户。用户需提供准确、完整的信息，并及时更新。用户有责任保护其账户信息的安全，并对通过其账户发生的所有活动承担责任。
                </div>
                <div>
                    2. 用户行为规范
                </div>
                <div>
                    用户同意不从事以下行为：
                    发布任何非法、虚假、误导、或侵犯他人权利的内容；
                    上传病毒、恶意软件或任何可能干扰本网站正常运营的内容；
                    以任何形式滥用本网站的服务。
                </div>
                <div>
                    3. 隐私政策
                </div>
                <div>
                    本网站尊重用户的隐私权，并致力于保护用户的个人信息。请参阅我们的隐私政策，了解我们如何收集、使用、分享和保护您的个人信息。用户同意我们按照隐私政策的规定处理其个人信息。
                </div>
                <div>
                    4. 知识产权
                </div>
                <div>
                    本网站及其内容（包括但不限于文字、图像、软件、商标）归IAMSET及其许可方所有。未经书面许可，用户不得复制、修改、分发或以其他方式使用本网站的任何内容。
                </div>
                <div>
                    5. 内容使用
                </div>
                <div>
                    用户在本网站发布的内容仍归用户所有，但用户授予本网站非独占、全球范围、免版税的许可，允许本网站使用、展示、复制和分发该内容。用户保证其发布的内容不侵犯任何第三方的权利。
                </div>
                <div>
                    6. 服务变更
                </div>
                <div>
                    本网站保留在任何时间修改、暂停或终止服务的权利，而无需事先通知用户。对于因这些变动而导致的任何损失，本网站不承担责任。
                </div>
                <div>
                    7. 第三方链接
                </div>
                <div>
                    本网站可能包含第三方网站的链接。用户访问这些链接需自担风险。本网站对任何第三方网站的内容、隐私政策或做法不承担责任。
                </div>
                <div>
                    8. 免责声明
                </div>
                <div>
                    本网站及其内容按“现状”提供。本网站不对内容的准确性、完整性或适用性作任何明示或默示的保证。用户使用本网站所产生的任何风险由用户自行承担。
                </div>
                <div>
                    9. 责任限制
                </div>
                <div>
                    在适用法律允许的最大范围内，本网站对因使用或无法使用本网站服务而引起的任何间接、附带、特殊、后果性或惩罚性损害不承担责任。
                </div>
                <div>
                    10. 终止
                </div>
                <div>
                    如果用户违反本协议的任何条款，本网站有权立即终止用户的账户和使用权限，而无需事先通知。
                </div>
                <div>
                    11. 修订条款
                </div>
                <div>
                    本网站保留随时修订本协议的权利。修订后的协议将在本网站发布后立即生效。用户继续使用本网站即表示接受修订后的协议。
                </div>
                <div>
                    12. 联系信息
                </div>
                <div>
                    如有任何问题或需要支持，请通过以下方式联系我们：service@iamset.cn。
                </div>
            </div>
        </el-dialog>
        <el-dialog title="隐私协议" :visible.sync="privacy" customClass="title" width="30%">
            <div>
                <div>
                    欢迎使用IAMSET（以下简称“本网站”）。通过访问和使用本网站，您（即“用户”）同意遵守以下服务协议（以下简称“本协议”）。如果您不同意这些条款，请勿使用本网站。
                </div>
                <div>
                    1. 简介
                </div>
                <div>
                    欢迎使用IAMSET（以下简称“本网站”）。我们重视您的隐私，并致力于保护您的个人信息。本隐私政策（“本政策”）描述了我们如何收集、使用、共享和保护您的信息。通过使用本网站，您同意按照本政策的规定处理您的信息。
                </div>
                <div>
                    2. 我们收集的信息
                </div>
                <div>
                    我们可能会收集和处理以下类型的信息：
                </div>
                <div>
                    2.1. 您提供的信息
                </div>
                <div>
                    注册信息：当您创建账户时，我们会收集您的用户名、电子邮件地址、密码等信息。
                    个人资料信息：您可以选择提供其他个人资料信息，如头像、联系方式、地址等。
                    通讯内容：当您与我们联系或参与我们提供的服务时，我们会收集您发送的消息和内容。
                </div>
                <div>
                    2.2. 自动收集的信息
                </div>
                <div>
                    使用数据：我们会自动收集您使用本网站时的信息，如访问的页面、点击的链接、搜索的内容等。
                    设备信息：我们会收集您的设备信息，包括设备类型、操作系统、浏览器类型和IP地址。
                </div>
                <div>
                    3. 我们如何使用信息
                </div>
                <div>
                    我们收集的信息用于以下目的：
                </div>
                <div>
                    提供和维护服务：使用您的信息来提供和改进我们的服务。
                    个性化体验：根据您的偏好和行为定制内容和广告。
                    通讯：与您联系以提供客户支持、发送重要通知和市场推广信息。
                    分析和改进：分析用户行为以改进我们的服务和用户体验。
                    法律合规：遵守适用的法律法规，处理和解决法律纠纷。
                </div>
                <div>
                    4. 信息共享和披露
                </div>
                <div>
                    我们不会向第三方出售您的个人信息。我们可能会在以下情况下共享您的信息：
                </div>
                <div>
                    本网站可能包含第三方网站的链接。用户访问这些链接需自担风险。本网站对任何第三方网站的内容、隐私政策或做法不承担责任。
                </div>
                <div>
                    服务提供商：与提供服务的第三方服务提供商共享信息，他们仅能按照我们的指示使用信息。
                    法律要求：在法律要求或允许的情况下，向执法机构、政府机构或其他第三方披露信息。
                    业务转让：在业务合并、收购或出售过程中，转让您的信息。
                </div>
                <div>
                    本网站及其内容按“现状”提供。本网站不对内容的准确性、完整性或适用性作任何明示或默示的保证。用户使用本网站所产生的任何风险由用户自行承担。
                </div>
                <div>
                    5. 信息安全
                </div>
                <div>
                    根据适用法律，您可能享有以下权利：
                </div>
                <div>
                    访问和更新信息：访问和更新我们持有的您的个人信息。
                    删除信息：请求删除您的个人信息。
                    限制处理：请求限制我们处理您的个人信息。
                    数据可携性：请求将您的个人信息转移给您或其他服务提供商。
                    撤回同意：撤回您对我们处理您的个人信息的同意，但不影响撤回前基于同意的处理。
                </div>
                <div>
                    如果用户违反本协议的任何条款，本网站有权立即终止用户的账户和使用权限，而无需事先通知。
                </div>
                <div>
                    7. 数据保留
                </div>
                <div>
                    我们会在实现本政策所述目的所需的时间内保留您的个人信息，除非法律要求或允许更长的保留期。
                </div>
                <div>
                    12. 联系信息
                </div>
                <div>
                    8. 隐私政策的变更
                </div>
                <div>
                    我们可能会不时更新本政策。任何变更将在本网站发布后立即生效。建议您定期查看本政策以了解最新的隐私实践。
                </div>
                <div>
                    9. 联系我们
                </div>
                <div>
                    如果您对本政策有任何疑问或需要支持，请通过以下方式联系我们：service@iamset.cn。
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {

    data() {
        return {
            dialogVisible: false,
            privacy: false,
            title: '',
        }
    },
    methods: {
        showDialog() {
            this.dialogVisible = true;
        },
        showDialog1() {
            this.privacy = true;
        },
        closeDialog() {
            this.dialogVisible = false;
            this.privacy = false
        },
        handleConfirm() {
            // 处理点击确定按钮后的逻辑
            this.closeDialog(); // 关闭弹出框
        }
    }
}
</script>
<style scoped lang="scss">
::v-deep .el-dialog__title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
::v-deep .el-dialog__header{
    text-align: center;
}
</style>