<template>
  <div id="app">
    <influenceHeader v-if="!logoPage"></influenceHeader>
    <router-view v-if="isRouterAlive"></router-view>
    <influenceBottom v-if="!logoPage"></influenceBottom>
    <el-backtop :right="70"></el-backtop>
  </div>
</template>
<script>
import influenceHeader from '@/components/common/header'
import influenceBottom from '@/components/common/bottom'

export default {
  name: 'App',
  data() {
    return {
      //路由重载
      isRouterAlive: true,
      logoPage: false
    }
  },
  components: {
    influenceHeader,
    influenceBottom,
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    checkRouteForContactUs() {
      const hideLoginRoutes = ['/login'];
      this.logoPage = hideLoginRoutes.includes(this.$route.path);
    }
  },
  watch: {
		// 监听路由变化
		"$route.path": {
			handler(routePath) {
				this.checkRouteForContactUs()
        
			},
			immediate: true,
		},

	}
}
</script>

<style>
#app {
  width: 100%;
  /* text-align: center; */
}

html {
  overflow-y: scroll;
}

body {
  padding: 0 !important;
}

.pointer {
  cursor: pointer
}
</style>