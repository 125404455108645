import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/pages/index.vue'
import Login from '@/pages/user/login'
import Meeting from '@/pages/meeting.vue'
import About from '@/pages/about.vue'
import News from '@/pages/news.vue'
import JournalSci from '@/pages/journal/journalSci.vue'
import JournalEsci from '@/pages/journal/journalEsci.vue'
import JournalAhci from '@/pages/journal/journalAhci.vue'
import JournalDetailsSCI from '@/pages/journal/journalDetailsSCI.vue'
import JournalDetailsAHCI from '@/pages/journal/journalDetailsAHCI.vue'
import JournalDetailsESCI from '@/pages/journal/journalDetailsESCI.vue'
import Translate from '@/pages/service/translate.vue'
import Personal from '@/pages/personal.vue'
import Polishing from '@/pages/service/polishing.vue'
import Difference from '@/pages/service/difference.vue'
// import Contribution from '@/pages/service/contribution.vue'
import Retrieval from '@/pages/service/retrieval.vue'
import Selective from '@/pages/service/selective.vue'
import privacy from '@/pages/privacy.vue'
import userAgreement from '@/pages/userAgreement.vue'
Vue.use(VueRouter)


export default new VueRouter({
	routes: [
		{
			path: "",
			name: "",
			component: () => import("@/pages/index.vue"),
		},

		{
			path: "/",
			redirect: "/index"
		},

		{
			path: "/index",
			name: 'index',
			component: Index,
		},

		{
			path: "/meeting",
			name: 'meeting',
			component: Meeting,
		},

		{
			path: "/news",
			name: 'news',
			component: News,
		},

		{
			path: "/journalSci",
			name: 'journalSci',
			component: JournalSci,
		},
		{
			path: "/journalAhci",
			name: 'journalAhci',
			component: JournalAhci,
		},
		{
			path: "/journalEsci",
			name: 'journalEsci',
			component: JournalEsci,
		},

		{
			path: "/journalDetailsSCI",
			name: 'journalDetailsSCI',
			component: JournalDetailsSCI,
		},
		{
			path: "/journalDetailsAHCI",
			name: 'journalDetailsAHCI',
			component: JournalDetailsAHCI,
		},
		{
			path: "/journalDetailsESCI",
			name: 'journalDetailsESCI',
			component: JournalDetailsESCI,
		},

		{
			path: "/about",
			name: 'about',
			component: About,
		},

		{
			path: "/login",
			component: Login,
		},

		{
			path: "/personal",
			name: 'personal',
			component: Personal,
		},
		{
			path: "/translate",
			name: 'translate',
			component: Translate
		},
		{
			path: "/polishing",
			name: 'polishing',
			component: Polishing
		},
		{
			path: "/difference",
			name: 'difference',
			component: Difference
		},

		{
			path: "/retrieval",
			name: 'retrieval',
			component: Retrieval
		},
		// {
		// 	path: "/contribution",
		// 	name: 'contribution',
		// 	component: Contribution
		// },
		{
			path: "/selective",
			name: 'selective',
			component: Selective
		},
		{
		    path: "/privacy",
			name: 'privacy',
			component: privacy
		},
		{
		    path: "/userAgreement",
			name: 'userAgreement',
			component: userAgreement
		}
	]
})


