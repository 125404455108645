<template>
    <div class="about_case">
        <serviceTitle :title="'成功案例与客户见证'"></serviceTitle>

        <div class="case_box">
            <el-carousel height="142px" indicator-position="none" ref="carouselRef">
                <el-carousel-item v-for="(item, index) in evaluateList" :key="index">
                    <div class="evaluate_item">
                        <!-- <div class="evaluate_item_img">
                            <img :src="item.authorImage" alt="">
                        </div> -->
                        <div class="evaluate_item_content">
                            <div class="name">{{ item.name }}</div>
                            <div class="technical">{{ item.technical }}</div>
                            <div class="content"> {{ item.content }}</div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import { evaluateList } from "./data";
import serviceTitle from "@/components/common/serviceTitle.vue";

export default {
    components: {
        serviceTitle,
    },
    data() {
        return {
            evaluateList
        }
    }
}
</script>

<style lang='scss'></style>