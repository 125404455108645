import Vue from 'vue'
import Vuex from "vuex"

Vue.use(Vuex)

const store = new Vuex.Store({

	state() {
		return {
			userInfo: [],
			tokenInfo: [],
			isLogin: false,
			expireTime:''
		};
	},
	
	mutations: {
		setUserInfo(state, userInfo) {
			localStorage.setItem('userInfo', JSON.stringify(userInfo))
			state.userInfo = userInfo
		},
		setTokenInfo(state, tokenInfo) {
		
			
			localStorage.setItem('tokenInfo', JSON.stringify(tokenInfo))
			state.tokenInfo = tokenInfo
			
		},
		setIsLogin(state, isLogin) {
			localStorage.setItem('isLogin', isLogin)
			state.isLogin = isLogin
		},
		setExpireTime(state,expireTime){
			const currentTime = Date.now()
			expireTime = currentTime + (expireTime-60) * 1000
			localStorage.setItem('expireTime', JSON.stringify(expireTime))
			state.expireTime = expireTime
		}
	
	},
	
	getters: {
		getUserInfo: state => {
			var localUserInfo =  localStorage.getItem("userInfo")
			
			state.userInfo = localUserInfo == undefined || localUserInfo == "" || localUserInfo == 'undefined' ? "" : JSON.parse(localStorage
				.getItem("userInfo"))
			return state.userInfo
		},
		getTokenInfo: state => {
			state.tokenInfo = localStorage.getItem("tokenInfo") == undefined || localStorage.getItem(
				"tokenInfo") == "" ? "" : JSON.parse(localStorage
				.getItem("tokenInfo"))
				
			return state.tokenInfo
		},
		getExpireTime: state => {
			state.expireTime = localStorage.getItem("expireTime") == undefined || localStorage.getItem(
				"expireTime") == "" ? "" : JSON.parse(localStorage
				.getItem("expireTime"))
				
			return state.expireTime
		},
		getIsLogin: state => {
			state.isLogin = localStorage.getItem("isLogin") == undefined || localStorage.getItem(
				"isLogin") == "" ? false : JSON.parse(localStorage
					.getItem("isLogin"))
			return state.isLogin
		}
	},

});
export default store
