/**
 * 手机号码
 * @param {String} val 当前值字符串
 * @returns 返回 true: 手机号码正确
 */
export function isValidatePhoneNumber(val) {
    // false: 手机号码不正确
    if (!/^1(3[0-9]|4[579]|5[0-35-9]|6[56]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(val)) return false;
    // true: 手机号码正确
    else return true;
}

/**
 * 邮箱
 * @param {String} val 当前值字符串
 * @returns 返回 true: 邮箱正确
 */
export function isValidateEmail(val) {
    // false: 邮箱不正确
    if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            val
        )
    )
        return false;
    // true: 邮箱正确
    else return true;
}