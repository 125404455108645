<template>
    <basic-container>
        <bannerVue :item="4"></bannerVue>
        <service-menus></service-menus>
        <div class="ret_box">
            <div class="main">
                <segmentTavs :tabs="[{ label: '代开检索证明' }, { label: '查询检索状态' }]">
                    <template v-slot:default="{ activeTab }">
                        <div v-if="activeTab === 0">
                            <div id="" style="margin: 0 auto;width: 80%;">
                                <div id="" style="margin: 0 auto;">
                                    <div id="">
                                        <p class="sel_title form_title_" style="margin-top: 10px;">论文收录、被引情况检索申请书</p>
                                        <!-- <el-divider></el-divider> -->
                                    </div>
                                    <div class="loading">
                                        <el-form :model="releaseForm" :inline="true" :rules="rules" ref="releaseForm"
                                            label-width="100px" class="form_box">
                                            <div class="form_header">
                                                <el-form-item label="检索人" required prop="searcher" class="form_item">
                                                    <el-input v-model="releaseForm.searcher"
                                                        autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item label="检索单位" required prop="searchUnit" class="form_item">
                                                    <el-input v-model="releaseForm.searchUnit"
                                                        autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item label="联系邮箱" required prop="Email" class="form_item">
                                                    <el-input v-model="releaseForm.Email" autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item label="联系电话" required prop="phone" class="form_item">
                                                    <el-input v-model="releaseForm.phone" autocomplete="off"></el-input>
                                                </el-form-item>
                                            </div>
                                            <div class="form_header">
                                                <el-form-item label="检索目的" prop="searchPurpose" class="form_item">
                                                    <el-select v-model="releaseForm.searchPurpose" placeholder="">
                                                        <el-option label="入职申请" value="入职申请"></el-option>
                                                        <el-option label="优青申请" value="优青申请"></el-option>
                                                        <el-option label="申报奖励" value="申报奖励"></el-option>
                                                        <el-option label="申请专利" value="申请专利"></el-option>
                                                        <el-option label="申报院士" value="申报院士"></el-option>
                                                        <el-option label="职称评审" value="职称评审"></el-option>
                                                        <el-option label="杰出青年" value="杰出青年"></el-option>
                                                        <el-option label="特聘教授" value="特聘教授"></el-option>
                                                        <el-option label="长江学者" value="长江学者"></el-option>
                                                        <el-option label="珠江学者" value="珠江学者"></el-option>
                                                        <el-option label="百人计划" value="百人计划"></el-option>
                                                        <el-option label="333工程" value="333工程"></el-option>
                                                        <el-option label="千人计划" value="千人计划"></el-option>
                                                        <el-option label="其它" value="其它"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="收录检索" prop="collectionRetrieval" class="form_item">
                                                    <el-select v-model="releaseForm.collectionRetrieval"
                                                        placeholder="">
                                                        <el-option label="SCIE" value="SCIE"></el-option>
                                                        <el-option label="EL" value="EL"></el-option>
                                                        <el-option label="CSSCI" value="CSSCI"></el-option>
                                                        <el-option label="CSCD" value="CSCD"></el-option>
                                                        <el-option label="其它" value="其它"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="引用检索" prop="referenceRetrieval" class="form_item">
                                                    <el-select v-model="releaseForm.referenceRetrieval"
                                                        placeholder="">
                                                        <el-option label="SCIE" value="SCIE"></el-option>
                                                        <el-option label="CSSCI" value="CSSCI"></el-option>
                                                        <el-option label="CSCD" value="CSCD"></el-option>
                                                        <el-option label="其它" value="其它"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            <div id="">
                                                <p class="sel_title form_title_">文献清单</p>
                                                <!-- <el-divider></el-divider> -->
                                            </div>
                                            <div class="form_header">
                                                <el-form-item label="作者" required prop="author" class="form_item">
                                                    <el-input v-model="releaseForm.author"
                                                        autocomplete="off"></el-input>
                                                </el-form-item>
                                            </div>
                                            <el-form-item label="论文标题" required prop="title" class="form_title">
                                                <el-input v-model="releaseForm.title" autocomplete="off"></el-input>
                                            </el-form-item>
                                            <div class="form_header">
                                                <el-form-item label="期刊名称" required prop="journalName"
                                                    class="form_item">
                                                    <el-input v-model="releaseForm.journalName"
                                                        autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item label="DOI" required prop="doi" class="form_item">
                                                    <el-input v-model="releaseForm.doi" autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item label="年份" required prop="yearY" class="form_item">
                                                    <el-input v-model="releaseForm.yearY" autocomplete="off"></el-input>
                                                </el-form-item>
                                                <el-form-item label="卷期" required prop="volume" class="form_item">
                                                    <el-input v-model="releaseForm.volume"
                                                        autocomplete="off"></el-input>
                                                </el-form-item>
                                            </div>
                                            <br />
                                            <el-form-item style="width: 98%;margin-left: 100px;">
                                                <el-upload class="upload-demo" ref="upload" action="/joi/upload/file"
                                                    :on-remove="handleRemove" :on-success="onSuccess" multiple
                                                    :limit="1" :on-exceed="handleExceed" :file-list="fileList">
                                                    <el-button size="medium" class="form_box_btn">上传清单</el-button>
                                                    <template #tip>
                                                        <div class="el-upload__tip" style="color: #606266">
                                                            注：上传内容建议去除封面、目录、附录，仅上传正文
                                                        </div>
                                                    </template>
                                                </el-upload>
                                            </el-form-item>
                                            <el-form-item class="submit_btn">
                                                <el-button type="primary" @click="onSubmit('releaseForm', 'open')"
                                                    style="background-color: #18305b; width: 150px">提交</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                    <div style="margin-left: 100px; margin-bottom: 20px;">
                                        <p class="desc_tips_title_">
                                            <img src="../../assets/img/ico_notice.png" alt="">
                                            收费标准
                                        </p>
                                        <div class="desc_tips_" style="margin-bottom: 30px;">
                                            <p>1. 论文检索费100元/篇</p>
                                            <p>2. JCR分区/中科院分区另加收5元</p>
                                            <p>3. 影响因子另加收5元</p>
                                            <p>4. 引用次数2元/条</p>
                                        </div>
                                        <div class="desc_tips_">
                                            <p>检索周期：3-5个工作日</p>
                                            <p>邮寄方式：顺丰到付</p>
                                        </div>
                                        <p class="desc_tips_title_" style="margin-top: 40px;">
                                            <img src="../../assets/img/tips.gif" alt="">
                                            注意事项
                                        </p>
                                        <div class="desc_tips_">
                                            <p>1. 如要求区分完全他引，请提供该篇论文的全部作者姓名</p>
                                            <p>2. 如是英文论文，请提供第一作者的中文姓名</p>
                                            <p>3. 上传原文格式必须为.pdf格式</p>
                                            <p>4. 检测结束后，工作人员会把检测报告发送至委托人邮箱，请注意查收</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activeTab === 1">
                            <div id=""
                                style="margin: 40px auto;width: 80%;padding-top: 10px;margin-top: 0;margin-bottom: 0; ">
                                <div id="" style="text-align: center;margin-bottom: 22px;">
                                    <p style="margin-top: 0;margin-left: -40px;font-size: 14px;">
                                        请填写以下信息，提交成功后，工作人员会帮您查询检索信息，检索结果同步发至您的邮箱，请注意查收。
                                    </p>
                                </div>
                                <div v-loading="loading">
                                    <el-form :model="releaseForm1" :inline="true" :rules="rules1" ref="releaseForm1"
                                        label-width="100px">
                                        <el-form-item class="form_title" label="论文标题" required prop="title">
                                            <el-input v-model="releaseForm1.title" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <el-form-item class="form_title" label="检索人" required prop="searcher">
                                            <el-input v-model="releaseForm1.searcher" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <br />

                                        <el-form-item class="form_title" label="邮箱" required prop="Email">
                                            <el-input v-model="releaseForm1.Email" autocomplete="off"></el-input>
                                        </el-form-item>
                                        <br />
                                        <el-form-item class="form_title" label="联系电话" required prop="phone">
                                            <el-input v-model="releaseForm1.phone" autocomplete="off"></el-input>
                                        </el-form-item>

                                        <el-form-item style="text-align: right; width: 100%;">
                                            <el-button type="primary" @click="onSubmit('releaseForm1', 'query')"
                                                style="background-color: #18305b; width: 150px;margin-right: 40px;">提交</el-button>
                                        </el-form-item>
                                    </el-form>
                                </div>

                                <div style="margin-left: 80px; padding: 20px;">
                                    <div class="contact_us_title">
                                        <div class="title_plus_icon"></div>
                                        <span>联系我们</span>
                                    </div>
                                    <div style="line-height: 24px;color: #333333;font-size: 14px;">
                                       
                                        <div style="margin-top: 14px;">
                                            <div>扫码添加 <br>学术服务小编微信</div>
                                            <div>
                                                <img src="../../assets/img/xueshuxiaobian.jpg" alt=""
                                                style="width: 120px;margin-top: 10px;">
                                            </div>
                                        </div>

                                        <p>
                                            联系电话: 0371-61319290/0371-63360580
                                        </p>

                                        <p>
                                            咨询QQ: 776553636
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </segmentTavs>
                <keepSecret></keepSecret>
            </div>
        </div>
    </basic-container>

</template>
<script>
import serviceMenus from "@/components/common/serviceMenus"
import keepSecret from "@/components/common/keepSecret.vue";
import serviceTitle from "@/components/common/serviceTitle.vue";
import segmentTavs from "@/components/segmentTabs/index.vue"
import bannerVue from "./banner.vue"
import {
    translateUp
} from '@/api/service/translate'
export default {
    components: {
        "service-menus": serviceMenus,
        keepSecret,
        serviceTitle,
        segmentTavs,
        bannerVue
    },
    data() {
        return {
            loading: false,
            activeName: "first",
            fileList: [],
            releaseForm: {
                searcher: "",
                searchUnit: "",
                Email: "",
                phone: "",
                searchPurpose: "",
                collectionRetrieval: "",
                referenceRetrieval: "",
                author: "",
                title: "",
                journalName: "",
                doi: "",
                yearY: "",
                volume: "",
                classification: "open",
                spareFieldO: ""
            },
            rules: {
                searcher: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入检索人"
                },],
                searchUnit: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入检索单位"
                },],
                Email: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入邮箱"
                },],
                phone: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入联系电话"
                },],

                author: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入作者"
                },],
                title: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入标题"
                },],
                journalName: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入期刊名称"
                }],
                doi: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入DOI"
                }],
                yearY: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入年份 "
                }],
                volume: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入卷期"
                }],

            },
            releaseForm1: {
                title: "",
                searcher: "",
                Email: "",
                phone: "",
                classification: "query"
            },
            rules1: {
                searcher: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入检索人"
                },],
                Email: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入邮箱"
                },],
                phone: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入联系电话"
                },],
                title: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入标题"
                },],
            },
        };
    },
    created() {

    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        handleRemove(file, fileList) {
            this.fileList = fileList;
            this.releaseForm.spareFieldO = null
        },
        onSuccess(esponse, file, fileList) {
            this.fileList = fileList;
            this.releaseForm.spareFieldO = this.fileList[0].response.data
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        onSubmit(releaseForm, type) {
            if (!this.$store.getters.getUserInfo) {
                this.$router.push({ path: '/login' });
                return
            }
            let paramMap;
            if ('open' == type) {
                paramMap = this.releaseForm
            } else if ('query' == type) {
                paramMap = this.releaseForm1
            }
            this.$refs[releaseForm].validate((valid) => {
                if (valid) {
                    let _this = this;
                    _this.loading = true;
                    translateUp("addIamsetSearchProof", paramMap, "loading")
                        .then(function (res) {
                            console.log(res)
                            if (res.code == 200) {
                                _this.$message.success({
                                    showClose: true,
                                    duration: 1500,
                                    message: res.msg,
                                    type: "success",
                                    onClose: function () {
                                        _this.$refs[releaseForm].resetFields();
                                        if ('open' == type) {
                                            _this.$refs['upload'].clearFiles();
                                            _this.fileList = [];
                                        }
                                        _this.loading = false;
                                        _this.fileList = [];
                                    },
                                });
                            }

                        })
                        .catch(function () { });
                } else {
                    //   this.$message.error("请检查必填项！");
                    this.$notify.error({
                        title: "错误",
                        message: "请检查必填项！",
                    });
                    return false;
                }
            });
        },
        resetForm(releaseForm) {
            this.$refs[releaseForm].resetFields();
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    destroyed() {
    }
}
</script>

<style lang="scss" scoped>
.ret_box {
    min-width: 1200px;

    ::v-deep .el-tabs__nav-scroll {
        &::after {
            height: 1px;
            background-color: #f3f3f3;
        }
    }

    ::v-deep .el-tabs__nav {
        margin-left: 15%;
    }

    ::v-deep .segment-tabs {
        .tabs {
            margin: 1rem auto;
            width: 300px;
        }
    }
}

.contact_us_title {
    display: flex;
    align-items: center;
    color: #262626;

    .title_plus_icon {
        width: 10px;
        height: 10px;
        margin-right: 8px;
        background-color: #262626;
    }
}

.form_box_btn {
    background-color: white;
    color: #666666;

    &:hover {
        background-color: #18305b;
        color: white;
    }
}

.desc_tips_ {
    line-height: 1.2;
    color: rgb(51, 51, 51);
    font-size: 14px;
}

.desc_tips_title_ {
    color: #b70000;
    margin: 16px 0;
    display: flex;
    align-items: flex-start;

    img {
        margin-right: 10px;
    }
}

.sel_title,
.form_title_ {
    color: #333333;
    text-align: center;
    font-size: 28px;
    margin: 50px 0;
    font-weight: 600;
}

.form_title_ {
    font-size: 20px;
    font-weight: 400;
}

.bm_xy {
    text-indent: 35px;
    margin-top: 0px;
    font-size: 14px;
    color: rgb(96, 98, 102);
}

.ret_desc {
    width: 75%;
    margin: 30px auto;
    color: #252B3A;
    font-size: 14px;
    text-align: center;
    padding: 0;
    line-height: 1.5;
}

.swiper_box {
    min-width: 1200px;
}

.main {
    min-width: 1000px;
    width: 1200px;
    box-sizing: border-box;
    padding: 20px;
    margin: 0px auto;
    max-width: 1600px;
    background-color: #fff;

    .title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }

    .submit_btn {
        text-align: right;
        width: 100%;

        ::v-deep .el-form-item__content {
            margin-right: 40px;
        }
    }
}

.form_title {
    width: 100%;
}

.form_box {
    width: 100%;
}

.form_header {
    display: flex;
    flex-wrap: wrap;

    .form_item {
        width: calc(calc(100% - 50px)/2);
    }
}

::v-deep .form_header .el-select {
    width: 100%;
}

::v-deep .form_title .el-form-item__content {
    width: calc(100% - 140px);
}

::v-deep .form_box .form_item .el-form-item__content {
    width: calc(100% - 100px);
}

.form_width {
    width: 80%;
}

::v-deep .form_width .el-form-item__content {
    width: calc(100% - 100px);
}
</style>
