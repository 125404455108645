<template>
	<div class="page_sidebar_">
		<el-popover placement="left" trigger="hover" v-for="(item, index) in sidebarList" :key="index">
			<img :src="item.icon" alt="" slot="reference" class="warp_item_icon">
			<div class="show_warp_image_box">
				<img :src="item.image" alt="" class="show_warp_image_">
			</div>
		</el-popover>
	</div>
</template>

<script>
import { sidebarList } from "@/pages/about/data"

export default {
	name: 'backTop',
	data() {
		return {
			sidebarList
		};
	},
};
</script>

<style lang="scss">
.page_sidebar_ {
	display: flex;
	flex-direction: column;
	background-color: #f2f6fc;
	border-radius: 40px;
	padding: 0 10px;
	box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.1019607843);

	.warp_item_icon {
		width: 30px;
		height: 30px;
		padding: 10px 0;
		border-bottom: 1px solid #d9d9d9;
	}

	&>span:last-child .warp_item_icon {
		border-bottom: none;
	}
}
</style>