import { render, staticRenderFns } from "./question.vue?vue&type=template&id=01ed3e41&scoped=true"
import script from "./question.vue?vue&type=script&lang=js"
export * from "./question.vue?vue&type=script&lang=js"
import style0 from "./question.vue?vue&type=style&index=0&id=01ed3e41&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01ed3e41",
  null
  
)

export default component.exports