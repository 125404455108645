<template>
    <div id="" class='translate_banner'>
        <div class="banner_carousel_item">
            <div class="banner_item">
                <div class="banner_d_item_">
                    <div class="item_title">{{ bannerList[item].title }}</div>
                    <div v-for="(describe, i) in bannerList[item].describe" :key="i" class="banner_describe_box">
                        <i class="el-icon-check item_icon"></i>
                        <div class="item_describe">{{ describe }}</div>
                    </div>
                </div>
                <div class="banner_image_box">
                    <img :src="bannerList[item].image" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bannerList } from "./data"

export default {
    props:{
        item:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            bannerList
        }
    }
}
</script>

<style lang='scss'>
.translate_banner {
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(../../assets/img/server_banner_bj.jpg) no-repeat;
    background-size: 100% 100%;

    .el-carousel__item {
        display: flex;
        align-items: center;
    }

    .banner_carousel_item {
        .banner_item {
            width: 960px;
            height: 400px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;

            .banner_d_item_ {
                .item_title {
                    font-size: 40px;
                    margin-bottom: 20px;
                    font-weight: bold;
                }

                .banner_describe_box {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    font-size: 14px;

                    .item_icon {
                        color: #bf7c16;
                        font-weight: 900;
                        margin-right: 10px;
                        font-size: 16px;
                    }

                    .item_describe {
                        font-size: 14px;
                        letter-spacing: 0.5px;
                        color: #414141;
                    }
                }
            }

            .banner_image_box {
                img {
                    width: 456px;
                    transform: scale(0.9);
                }
            }
        }
    }
}
</style>