
import request from '@/utils/request';

/* export const getMType1 = () => {
	return request({
		url: '/joi/queryIamsetMeetingType',
		method: 'post',
		data: ''
	})
}
 */

/* export const getMeetingList = (paramap) => {
	return request({
		url: '/joi/queryIamsetMeetingType',
		method: 'post',
		data: paramap
	})
} */
export const getType = (paramap) => {
	return request({
		url: '/joi/getMeetingType',
		method: 'get',
		data: paramap
	})
}
export const getMeetingList = (paramap) => {
	return request({
		url: '/joi/queryPageMeeting',
		method: 'get',
		params: paramap
	})
}