<template>
	<basic-container>
		<div class="journal_details">

			<div class="details_content" v-loading="loading">
				<div class="page-content-top">
					<el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: 14px;">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/journalSci' }">期刊搜索</el-breadcrumb-item>
						<el-breadcrumb-item
							:to="{ path: '/journalSci', query: { category: 'AHCI' } }">AHCI</el-breadcrumb-item>
						<el-breadcrumb-item>{{ basicInformation.publisherName }}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="content_first">

					<div class="fLeft">
						<div>
							<div class="fLeft_topl1">
								<div class="fLeft_topl1_title" style="width: 700px;">{{ basicInformation.journalName }}
								</div>
							</div>
							<div class="fLeft_top">
								<div class="fLeft_topl">
									<div class="fLeft_topl2">
										<img style="width: 168px; height: 222px" :src="basicInformation.indexFaceHref"
											alt="Image" @error="handleImageError"
											v-if="!imageLoadFailed && basicInformation.indexFaceHref" />
										<div v-else class="fallback-bg" style="text-align: left;">
											<!-- 显示默认内容 -->
											<div>
												<div class="three">
													{{ basicInformation.journalName }}
												</div>
												<div class="eight">
													{{ basicInformation.publisherName }}
												</div>
											</div>
										</div>

										<el-button class="fLeft_topl2_btn"
											@click="() => $router.push({ path: 'selective', query: { journalTitle: basicInformation.journalName } })">在线匹配</el-button>
									</div>
								</div>
								<div class="fLeft_topR">
									<el-descriptions class="margin-top" title="" :column="1" border>
										<el-descriptions-item label="ISSN">
											{{ basicInformation.issn ? basicInformation.issn : '暂无' }}
										</el-descriptions-item>
										<el-descriptions-item label="eISSN">
											{{ basicInformation.eissn ? basicInformation.eissn : '暂无' }}
										</el-descriptions-item>
										<el-descriptions-item label="出版频率">
											{{ basicInformation.frequency ? basicInformation.frequency : '暂无' }}
										</el-descriptions-item>
										<el-descriptions-item label="是否OA">
											{{ basicInformation.isOa ? basicInformation.isOa : '暂无' }}
										</el-descriptions-item>
										<el-descriptions-item label="出版社">
											{{ basicInformation.publisherName ? basicInformation.publisherName : '暂无' }}
										</el-descriptions-item>
										<el-descriptions-item label="国家">
											{{ basicInformation.country ? basicInformation.country : '暂无' }}
										</el-descriptions-item>
										<el-descriptions-item label="语言">
											{{ basicInformation.languages ? basicInformation.languages : '暂无' }}
										</el-descriptions-item>
										<el-descriptions-item label="收录数据库">
											<div style="display: flex;">
												<span v-for="item in basicInformation.includedDatabaseList" :key="item"
													style="margin-right: 20px;">
													{{ item }}
												</span>
											</div>
										</el-descriptions-item>
										<el-descriptions-item label="期刊网址">
											<a :href="basicInformation.indexHref" target="_blank"
												style="color:#0d80fd;width: 400px;display: block;"
												class="fLeft_topl1_title">
												{{ basicInformation.indexHref ? basicInformation.indexHref : '暂无' }}
											</a>
										</el-descriptions-item>
									</el-descriptions>

								</div>
							</div>
						</div>
						<div class="fLeft_bottom">
							<div class="fLeft_topl1">
								期刊指标<span style="color: #666666;">（2023）</span>
							</div>
							<div class="fLeft_topl22">
								<el-descriptions class="margin-top" title="" :column="1" border>
									<el-descriptions-item label="年发文量">
										{{ basicInformation.totalArticle || '暂无' }}
									</el-descriptions-item>
									<el-descriptions-item label="影响因子(2023)">
										{{ basicInformation.impact2022 ? basicInformation.impact2022 : '暂无' }}
									</el-descriptions-item>
									<el-descriptions-item label="去除自引影响因子">
										{{ basicInformation.jifWSC ? basicInformation.jifWSC : '暂无' }}
									</el-descriptions-item>
									<el-descriptions-item label="领域、排名及JCR分区">
										<div style="display: flex; flex-direction: column;">
											<div v-for="(item, index) in basicInformation.mixPpf" :key="index"
												style="display: flex; width: 100%;">
												<span v-html="highlightItem(item)"></span>
											</div>
										</div>
									</el-descriptions-item>
									<el-descriptions-item label="引文指标(JCI2023)">
										{{ basicInformation.jci2022 || '暂无' }}
									</el-descriptions-item>
									<el-descriptions-item label="引文指标(JCI)分区">
										{{ basicInformation.jciQuartile || '暂无' }}
									</el-descriptions-item>
									<el-descriptions-item label="引文指标(JCI)排名">
										<span style="width: 80%; display: inline-block; vertical-align: middle;">
											<el-progress :stroke-width="8" :show-text="false"
												:percentage="basicInformation.jciRank > 100 ? 100 : basicInformation.jciRank"></el-progress>
										</span>
										<span
											style="width: 15%; display: inline-block; vertical-align: middle; margin-left: 10px;">
											{{ basicInformation.jciRankSource || '暂无' }}
										</span>
									</el-descriptions-item>
								</el-descriptions>
							</div>
						</div>
					</div>
					<div class="fRight">
						<div class="fRight_top">
							<div class="fLeft_topl1">期刊简介</div>
							<div class="jianjie" lang="en" style="text-align: justify; hyphens: auto;">
								{{ basicInformation.introductionJournal ? basicInformation.introductionJournal : '暂无' }}
							</div>
						</div>
						<div class="fRight_bottom">
							<div class="fLeft_topl1">
								OA文章占比
							</div>
							<div id="oa" class="chart-container" style="height:362px;width: 365px"
								v-show="basicInformation && basicInformation.ofOaGold"></div>
							<div style="padding: 20px;font-size: 14px;color: #666666;"
								v-show="!basicInformation || !basicInformation.ofOaGold">暂无
							</div>
						</div>
					</div>
				</div>
				<div class="content_second">
					<div class="fLeft_topl1">中科院分区<span style="color: #666666;">（2023）</span><span
							style="color: #f40; font-style: italic;font-size: 14px;"
							v-if="basicInformation.isTop">TOP</span></div>
					<div class="content_second_zong">
						<div class="content_second_zong_item">
							<div class="content_second_zong_item1">大类学科</div>
							<div class='content_second_zong_item2' style="align-items: center;">
								{{ journalCategory.category ? journalCategory.category : '暂无' }}
							</div>
						</div>
						<div class="content_second_zong_item"
							style="padding-right:35px;border-right:1px solid #d2d2d2;">
							<div class="content_second_zong_item1">大类分区</div>
							<div class='content_second_zong_item2'>

								<div style="width:98%;top:15%;position: relative;">
									<div class="triangle" :style="{
										'border-bottom': journalCategory.categoryPartition === '1区' ? '50px solid hsl(0deg 80% 67%)' :
											'50px solid #eeeeee'
									}">
										<p class="bigPartition1" style="padding-top: 5px;">1区</p>
									</div>
									<div class="trapezoid" :style="{
										'border-bottom': journalCategory.categoryPartition === '2区' ? '40px solid hsl(41deg 94% 66%)' :
											'40px solid #eeeeee'
									}">
										<p class="bigPartition">2区</p>
									</div>
									<div class="trapezoid1" :style="{
										'border-bottom': journalCategory.categoryPartition === '3区' ? '40px solid hsl(101deg 46% 63%)' :
											'40px solid #eeeeee'
									}">
										<p class="bigPartition">3区</p>
									</div>
									<div class="trapezoid2" :style="{
										'border-bottom': journalCategory.categoryPartition === '4区' ? '40px solid hsl(210deg 100% 63%)' :
											'40px solid #eeeeee'
									}">
										<p class="bigPartition">4区</p>
									</div>
								</div>
							</div>
						</div>
						<div class="content_second_zong_item">
							<div class="content_second_zong_item1">小类学科</div>
							<div class='content_second_zong_item2'
								style="display:flex;flex-direction:column;align-items: center;">
								<div v-if="journalCategory.subCategoryList"
									v-for="(item, index) in (journalCategory.subCategoryList || []).filter(r => r != null)"
									:key="item">
									<p style="margin:0px;">{{ item }}</p>
								</div>
								<span
									v-if="!journalCategory.subCategoryList || journalCategory.subCategoryList.every(item => item === null)">暂无</span>
							</div>

						</div>
						<div class="content_second_zong_item">
							<div class="content_second_zong_item1">小类分区</div>
							<div class='content_second_zong_item2'
								style="display:flex;flex-direction:column;align-items: center;">
								<div v-if="journalCategory.partitionList"
									v-for="(item, index) in (journalCategory.partitionList || []).filter(r => r != null)"
									:key="item">
									<p style="margin:0px;">{{ item }}</p>
								</div>
								<span
									v-if="!journalCategory.partitionList || journalCategory.partitionList.every(item => item === null)">暂无</span>
							</div>

						</div>
					</div>

				</div>
				<div class="content_third" v-if="basicInformation.remark">
					<div style="font-weight: bolder;font-size: 20px;padding:15px;">特别说明</div>
					<div style="padding:10px 15px 15px 15px;text-indent: 24px;line-height: 24px;">
						{{ basicInformation.remark }}
					</div>
				</div>
				<div class="content_fourth">
					<div class="fLeft_topl1">综合数据</div>

					<div class="content_fourth2">
						<div class="content_fourth21">
							<el-tabs type="card" class="el_tab">
								<el-tab-pane label="发文量">
									<div id="publicationsNumber" class="chart-container"
										style="width:1180px;height:600px;  "></div>
								</el-tab-pane>

								<el-tab-pane label="发文类型">
									<div id="publicationsType" class="chart-container"
										style="width:1180px;height:600px;   ">
									</div>
								</el-tab-pane>

								<el-tab-pane label="引文指标">
									<div id="citationMetrics" class="chart-container"
										style="width:1180px;height:600px;  ">
									</div>
								</el-tab-pane>
								<el-tab-pane label="即时指数">
									<div id="instantIndices" class="chart-container"
										style="width:1180px;height:600px;  ">
									</div>
								</el-tab-pane>
								<el-tab-pane label="半衰期">
									<div id="halfLife" class="chart-container" style="width:1180px;height:600px;  ">
									</div>
								</el-tab-pane>
							</el-tabs>

						</div>
						<!-- 遮罩层 -->
						<div class="overlay" v-if="!isLoggedIn">
							<div class="overlay_content">
								<p class="overlay_content_p1">登录查看</p>
								<p class="overlay_content_p2">登录后享有权益的用户可查看详细信息</p>
								<div @click="jumplogin" class="loginButton">登录</div>
								<div class="overlay_bottom">
									<p style="color:#333333">数据样例</p>
									<img src="../../assets/img/sciSuolue.png" alt="">
								</div>
							</div>
						</div>
						<!-- 遮罩层 -->
					</div>

				</div>
				<!-- 评论与展示 -->
				<div class="content_fifth">
					<div class="fLeft_topl1">评论</div>
					<div class="content_fifth1">
						<!-- <div style="display: inline-block;">评分&nbsp;<el-rate style="display: inline-block;"
							v-model="pinglun.score" show-text>
						</el-rate>
					</div> -->
						<div style="margin:15px 0px;" @click="goToLogin"><el-input @focus="show"
								@keyup.enter="publishAppraise" type="textarea" :rows="4"
								:style="{ filter: !isLoggedIn ? 'brightness(0.97)' : 'none' }"
								:placeholder="isLoggedIn ? '请输入评价' : '登录后评论'" v-model="pinglun.content">
							</el-input>
						</div>
						<div style="text-align: right;" v-if="showPubilsh">
							<el-button size="medium" type="primary" @click="publishAppraise">发布</el-button>
						</div>
					</div>
					<div class="content_fifth2">
						<div class="content_fifth21" v-for="(item, index) in showPinglun" :key="item.id"
							v-loading="loading">

							<div class="content_fifth21_zong">
								<div class="content_fifth21_left">
									<img class="user-icon" src="item.userIcon" alt="" @error="handleImageError"
										v-if="!imageLoadFailed && item.userIcon">
									<div class="fall_user_icon" v-else></div>
								</div>
								<div class="content_fifth21_right">
									<div class="comment1"><span>{{ item.userName }}</span>&nbsp;&nbsp;
										<!-- <el-rate disabled v-model="item.getScore"></el-rate> -->
										<!-- 评分 -->
									</div>
									<div class="comment1">{{ item.content }}</div>
									<div class="comment1">
										<span>{{ item.createTime ? formatDate(item.createTime) : '/' }}</span>
										<span style="display: flex;align-items: center;"><img v-if="item.liked"
												class="liked-icon" src="../../assets/img/赞.png" alt=""
												@click="toggleLike(index, -1)">
											<img v-else class="disliked-icon" src="../../assets/img/赞同.png" alt=""
												@click="toggleLike(index, -1)"><span>{{ item.likedTimes }}</span></span>

										<span @click="publish1(item, index)"
											style="display: flex;align-items: center;font-size: 14px;color:#8c8c8c;cursor: pointer;"><img
												src="../../assets/img/comment.png" alt=""><span>{{ item.replyTimes
												}}</span><span>回复</span></span>
										<!-- 回复评论 -->
									</div>
									<div style="display: flex;margin:5px 0px 0px 20px;width:92%;align-items: center;"
										v-if="item.hidden">
										<div style="width: 80%;margin-right: 20px;"><el-input
												@keyup.enter="SendCommentReply(item, index)"
												v-model="replyContent.content" :disabled="!isLoggedIn"
												:placeholder="isLoggedIn ? '请输入评价' : '登录后评论'"></el-input></div>
										<div style="width:15%;"><el-button type="primary" size="mini"
												@click="SendCommentReply(item, index)">提交</el-button></div>
									</div>
								</div>
							</div>

							<div class="replyComment" v-if="item.children && item.children.length > 0"
								v-for="(child, childIndex) in item.children" :key="child.id" v-loading="loading">
								<div style="display: flex;">
									<div class="content_fifth21_left">
										<img class="user-icon" style="height:45px;width:45px" src="item.userIcon" alt=""
											@error="handleImageError" v-if="!imageLoadFailed && child.userIcon">
										<div class="fall_user_icon" style="height:45px;width:45px" v-else></div>
									</div>
									<div class="content_fifth21_right">
										<div class="comment1"><span>{{ child.userName }}<span v-if="child.status == 3"
													style="margin-left:10px;color:#008AC5;">回复@{{ child.targetUserName
													}}</span></span>&nbsp;&nbsp;
										</div>
										<div class="comment1">{{ child.content }}</div>
										<div class="comment1">
											<span>{{ child.createTime ? formatDate(child.createTime) : '/' }}</span>
											<span style="display: flex;align-items: center;"><img v-if="child.liked"
													class="liked-icon" src="../../assets/img/赞.png" alt=""
													@click="toggleLike(index, childIndex)">
												<img v-else class="disliked-icon" src="../../assets/img/赞同.png" alt=""
													@click="toggleLike(index, childIndex)"><span>{{ child.likedTimes
													}}</span></span>

											<span @click="publish2(item, index, childIndex)"
												style="display: flex;align-items: center;font-size: 14px;color:#8c8c8c;cursor: pointer;"><img
													src="../../assets/img/comment.png" alt=""><span>回复</span></span>
											<!-- 回复评论 -->
										</div>
										<div style="display: flex;margin:5px 0px 0px 20px;width:92%;align-items: center;"
											v-if="child.hidden">
											<div style="width: 80%;margin-right: 20px;"><el-input
													@keyup.enter="SendCommentReply1(item, index, childIndex)"
													v-model="replyContent1.content" :disabled="!isLoggedIn"
													:placeholder="isLoggedIn ? '请输入评价' : '登录后评论'"></el-input>
											</div>
											<div style="width:15%;"><el-button type="primary" size="mini"
													@click="SendCommentReply1(item, index, childIndex)">提交</el-button>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div style="margin-top:20px;">
							<el-pagination :hide-on-single-page="true" @current-change="handleCurrentChange"
								layout="prev, pager, next" :page-size="pageSize" :page-sizes="[10, 20, 30]"
								:current-page.sync="currentPage" :pager-count="5" :total="total">
							</el-pagination>

						</div>
					</div>
				</div>
				<!-- 推荐期刊 -->
				<div class="content_sixth">
					<div class="fLeft_topl1">推荐期刊</div>
					<div style="margin:15px 30px;padding: 10px;">
						<div style="display: flex;justify-content: space-between;">
							<div style="flex:1;display: flex;flex-direction: column;align-items: center;"
								v-for="(journal, journalIndex) in journalList.slice(0, 5)" :key="journalIndex"
								@click="updateJournalDetails(journalIndex)" class="cursor">
								<div>
									<div v-if="journal.imageLoadFailed">
										<img style="width: 168px; height: 222px" :src="journal.indexFaceHref"
											@error="recommendImageError" alt="Image" />
									</div>
									<div v-else class="fallback-bg">
										<div>
											<div class="three">
												{{ journal.journalName }}
											</div>
											<div class="eight">
												{{ journal.publisherName }}
											</div>
										</div>
									</div>
								</div>
								<div class="two journalTiele">
									{{ journal.journalTitle }}
								</div>
								<div style="text-align: center;line-height: 0.4;">
									<p class="one">
										<span style="color: rgb(172 172 172);margin-right: 4px;">ISSN:</span><span
											style="color: #606266;">{{ journal.issn }}</span>
									</p>
									<p class="one">
										<span style="color: rgb(172 172 172);margin-right: 4px;">e-ISSN:</span><span
											style="color:#606266 ">{{ journal.eissn }}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 提交表单 -->
				<div
					style="width:97%;color:rgb(112 109 109);padding:30px 0px;display:flex;justify-content: center;align-items: center;margin:0 auto">
					<span style="display: flex;justify-content: center;align-items: center;font-size: 14px;">
						<img src="../../assets/img/ico_notice.png" alt="" style="margin-right: 6px;">
						网站数据来源于手工整理，仅作学术分享，如有异议请提交工单处理 &nbsp;&nbsp;</span>

					<el-button @click="dialogFormVisible = true" class="jour_err_btn">提交工单</el-button>

					<el-dialog title="" :visible.sync="dialogFormVisible" :append-to-body="true">
						<div :style="{ margin: `0 0 20px ${formLabelWidth}`, fontSize: '18px', color: '#303133' }">提交工单
						</div>
						<el-form :rules="rules" ref="journalErrorMessage" :model="journalErrorMessage">
							<el-form-item label="期刊名称" :label-width="formLabelWidth" prop="name">
								<el-input v-model="journalErrorMessage.name" autocomplete="off" required></el-input>
							</el-form-item>
							<el-form-item label="ISSN/e-ISSN" :label-width="formLabelWidth" prop="number">
								<el-input v-model="journalErrorMessage.number" autocomplete="off" required></el-input>
							</el-form-item>
							<el-form-item label="联系邮箱" :label-width="formLabelWidth" :error="phoneError" prop="phone">
								<el-input v-model="journalErrorMessage.phone" autocomplete="off"
									@blur="validatePhone"></el-input>
							</el-form-item>
							<el-form-item label="错误信息" :label-width="formLabelWidth" :error="phoneError"
								prop="errorContent">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder=""
									v-model="journalErrorMessage.errorContent" required>
								</el-input>
							</el-form-item>
						</el-form>

						<div style="display: flex;justify-content: flex-end;margin-top:30px;">
							<!-- <el-button @click="postError" type="primary" round size="small"
								style="width:100px">提交</el-button> -->

							<div @click="postError" style="" class="gd_btn loginButton">提交</div>
						</div>
					</el-dialog>
				</div>
			</div>
		</div>
	</basic-container>
</template>

<script>
import {
	getAhciJournalList, getPieEchartSCi,
	getCitedTopTenSCi,
	getPublishAppraiseSCi,
	getCommentSCi,
	getToggleLikeSCi,
	getRandomJournalListSCi,
	getPostErrorSCi
} from "@/api/journal/journal";
import * as echarts from 'echarts';
import 'echarts-liquidfill';
export default {
	inject: ['reload'],
	data() {
		return {
			userInfo: {}, //用户信息
			basicInformation: {}, //期刊基本信息
			imageLoadFailed: false, //期刊图片加载失败
			journalCategory: {}, //中科院分区
			journalCites: [], //被引前10数据
			echarts: {}, //echarts数据
			rate: '4.0', //评分显示
			showTooltip: false, //是否展示?后面的话
			pinglun: {
				status: 1,
				topReplyId: '', //顶层ID
				content: '', //评价内容
				journalName: '', //期刊名称
				targetReplyId: '', //目标回复Id
				targetUserId: '', //上层用户ID
			},
			showPubilsh: false, //是否展示发布
			showPinglun: [{
				id: 1,
				userIcon: '', //当前用户头像
				userName: '', //当前用户名
				anonymity: false, //是否匿名
				score: '',
				content: '', //评价内容
				replyTimes: '', //回复总数
				createTime: '', //创建时间
				liked: false, //是否点赞
				likedTimes: 0, //点赞总数
				targetUserName: '', //
				hidden: false,
				// getScore: 3, //用户评分,暂时不用
				//初元素，避免j
				children: [{
					id: 1,
					userIcon: '', //当前用户头像
					userName: '', //当前用户名
					anonymity: false, //是否匿名
					score: '',
					content: '', //评价内容
					replyTimes: '', //回复总数
					createTime: '', //创建时间
					liked: false, //是否点赞
					likedTimes: 0, //点赞总数
					targetUserName: '', //
					hidden: false,
					// getScore: 3, //用户评分,暂时不用
					//初元素，避免j
					children1: [{

					}]
				}],
				//子元素
				total: 0,
				pageNo: 1

			}],
			replyContent: {
				status: 2,
				score: '', //评分
				content: '', //评价内容
				journalName: '', //期刊名称
				targetReplyId: '', //目标回复Id
				targetUserId: '', //上层用户ID
				topReplyId: '',
			}, //评论回复内容
			replyContent1: {
				status: 3,
				score: '', //评分
				content: '', //评价内容
				journalName: '', //期刊名称
				targetReplyId: '', //目标回复Id
				targetUserId: '', //上层用户ID
				topReplyId: '',
			}, //评论回复二级内容
			total: 0, //评价总数
			pageSize: 10,
			currentPage: 1,
			color: [
				"#ee6666",
				"#73c0de",
				"#3ba272",
				"#fc8452",
			], //被引前10的颜色
			journalList: [], //推荐期刊
			dialogFormVisible: false, //提交表单的对话框
			postTypeData: [], //饼图数据
			journalErrorMessage: {
				name: "",
				number: "",
				phone: "",
				errorContent: "",
			},
			activeName: '',
			//电话号码校验
			phoneError: false,
			formLabelWidth: "100px",
			rules: {
				name: [{
					required: true,
					message: '请输入期刊名称',
					trigger: 'blur'
				}],
				phone: [{
					required: true,
					message: '请输入邮箱',
					trigger: 'blur'
				}],
				errorContent: [{
					required: true,
					message: '请输入错误信息',
					trigger: 'blur'
				}]
			}, //校验提交的错误表单内容
			loading: false, //loading
			isLoggedIn: 0, // 登录状态
			hiddenFen: false, //隐藏分页
			chartAa: null, //oa文章占比
			predictedNumbers: [],//预测值

		}
	},
	created() {
		this.isLogin = this.$store.getters.getIsLogin
		this.userInfo = this.$store.getters.getUserInfo
		this.journalDetails = this.$route.query
		this.activeName = this.$route.query.activeName
	},

	mounted() {
		this.fetchData()
		window.addEventListener("resize", () => {
			this.chartAa.resize();
		});
	},

	methods: {
		goToLogin() {
			if (this.isLoggedIn) return

			this.$router.push({
				path: '/login'
			})
		},
		async fetchData() {
			this.loading = true
			let paramap = {
				'id': this.$route.query.id
			}
			await getAhciJournalList(paramap)
				.then(res => {
					// 给echarts添加遮罩层
					this.$nextTick(() => {
						// 添加样式
						if (res.data.isLogin == 0) {
							document.querySelectorAll('.content_fourth21').forEach((element) => {
								element.style.filter = "blur(3px)";
							});
						}
					});
					this.isLoggedIn = res.data.isLogin //判断登录状态
					// 给echarts添加遮罩层
					const data = res.data

					this.basicInformation = data
					this.journalCategory = data.journalCategory
					this.journalName = this.basicInformation.journalName
					this.echarts = data.echartsVOList
					this.predictedNumbers = this.basicInformation.predictedList
					this.basicInformation.avgPercentileJif = this.basicInformation.avgPercentileJif - 0
					this.echartsImage()
					this.postType()
					this.getpinglun()
					this.randomJournalList()
					this.$forceUpdate();

					this.loading = false

				})
				.catch(function () { });
		}, //获取所有期刊信息
		//详情页图片加载失败时，设置imageLoadFailed为true
		handleImageError() {
			e.target.src = this.defaultImg
			this.imageLoadFailed = true;
		},
		recommendImageError() {
			this.journalList[index].imageLoadFailed = false
		},
		// 领域/排名及JCR分区:
		highlightItem(item) {
			const regex = /,([^,]+)$/; // 正则表达式匹配最后一个逗号后的内容
			const match = regex.exec(item); // 获取匹配结果
			if (match && match[1].match(/^Q[1-4]$/)) {
				// 如果匹配到值并且在范围 Q[1-4] 内
				const highlightedValue = `<span style="width: 45px;
			border: 1px solid;
			display: inline-block;
			border-radius: 10px;
			text-align: center;
			color: #ffffff;
			background-color: #409eff;">${match[1]}</span>`;
				return item.replace(regex, `,<span>${highlightedValue}</span>`);
			}
			return item;
		},
		//OA占比
		oaPercentage() {

			this.chartAa = echarts.init(
				document.getElementById('oa'), 'white', {
				renderer: 'canvas'
			});
			let data = [this.basicInformation.ofOaGold / 100]
			var option_oa = {
				// "backgroundColor": "white",
				"animation": true,
				"animationThreshold": 2000,
				"animationDuration": 1000,
				"animationEasing": "cubicOut",
				"animationDelay": 0,
				"animationDurationUpdate": 300,
				"animationEasingUpdate": "cubicOut",
				"animationDelayUpdate": 0,
				"aria": {
					"enabled": false
				},
				"color": [
					"#5470c6",
					"#91cc75",
					"#fac858",
					"#ee6666",
					"#73c0de",
					"#3ba272",
					"#fc8452",
					"#9a60b4",
					"#ea7ccc"
				],
				"series": [{
					"type": "liquidFill",
					"name": "lq",
					"data": data,
					"waveAnimation": true,
					"animationDuration": 2000,
					"animationDurationUpdate": 1000,
					"color": [
						"#294D99",
						"#156ACF",
						"#1598ED",
						"#45BDFF"
					],
					"shape": "circle",
					"backgroundStyle": {},
					"outline": {
						"show": true,
						"borderDistance": 8
					},
					"tooltip": {
						"show": false,
						"trigger": "item",
						"triggerOn": "mousemove|click",
						"axisPointer": {
							"type": "line"
						},
						"showContent": true,
						"alwaysShowContent": false,
						"showDelay": 0,
						"hideDelay": 100,
						"enterable": false,
						"confine": false,
						"appendToBody": false,
						"transitionDuration": 0.4,
						"textStyle": {
							"fontSize": 14
						},
						"borderWidth": 0,
						"padding": 10,
						"order": "seriesAsc"
					},
					"label": {
						"show": true,
						"position": "inside",
						"margin": 16,
						"fontSize": 40,
						"formatter": function (param) {
							return Math.round(param.value * 10000) / 100 + '%';
						}
					},
					"rippleEffect": {
						"show": true,
						"brushType": "stroke",
						"scale": 2.5,
						"period": 4
					}
				}],
				"legend": [{
					"data": [],
					"selected": {},
					"show": false,
					"padding": 4,
					"itemGap": 10,
					"itemWidth": 25,
					"itemHeight": 14,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 1,
					"borderRadius": 0,
					"pageButtonItemGap": 5,
					"pageButtonPosition": "end",
					"pageFormatter": "{current}/{total}",
					"pageIconColor": "#2f4554",
					"pageIconInactiveColor": "#aaa",
					"pageIconSize": 15,
					"animationDurationUpdate": 800,
					"selector": false,
					"selectorPosition": "auto",
					"selectorItemGap": 7,
					"selectorButtonGap": 10
				}],
				"tooltip": {
					"show": true,
					"trigger": "item",
					"triggerOn": "mousemove|click",
					"axisPointer": {
						"type": "line"
					},
					"showContent": true,
					"alwaysShowContent": false,
					"showDelay": 0,
					"hideDelay": 100,
					"enterable": false,
					"confine": false,
					"appendToBody": false,
					"transitionDuration": 0.4,
					"textStyle": {
						"fontSize": 14
					},
					"borderWidth": 0,
					"padding": 2,
					"order": "seriesAsc"
				},
				"title": [{
					"show": true,
					"target": "blank",
					"subtarget": "blank",
					"padding": 5,
					"itemGap": 10,
					"textAlign": "auto",
					"textVerticalAlign": "auto",
					"triggerEvent": false
				}]
			};
			this.chartAa.setOption(option_oa);
		},
		//发文量
		publicationsNumber() {

			var chart_publicationsNumber = echarts.init(
				document.getElementById('publicationsNumber'), 'white', {
				renderer: 'canvas'
			});
			let xList = this.getNotNullArray(this.echarts.sendChineseRate.xlist)
			let yList = this.getNotNullArray(this.echarts.sendChineseRate.ylist)
			let yList1 = this.getNotNullArray(this.echarts.sendCountQuantity.ylist)
			var option_publicationsNumber = {
				"animation": true,
				"animationThreshold": 2000,
				"animationDuration": 1000,
				"animationEasing": "cubicOut",
				"animationDelay": 0,
				"animationDurationUpdate": 300,
				"animationEasingUpdate": "cubicOut",
				"animationDelayUpdate": 0,
				"aria": {
					"enabled": false
				},
				"color": [
					"#5470c6",
					"#91cc75",
					"#fac858",
					"#ee6666",
					"#73c0de",
					"#3ba272",
					"#fc8452",
					"#9a60b4",
					"#ea7ccc"
				],
				"series": [{
					"type": "line",
					"name": "\u603b\u53d1\u6587\u91cf",
					"connectNulls": false,
					"xAxisIndex": 0,
					"yAxisIndex": 0,
					"symbolSize": 4,
					"showSymbol": true,
					"smooth": true,
					"clip": true,
					"step": false,
					"data": yList1,
					"hoverAnimation": true,
					"label": {
						"show": true,
						"margin": 8
					},
					"logBase": 10,
					"seriesLayoutBy": "column",
					"lineStyle": {
						"show": true,
						"width": 1,
						"opacity": 1,
						"curveness": 0,
						"type": "solid"
					},
					"areaStyle": {
						"opacity": 0.5
					},
					"zlevel": 0,
					"z": 0,
					"rippleEffect": {
						"show": true,
						"brushType": "stroke",
						"scale": 2.5,
						"period": 4
					}
				},
				{
					"type": "line",
					"name": "\u4e2d\u56fd\u4eba\u53d1\u6587\u91cf",
					"connectNulls": false,
					"xAxisIndex": 0,
					"yAxisIndex": 0,
					"symbolSize": 4,
					"showSymbol": true,
					"smooth": true,
					"clip": true,
					"step": false,
					"data": yList,
					"hoverAnimation": true,
					"label": {
						"show": true,
						"margin": 8
					},
					"logBase": 10,
					"seriesLayoutBy": "column",
					"lineStyle": {
						"show": true,
						"width": 1,
						"opacity": 1,
						"curveness": 0,
						"type": "solid"
					},
					"areaStyle": {
						"opacity": 0.5
					},
					"zlevel": 0,
					"z": 0,
					"rippleEffect": {
						"show": true,
						"brushType": "stroke",
						"scale": 2.5,
						"period": 4
					}
				}
				],
				"legend": [{
					"data": [
						"\u603b\u53d1\u6587\u91cf",
						"\u4e2d\u56fd\u4eba\u53d1\u6587\u91cf"
					],
					"selected": {},
					"show": true,
					"left": "right",
					"orient": "vertical",
					"padding": 5,
					"itemGap": 10,
					"itemWidth": 25,
					"itemHeight": 14,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 0,
					"borderRadius": 0,
					"pageButtonItemGap": 5,
					"pageButtonPosition": "end",
					"pageFormatter": "{current}/{total}",
					"pageIconColor": "#2f4554",
					"pageIconInactiveColor": "#aaa",
					"pageIconSize": 15,
					"animationDurationUpdate": 800,
					"selector": false,
					"selectorPosition": "auto",
					"selectorItemGap": 7,
					"selectorButtonGap": 10
				}],
				"tooltip": {
					"show": true,
					"trigger": "axis",
					"triggerOn": "mousemove|click",
					"axisPointer": {
						"type": "line"
					},
					"showContent": true,
					"alwaysShowContent": false,
					"showDelay": 0,
					"hideDelay": 100,
					"enterable": false,
					"confine": false,
					"appendToBody": false,
					"transitionDuration": 0.4,
					"textStyle": {
						"fontSize": 14
					},
					"borderWidth": 0,
					"padding": 5,
					"order": "seriesAsc"
				},
				"xAxis": [{
					"name": "\u5e74",
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"axisTick": {
						"show": true,
						"alignWithLabel": true,
						"inside": false
					},
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"boundaryGap": false,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					},
					"data": xList,
				}],
				"yAxis": [{
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					}
				}],
				"title": [{
					"show": true,
					"text": "\u8fd1\u4e94\u5e74\u53d1\u6587\u91cf",
					"target": "blank",
					"subtarget": "blank",
					"left": "left",
					"padding": 5,
					"itemGap": 10,
					"textAlign": "auto",
					"textVerticalAlign": "auto",
					"triggerEvent": false
				}],
				"grid": [{
					"show": false,
					"zlevel": 0,
					"z": 2,
					"top": 100,
					"containLabel": false,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 1,
					"shadowOffsetX": 0,
					"shadowOffsetY": 0
				}]
			};
			document.getElementById('publicationsNumber').setAttribute('_echarts_instance_', '');
			chart_publicationsNumber.setOption(option_publicationsNumber);
		},
		//论文影响力
		/* impactor() {

			var chart_impactor = echarts.init(
				document.getElementById('impactor'), 'white', {
				renderer: 'canvas'
			});
			let xList = this.getNotNullArray(this.echarts.aisList.xlist)
			let yList = this.getNotNullArray(this.echarts.aisList.ylist)
			var option_impactor = {
				"animation": true,
				"animationThreshold": 2000,
				"animationDuration": 1000,
				"animationEasing": "cubicOut",
				"animationDelay": 0,
				"animationDurationUpdate": 300,
				"animationEasingUpdate": "cubicOut",
				"animationDelayUpdate": 0,
				"aria": {
					"enabled": false
				},
				"color": [
					"#5470c6",
					"#91cc75",
					"#fac858",
					"#ee6666",
					"#73c0de",
					"#3ba272",
					"#fc8452",
					"#9a60b4",
					"#ea7ccc"
				],
				"series": [{
					"type": "line",
					"name": "\u8bba\u6587\u5f71\u54cd\u529b",
					"connectNulls": false,
					"xAxisIndex": 0,
					"yAxisIndex": 0,
					"symbolSize": 4,
					"showSymbol": true,
					"smooth": true,
					"clip": true,
					"step": false,
					"data": yList,
					"hoverAnimation": true,
					"label": {
						"show": true,
						"margin": 8
					},
					"logBase": 10,
					"seriesLayoutBy": "column",
					"lineStyle": {
						"show": true,
						"width": 1,
						"opacity": 1,
						"curveness": 0,
						"type": "solid"
					},
					"areaStyle": {
						"opacity": 0
					},
					"markLine": {
						"silent": false,
						"precision": 2,
						"label": {
							"show": true,
							"margin": 8
						},
						"data": [{
							"name": "\u5e73\u5747\u503c",
							"type": "average"
						}]
					},
					"zlevel": 0,
					"z": 0,
					"rippleEffect": {
						"show": true,
						"brushType": "stroke",
						"scale": 2.5,
						"period": 4
					}
				}],
				"legend": [{
					"data": [
						"\u8bba\u6587\u5f71\u54cd\u529b"
					],
					"selected": {},
					"show": true,
					"left": "right",
					"orient": "vertical",
					"padding": 5,
					"itemGap": 10,
					"itemWidth": 25,
					"itemHeight": 14,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 0,
					"borderRadius": 0,
					"pageButtonItemGap": 5,
					"pageButtonPosition": "end",
					"pageFormatter": "{current}/{total}",
					"pageIconColor": "#2f4554",
					"pageIconInactiveColor": "#aaa",
					"pageIconSize": 15,
					"animationDurationUpdate": 800,
					"selector": false,
					"selectorPosition": "auto",
					"selectorItemGap": 7,
					"selectorButtonGap": 10
				}],
				"tooltip": {
					"show": true,
					"trigger": "item",
					"triggerOn": "mousemove|click",
					"axisPointer": {
						"type": "line"
					},
					"showContent": true,
					"alwaysShowContent": false,
					"showDelay": 0,
					"hideDelay": 100,
					"enterable": false,
					"confine": false,
					"appendToBody": false,
					"transitionDuration": 0.4,
					"textStyle": {
						"fontSize": 14
					},
					"borderWidth": 0,
					"padding": 5,
					"order": "seriesAsc"
				},
				"xAxis": [{
					"name": "\u5e74",
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"axisPointer": {
						"show": true,
						"type": "shadow",
						"triggerTooltip": true,
						"triggerOn": "mousemove|click"
					},
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					},
					"data": xList,
				}],
				"yAxis": [{
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					}
				}],
				"title": [{
					"show": true,
					"text": "近三年论文影响力",
					// "subtext": '2023年IF为预测值，仅供参考',
					"subtextStyle": {
						"color": 'red',
						"fontSize": 14,
					},
					"target": "blank",
					"subtarget": "blank",
					"left": "left",
					"padding": 5,
					"itemGap": 10,
					"textAlign": "auto",
					"textVerticalAlign": "auto",
					"triggerEvent": false
				}],
				"grid": [{
					"show": false,
					"zlevel": 0,
					"z": 2,
					"top": 100,
					"containLabel": false,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 1,
					"shadowOffsetX": 0,
					"shadowOffsetY": 0
				}]
			};
			document.getElementById('impactor').setAttribute('_echarts_instance_', '');
			chart_impactor.setOption(option_impactor);
		}, */
		// 发文类型
		publicationsType() {
			var chart_publicationsType = echarts.init(
				document.getElementById('publicationsType'), 'white', {
				renderer: 'canvas'
			});
			var bingData = this.postTypeData

			function genData(data) {
				const legendData = [];
				const seriesData = [];

				data.forEach(item => {
					const {
						name,
						value
					} = item;
					legendData.push(name);
					seriesData.push({
						name,
						value
					});
				});

				return {
					legendData: legendData,
					seriesData: seriesData
				};
			}
			const {
				legendData,
				seriesData
			} = genData(bingData);
			var option_publicationsType = {
				"animation": true,
				"animationThreshold": 2000,
				"animationDuration": 1000,
				"animationEasing": "cubicOut",
				"animationDelay": 0,
				"animationDurationUpdate": 300,
				"animationEasingUpdate": "cubicOut",
				"animationDelayUpdate": 0,
				"aria": {
					"enabled": false
				},
				"color": [
					"#5470c6",
					"#91cc75",
					"#fac858",
					"#ee6666",
					"#73c0de",
					"#3ba272",
					"#fc8452",
					"#9a60b4",
					"#ea7ccc"
				],
				"series": [{
					"type": "pie",
					"name": "\u53d1\u6587\u7c7b\u578b",
					"colorBy": "data",
					"legendHoverLink": true,
					"selectedMode": false,
					"selectedOffset": 10,
					"clockwise": true,
					"startAngle": 90,
					"minAngle": 0,
					"minShowLabelAngle": 0,
					"avoidLabelOverlap": true,
					"stillShowZeroSum": true,
					"percentPrecision": 2,
					"showEmptyCircle": true,
					"emptyCircleStyle": {
						"color": "lightgray",
						"borderColor": "#000",
						"borderWidth": 0,
						"borderType": "solid",
						"borderDashOffset": 0,
						"borderCap": "butt",
						"borderJoin": "bevel",
						"borderMiterLimit": 10,
						"opacity": 1
					},
					"data": seriesData,
					"radius": [
						"0%",
						"75%"
					],
					"center": [
						"50%",
						"50%"
					],
					"label": {
						"show": true,
						"margin": 8
					},
					"labelLine": {
						"show": true,
						"showAbove": false,
						"length": 15,
						"length2": 15,
						"smooth": false,
						"minTurnAngle": 90,
						"maxSurfaceAngle": 90
					},
					"tooltip": {
						"show": true,
						"trigger": "item",
						"triggerOn": "mousemove|click",
						"axisPointer": {
							"type": "line"
						},
						"showContent": true,
						"alwaysShowContent": false,
						"showDelay": 0,
						"hideDelay": 100,
						"enterable": false,
						"confine": false,
						"appendToBody": false,
						"transitionDuration": 0.4,
						"formatter": "{a} <br/>{b}: {c} ({d}%)",
						"textStyle": {
							"fontSize": 14
						},
						"borderWidth": 0,
						"padding": 5,
						"order": "seriesAsc"
					},
					"rippleEffect": {
						"show": true,
						"brushType": "stroke",
						"scale": 2.5,
						"period": 4
					}
				}],
				"legend": [{
					"data": legendData,
					"selected": {},
					"show": true,
					"left": "right",
					"orient": "vertical",
					"padding": 5,
					"itemGap": 10,
					"itemWidth": 25,
					"itemHeight": 14,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 0,
					"borderRadius": 0,
					"pageButtonItemGap": 5,
					"pageButtonPosition": "end",
					"pageFormatter": "{current}/{total}",
					"pageIconColor": "#2f4554",
					"pageIconInactiveColor": "#aaa",
					"pageIconSize": 15,
					"animationDurationUpdate": 800,
					"selector": false,
					"selectorPosition": "auto",
					"selectorItemGap": 7,
					"selectorButtonGap": 10
				}],
				"tooltip": {
					"show": true,
					"trigger": "item",
					"triggerOn": "mousemove|click",
					"axisPointer": {
						"type": "line"
					},
					"showContent": true,
					"alwaysShowContent": false,
					"showDelay": 0,
					"hideDelay": 100,
					"enterable": false,
					"confine": false,
					"appendToBody": false,
					"transitionDuration": 0.4,
					"textStyle": {
						"fontSize": 14
					},
					"borderWidth": 0,
					"padding": 5,
					"order": "seriesAsc"
				},
				"title": [{
					"show": true,
					"text": "\u53d1\u6587\u7c7b\u578b",
					"target": "blank",
					"subtarget": "blank",
					"left": "left",
					"padding": 5,
					"itemGap": 10,
					"textAlign": "auto",
					"textVerticalAlign": "auto",
					"triggerEvent": false
				}]
			};
			document.getElementById('publicationsType').setAttribute('_echarts_instance_', '');
			chart_publicationsType.setOption(option_publicationsType);
		},

		//引文指标
		citationMetrics() {
			var chart_citationMetrics = echarts.init(
				document.getElementById('citationMetrics'), 'white', {
				renderer: 'canvas'
			});
			let xList = this.getNotNullArray(this.echarts.jciList.xlist)
			let yList = this.getNotNullArray(this.echarts.jciList.ylist)
			var option_citationMetrics = {
				"animation": true,
				"animationThreshold": 2000,
				"animationDuration": 1000,
				"animationEasing": "cubicOut",
				"animationDelay": 0,
				"animationDurationUpdate": 300,
				"animationEasingUpdate": "cubicOut",
				"animationDelayUpdate": 0,
				"aria": {
					"enabled": false
				},
				"color": [
					"#5470c6",
					"#91cc75",
					"#fac858",
					"#ee6666",
					"#73c0de",
					"#3ba272",
					"#fc8452",
					"#9a60b4",
					"#ea7ccc"
				],
				"series": [{
					"type": "line",
					"name": "\u5f15\u6587\u6307\u6807",
					"connectNulls": false,
					"xAxisIndex": 0,
					"yAxisIndex": 0,
					"symbolSize": 4,
					"showSymbol": true,
					"smooth": true,
					"clip": true,
					"step": false,
					"data": yList,
					"hoverAnimation": true,
					"label": {
						"show": true,
						"margin": 8
					},
					"logBase": 10,
					"seriesLayoutBy": "column",
					"lineStyle": {
						"show": true,
						"width": 1,
						"opacity": 1,
						"curveness": 0,
						"type": "solid"
					},
					"areaStyle": {
						"opacity": 0
					},
					"markLine": {
						"silent": false,
						"precision": 2,
						"label": {
							"show": true,
							"margin": 8
						},
						"data": [{
							"name": "\u5e73\u5747\u503c",
							"type": "average"
						}]
					},
					"zlevel": 0,
					"z": 0,
					"rippleEffect": {
						"show": true,
						"brushType": "stroke",
						"scale": 2.5,
						"period": 4
					}
				}],
				"legend": [{
					"data": [
						"\u5f15\u6587\u6307\u6807"
					],
					"selected": {},
					"show": true,
					"left": "right",
					"orient": "vertical",
					"padding": 5,
					"itemGap": 10,
					"itemWidth": 25,
					"itemHeight": 14,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 0,
					"borderRadius": 0,
					"pageButtonItemGap": 5,
					"pageButtonPosition": "end",
					"pageFormatter": "{current}/{total}",
					"pageIconColor": "#2f4554",
					"pageIconInactiveColor": "#aaa",
					"pageIconSize": 15,
					"animationDurationUpdate": 800,
					"selector": false,
					"selectorPosition": "auto",
					"selectorItemGap": 7,
					"selectorButtonGap": 10
				}],
				"tooltip": {
					"show": true,
					"trigger": "item",
					"triggerOn": "mousemove|click",
					"axisPointer": {
						"type": "line"
					},
					"showContent": true,
					"alwaysShowContent": false,
					"showDelay": 0,
					"hideDelay": 100,
					"enterable": false,
					"confine": false,
					"appendToBody": false,
					"transitionDuration": 0.4,
					"textStyle": {
						"fontSize": 14
					},
					"borderWidth": 0,
					"padding": 5,
					"order": "seriesAsc"
				},
				"xAxis": [{
					"name": "\u5e74",
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"axisPointer": {
						"show": true,
						"type": "shadow",
						"triggerTooltip": true,
						"triggerOn": "mousemove|click"
					},
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					},
					"data": xList,
				}],
				"yAxis": [{
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					}
				}],
				"title": [{
					"show": true,
					"text": "近三年引文指标",
					"target": "blank",
					"subtarget": "blank",
					"left": "left",
					"padding": 5,
					"itemGap": 10,
					"textAlign": "auto",
					"textVerticalAlign": "auto",
					"triggerEvent": false
				}],
				"grid": [{
					"show": false,
					"zlevel": 0,
					"z": 2,
					"top": 100,
					"containLabel": false,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 1,
					"shadowOffsetX": 0,
					"shadowOffsetY": 0
				}]
			};
			document.getElementById('citationMetrics').setAttribute('_echarts_instance_', '');
			chart_citationMetrics.setOption(option_citationMetrics);
		},
		//即时指数
		instantIndices() {
			var chart_instantIndices = echarts.init(
				document.getElementById('instantIndices'), 'white', {
				renderer: 'canvas'
			});
			let xList = this.getNotNullArray(this.echarts.instantIndexCharts.xlist)
			let yList = this.getNotNullArray(this.echarts.instantIndexCharts.ylist)
			var option_instantIndices = {
				"animation": true,
				"animationThreshold": 2000,
				"animationDuration": 1000,
				"animationEasing": "cubicOut",
				"animationDelay": 0,
				"animationDurationUpdate": 300,
				"animationEasingUpdate": "cubicOut",
				"animationDelayUpdate": 0,
				"aria": {
					"enabled": false
				},
				"color": [
					"#5470c6",
					"#91cc75",
					"#fac858",
					"#ee6666",
					"#73c0de",
					"#3ba272",
					"#fc8452",
					"#9a60b4",
					"#ea7ccc"
				],
				"series": [{
					"type": "bar",
					"name": "\u5373\u65f6\u6307\u6570",
					"xAxisIndex": 0,
					"yAxisIndex": 0,
					"legendHoverLink": true,
					"data": yList,
					"realtimeSort": false,
					"showBackground": false,
					"stackStrategy": "samesign",
					"cursor": "pointer",
					"barMinHeight": 0,
					"barCategoryGap": "20%",
					"barGap": "30%",
					"large": false,
					"largeThreshold": 400,
					"seriesLayoutBy": "column",
					"datasetIndex": 0,
					"clip": true,
					"zlevel": 0,
					"z": 2,
					"label": {
						"show": true,
						"position": "top",
						"margin": 8
					},
					"rippleEffect": {
						"show": true,
						"brushType": "stroke",
						"scale": 2.5,
						"period": 4
					}
				}],
				"legend": [{
					"data": [
						"\u5373\u65f6\u6307\u6570"
					],
					"selected": {},
					"show": true,
					"left": "right",
					"orient": "vertical",
					"padding": 5,
					"itemGap": 10,
					"itemWidth": 25,
					"itemHeight": 14,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 0,
					"borderRadius": 0,
					"pageButtonItemGap": 5,
					"pageButtonPosition": "end",
					"pageFormatter": "{current}/{total}",
					"pageIconColor": "#2f4554",
					"pageIconInactiveColor": "#aaa",
					"pageIconSize": 15,
					"animationDurationUpdate": 800,
					"selector": false,
					"selectorPosition": "auto",
					"selectorItemGap": 7,
					"selectorButtonGap": 10
				}],
				"tooltip": {
					"show": true,
					"trigger": "item",
					"triggerOn": "mousemove|click",
					"axisPointer": {
						"type": "line"
					},
					"showContent": true,
					"alwaysShowContent": false,
					"showDelay": 0,
					"hideDelay": 100,
					"enterable": false,
					"confine": false,
					"appendToBody": false,
					"transitionDuration": 0.4,
					"textStyle": {
						"fontSize": 14
					},
					"borderWidth": 0,
					"padding": 5,
					"order": "seriesAsc"
				},
				"xAxis": [{
					"name": "\u5e74",
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"axisPointer": {
						"show": true,
						"type": "shadow",
						"triggerTooltip": true,
						"triggerOn": "mousemove|click"
					},
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					},
					"data": xList
				}],
				"yAxis": [{
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					}
				}],
				"title": [{
					"show": true,
					"text": "近三年即时指数\n",
					"target": "blank",
					"subtext": "\u5373\u65f6\u6307\u6570\uff1a\u7528\u671f\u520a\u4e2d\u67d0\u4e00\u5e74\u4e2d\u53d1\u8868\u7684\u6587\u7ae0\u5728\u5f53\u5e74\u88ab\u5f15\u7528\u6b21\u6570\u9664\u4ee5\u540c\u5e74\u53d1\u8868\u6587\u7ae0\u7684\u603b\u6570\u5f97\u5230\u7684\u6307\u6570\uff0c\u53cd\u6620\u671f\u520a\u4e2d\u8bba\u6587\u5f97\u5230\u5f15\u7528\u7684\u901f\u5ea6",
					"subtarget": "blank",
					"left": "left",
					"padding": 5,
					"itemGap": 10,
					"textAlign": "auto",
					"textVerticalAlign": "auto",
					"triggerEvent": false
				}],
				"grid": [{
					"show": false,
					"zlevel": 0,
					"z": 2,
					"top": 100,
					"containLabel": false,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 1,
					"shadowOffsetX": 0,
					"shadowOffsetY": 0
				}]
			};
			document.getElementById('instantIndices').setAttribute('_echarts_instance_', '');
			chart_instantIndices.setOption(option_instantIndices);
		},
		//半衰期
		halfLife() {
			var chart_halfLife = echarts.init(
				document.getElementById('halfLife'), 'white', {
				renderer: 'canvas'
			});
			let xList = this.getNotNullArray(this.echarts.citationHalfLifeCharts.xlist)
			let yListOne = this.getNotNullArray(this.echarts.citationHalfLifeCharts.ylist)
			let yListTwo = this.getNotNullArray(this.echarts.halfLifeList.ylist)
			var option_halfLife = {
				"animation": true,
				"animationThreshold": 2000,
				"animationDuration": 1000,
				"animationEasing": "cubicOut",
				"animationDelay": 0,
				"animationDurationUpdate": 300,
				"animationEasingUpdate": "cubicOut",
				"animationDelayUpdate": 0,
				"aria": {
					"enabled": false
				},
				"color": [
					"#5470c6",
					"#91cc75",
					"#fac858",
					"#ee6666",
					"#73c0de",
					"#3ba272",
					"#fc8452",
					"#9a60b4",
					"#ea7ccc"
				],
				"series": [{
					"type": "bar",
					"name": "\u88ab\u5f15\u534a\u8870\u671f",
					"xAxisIndex": 0,
					"yAxisIndex": 0,
					"legendHoverLink": true,
					"data": yListOne,
					"realtimeSort": false,
					"showBackground": false,
					"stackStrategy": "samesign",
					"cursor": "pointer",
					"barMinHeight": 0,
					"barCategoryGap": "20%",
					"barGap": "30%",
					"large": false,
					"largeThreshold": 400,
					"seriesLayoutBy": "column",
					"datasetIndex": 0,
					"clip": true,
					"zlevel": 0,
					"z": 2,
					"label": {
						"show": true,
						"position": "top",
						"margin": 8
					},
					"tooltip": {
						"show": true,
						"trigger": "axis",
						"triggerOn": "mousemove|click",
						"axisPointer": {
							"type": "line"
						},
						"showContent": true,
						"alwaysShowContent": false,
						"showDelay": 0,
						"hideDelay": 100,
						"enterable": false,
						"confine": false,
						"appendToBody": false,
						"transitionDuration": 0.4,
						"textStyle": {
							"fontSize": 14
						},
						"borderWidth": 0,
						"padding": 5,
						"order": "seriesAsc"
					},
					"rippleEffect": {
						"show": true,
						"brushType": "stroke",
						"scale": 2.5,
						"period": 4
					}
				},
				{
					"type": "bar",
					"name": "\u5f15\u7528\u534a\u8870\u671f",
					"xAxisIndex": 0,
					"yAxisIndex": 0,
					"legendHoverLink": true,
					"data": yListTwo,
					"realtimeSort": false,
					"showBackground": false,
					"stackStrategy": "samesign",
					"cursor": "pointer",
					"barMinHeight": 0,
					"barCategoryGap": "20%",
					"barGap": "30%",
					"large": false,
					"largeThreshold": 400,
					"seriesLayoutBy": "column",
					"datasetIndex": 0,
					"clip": true,
					"zlevel": 0,
					"z": 2,
					"label": {
						"show": true,
						"position": "top",
						"margin": 8
					},
					"tooltip": {
						"show": true,
						"trigger": "axis",
						"triggerOn": "mousemove|click",
						"axisPointer": {
							"type": "line"
						},
						"showContent": true,
						"alwaysShowContent": false,
						"showDelay": 0,
						"hideDelay": 100,
						"enterable": false,
						"confine": false,
						"appendToBody": false,
						"transitionDuration": 0.4,
						"textStyle": {
							"fontSize": 14
						},
						"borderWidth": 0,
						"padding": 5,
						"order": "seriesAsc"
					},
					"rippleEffect": {
						"show": true,
						"brushType": "stroke",
						"scale": 2.5,
						"period": 4
					}
				}
				],
				"legend": [{
					"data": [
						"\u88ab\u5f15\u534a\u8870\u671f",
						"\u5f15\u7528\u534a\u8870\u671f"
					],
					"selected": {},
					"show": true,
					"left": "right",
					"orient": "vertical",
					"padding": 5,
					"itemGap": 10,
					"itemWidth": 25,
					"itemHeight": 14,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 0,
					"borderRadius": 0,
					"pageButtonItemGap": 5,
					"pageButtonPosition": "end",
					"pageFormatter": "{current}/{total}",
					"pageIconColor": "#2f4554",
					"pageIconInactiveColor": "#aaa",
					"pageIconSize": 15,
					"animationDurationUpdate": 800,
					"selector": false,
					"selectorPosition": "auto",
					"selectorItemGap": 7,
					"selectorButtonGap": 10
				}],
				"tooltip": {
					"show": true,
					"trigger": "item",
					"triggerOn": "mousemove|click",
					"axisPointer": {
						"type": "line"
					},
					"showContent": true,
					"alwaysShowContent": false,
					"showDelay": 0,
					"hideDelay": 100,
					"enterable": false,
					"confine": false,
					"appendToBody": false,
					"transitionDuration": 0.4,
					"textStyle": {
						"fontSize": 14
					},
					"borderWidth": 0,
					"padding": 5,
					"order": "seriesAsc"
				},
				"xAxis": [{
					"name": "\u5e74",
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"axisPointer": {
						"show": true,
						"type": "shadow",
						"triggerTooltip": true,
						"triggerOn": "mousemove|click"
					},
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					},
					"data": xList,
				}],
				"yAxis": [{
					"show": true,
					"scale": false,
					"nameLocation": "end",
					"nameGap": 15,
					"gridIndex": 0,
					"inverse": false,
					"offset": 0,
					"splitNumber": 5,
					"minInterval": 0,
					"splitLine": {
						"show": true,
						"lineStyle": {
							"show": true,
							"width": 1,
							"opacity": 1,
							"curveness": 0,
							"type": "solid"
						}
					}
				}],
				"title": [{
					"show": true,
					"text": "近三年被引半衰期和引用半衰期\n",
					"target": "blank",
					"subtext": "\u88ab\u5f15\u534a\u8870\u671f\uff1a\u4e00\u79cd\u671f\u520a\u4ece\u5f53\u524d\u5e74\u5ea6\u5411\u524d\u63a8\u7b97\u5f15\u7528\u6570\u5360\u622a\u6b62\u5f53\u524d\u5e74\u5ea6\u88ab\u5f15\u7528\u671f\u520a\u7684\u603b\u5f15\u7528\u6570 50% \u7684\u65f6\u95f4\n\n\u5f15\u7528\u534a\u8870\u671f\uff1a\u53c2\u8003\u6587\u732e\u6570\u8fbe\u5230\u5f53\u524d\u671f\u520a\u53d1\u8868\u7684\u8bba\u6587\u4e2d\u7684\u53c2\u8003\u6587\u732e\u6570\u7684 50% \u6240\u91210\u8981\u7684\u65f6\u95f4",
					"subtarget": "blank",
					"left": "left",
					"padding": 5,
					"itemGap": 10,
					"textAlign": "auto",
					"textVerticalAlign": "auto",
					"triggerEvent": false
				}],
				"grid": [{
					"show": false,
					"zlevel": 0,
					"z": 2,
					"top": 120,
					"containLabel": false,
					"backgroundColor": "transparent",
					"borderColor": "#ccc",
					"borderWidth": 1,
					"shadowOffsetX": 0,
					"shadowOffsetY": 0
				}]
			};
			document.getElementById('halfLife').setAttribute('_echarts_instance_', '');
			chart_halfLife.setOption(option_halfLife);
		},
		//所有echarts图
		echartsImage() {
			this.oaPercentage()
			this.publicationsNumber()
			// this.impactor()
			this.citationMetrics()
			this.instantIndices()
			this.halfLife()
		},
		// 发文类型echarts图
		postType() {
			let paramap = {
				'journalName': this.journalName,
				'activeName': 'AHCI'
			}
			getPieEchartSCi(paramap)
				.then(res => {
					this.postTypeData = res.data
					this.publicationsType()
				})
				.catch(function () { }.bind(this));

		},
		//通过判断数组是否为空返回其真是chartshuju
		getNotNullArray(array) {
			let nowArray = array.filter(f => f != null)
			if (nowArray.length == 0) return [0, 0, 0, 0, 0]
			else return nowArray

		},

		//评价的发布是否展示
		show() {
			this.showPubilsh = true
		},

		//发布评价
		publishAppraise(index) {
			this.pinglun.journalName = this.basicInformation.journalName
			if (this.pinglun.content.trim().length > 0) {
				this.showPubilsh = true
				getPublishAppraiseSCi(this.pinglun)
					.then(res => {
						this.$message.success("提交成功")
						this.getpinglun()
						this.showPubilsh = false
						this.pinglun.content = ''

					})
					.catch(function () { }.bind(this));
			} else {
				this.$message.warning("请填写内容")
			}
		},
		handleCurrentChange(val) {
			this.pageNo = val
			this.getpinglun()
		},

		//获取评价
		getpinglun() {
			let ob = {
				status: 1,
				pageNo: this.pageNo,
				journalName: this.basicInformation.journalName
			}
			this.loading = true
			getCommentSCi(ob)
				.then(res => {
					this.pageNo = res.pageNo
					this.total = res.dataCount - 0
					this.showPinglun = res.data
					this.loading = false
				})
				.catch(function () { }.bind(this));
		},

		// 获取子评论
		getChildrenComment(index, childIndex) {
			let ob = {
				status: 2,
				journalName: this.basicInformation.journalName,
				topReplyId: this.topIdComment,
			}
			this.loading = true
			getCommentSCi(ob)
				.then(res => {
					this.showPinglun[index].children = res.data
					this.showPinglun[index].pageNo = res.pageNo
					this.showPinglun[index].total = res.dataCount - 0
					this.loading = false
				})
				.catch(function () { }.bind(this));
		},


		//评价点赞
		toggleLike(index, childrenIndex) {
			let id = -1;

			if (childrenIndex == -1) {

				id = this.showPinglun[index].id

				if (!this.showPinglun[index].liked) {
					this.showPinglun[index].liked = true
					this.showPinglun[index].likedTimes += 1;
				} else {
					this.showPinglun[index].liked = false
					this.showPinglun[index].likedTimes -= 1;
				}
			} else {

				id = this.showPinglun[index].children[childrenIndex].id

				if (!this.showPinglun[index].children[childrenIndex].liked) {
					this.showPinglun[index].children[childrenIndex].liked = true
					this.showPinglun[index].children[childrenIndex].likedTimes += 1;
				} else {
					this.showPinglun[index].children[childrenIndex].liked = false
					this.showPinglun[index].children[childrenIndex].likedTimes -= 1;
				}
			}



			let ob = {
				liked: this.showPinglun[index].liked,
				bizType: 'IAMSET',
				bizId: id
			}
			getToggleLikeSCi(ob).then(() => {
				this.$forceUpdate()
			})
				.catch((error) => {
					console.error(error);
				});

		},
		//是否展开子评论
		publish1(item, index) {
			this.topIdComment = item.id
			this.showPinglun[index].hidden = !this.showPinglun[index].hidden
			if (this.showPinglun[index].hidden) {
				this.getChildrenComment(index)
			}

		},
		//子评论回复是否展开三级
		publish2(item, index, childIndex) {
			this.showPinglun[index].children[childIndex].hidden = !this.showPinglun[index].children[childIndex].hidden
			this.$forceUpdate()
		},


		//回复评价
		SendCommentReply(item, index) {
			if (this.isLogin == true) {
				this.replyContent.targetReplyId = item.id
				this.replyContent.targetUserId = item.userId
				this.replyContent.journalName = this.basicInformation.journalName
				this.replyContent.topReplyId = this.topIdComment

				if (this.replyContent.content.trim().length > 0) {

					getPublishAppraiseSCi(this.replyContent)
						.then(res => {
							if (res.msg == 'ok') {
								item.hidden = false
								this.$message.success("提交成功")
								item.replyTimes += 1
							}
							this.getChildrenComment(index)
							this.replyContent.content = ''

						})
						.catch(function () { }.bind(this));
				} else {
					this.$message.warning("请填写完整的内容")
				}
			} else {
				this.$message.warning("请您先登录")
			}
		},
		//回复二级评价
		SendCommentReply1(item, index, childIndex) {
			if (this.isLogin == true) {
				this.replyContent1.topReplyId = this.topIdComment
				this.replyContent1.targetReplyId = item.children[childIndex].id
				this.replyContent1.journalName = this.basicInformation.journalName
				this.replyContent1.targetUserId = item.children[childIndex].userId

				if (this.replyContent1.content) {
					getPublishAppraiseSCi(this.replyContent1)
						.then(res => {
							if (res.msg == 'ok') {
								item.children[childIndex].hidden = false
								this.$message.success("提交成功")
							}
							this.getChildrenComment(index)
							this.replyContent1.content = ''

						})
						.catch(function () { }.bind(this));
				} else {
					this.$message.warning("请填写完整的内容")
				}
			} else {
				this.$message.warning("请您先登录")
			}

		},
		//推荐期刊-图片被背景图片覆盖
		randomJournalList() {

			let _this = this
			let paramMap = {
				mixPpf: _this.$route.query.mixPpf,
				id: _this.$route.query.id,
				activeName: _this.activeName,

			}
			getRandomJournalListSCi(paramMap)
				.then(function (res) {
					res.data.forEach(item => {
						// console.log(item);
						if (item.indexFaceHref) {
							item.imageLoadFailed = true
						} else {
							item.imageLoadFailed = false
						}
					})
					_this.journalList = res.data
				})
				.catch(function () { });
		},
		updateJournalDetails(journalIndex) {


			let activeName = this.journalDetails.activeName
			let id = this.journalDetails.id
			//如果id一样阻止跳转
			if (id == this.journalList[journalIndex].id) {
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'  // 可选，实现平滑滚动
				});
				return
			}
			this.journalDetails = this.journalList[journalIndex]

			this.journalDetails['activeName'] = activeName

			this.$router.replace({
				name: "journalDetailsAHCI",
				query: this.journalDetails
			})

			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'  // 可选，实现平滑滚动
			});
			setTimeout(() => {
				this.reload()
			}, 500)

		},
		//电话号码校验
		validatePhone() {
			if (this.journalErrorMessage.phone.length === 0) {
				this.phoneError = false;
				alert('请输入电话号码');
			} else if (!/^1[3456789]\d{9}$/.test(this.journalErrorMessage.phone)) {
				this.phoneError = false;
				alert('请填写正确的电话号码');
			} else {
				this.phoneError = true;
			}
		},
		//错误信息提交
		postError() {
			this.$refs.journalErrorMessage.validate(valid => {
				if (valid) {
					let paramMap = {
						journalName: this.journalErrorMessage.name,
						issn: this.journalErrorMessage.number,
						phone: this.journalErrorMessage.phone,
						errorMessage: this.journalErrorMessage.errorContent
					}
					getPostErrorSCi(paramMap)
						.then(res => {
							this.$notify({
								title: "成功",
								type: "success",
								message: "提交成功！",
							});
							this.dialogFormVisible = false;
						})
						.catch(error => {
							console.error(error);
						});
				} else {
					// 表单验证不通过，显示错误信息
				}
			});

		},
		//跳登录
		jumplogin() {
			this.$router.push('/login')
			setTimeout(() => {
				this.$router.go(0)
			}, 100)
		},
		//规范时间
		formatDate(date) {
			const dateTime = new Date(date);
			const year = dateTime.getFullYear();
			const month = dateTime.getMonth() + 1;
			const day = dateTime.getDate();
			const hours = dateTime.getHours();
			const minutes = dateTime.getMinutes();
			const seconds = dateTime.getSeconds();

			return `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`;
		},
		// 原有方法的注释
		isnullFilter(value) {

			if (value == 'null' || value == "" || value == null || value == undefined) {
				return "暂无数据"
			} else {
				return value
			}
		},

	},


}
</script>

<style lang="scss" scoped>
//默认期刊图片背景图
.fallback-bg {
	height: 222px;
	width: 168px;
	background-image: url('../../assets/img/no_photo.png');
	background-repeat: no-repeat;
	background-size: cover;
	color: white;

	.three {
		height: 42px;
		max-width: 150px;
		padding: 40px 5px 0px 24px;
		overflow: hidden;
		font-weight: bold;
	}

	.eight {
		max-width: 150px;
		padding: 50px 5px 0px 24px;
		margin-top: 10px;
		font-style: oblique;
		font-size: 12px;
	}
}

.jour_err_btn {
	padding: 10px 26px;
	color: #fff;
	background-color: #bf7c16;
	border-radius: 4px;

	&:hover {
		cursor: pointer;
		color: white;
		background-color: #dcaa5d;
	}
}

::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active{
	background-color: #42a1ff;
	color: white;
}

.journal_details {
	width: 100%;
	min-width: 1200px;
}

.details_content {
	width: 1200px;
	min-width: 1200px;
	margin: 0 auto;
}

.content_first {
	width: 100%;
	display: flex;
	gap: 15px;
}

.fLeft {
	flex: 7;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.fLeft_bottom,
.fRight_top,
.fRight_bottom {}

//数据来源
.tooltip {
	position: absolute;
	bottom: 28px;
	left: 20px;
	padding: 5px;
	background-color: #333333;
	color: #fff;
}

//oa文章占比

.fRight_bottom {
	background-color: #fff;
	height: 100%;
	// width: 100%;	
}

.chart-container {
	width: 100%;
}

.fLeft_top {
	display: flex;
	align-items: flex-start;
}

.fRight_top {
	background-color: #ffffff;
}

.fLeft_topl {
	flex: 2;
	text-align: center;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.fLeft_topl1 {
	padding: 15px;
	border-bottom: 1px solid #18305b;
	background-color: #f2f6fc;
	font-weight: bold;
	font-size: 20px;
}

.fLeft_topl1_title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.fLeft_topl22 {
	padding: 20px;
}

.fLeft_topl2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	background-color: #fff;
	margin-top: 10px;
}

.fLeft_topl2_btn {
	margin: 32px 0px;
	padding: 10px 26px;
	color: #fff;
	background-color: #bf7c16;
	border-radius: 4px;

	&:hover {
		cursor: pointer;
		color: white;
		background-color: #dcaa5d;
	}
}

.jianjie {
	overflow: auto;
	height: 425px;
	padding: 15px;
	line-height: 1.8;
	color: #666666;
	font-size: 14px;
	text-align: justify;
}

.fLeft_topR {
	width: 74%;
	background-color: rgb(255, 255, 255);
	padding: 20px;
	padding-left: 0;
}

.fLeft_topRZ {
	margin: 20px 0px 0px 0px;
	width: 100%;
	display: flex;

}

.fLeft_topRZL {
	width: 40%;
	margin-left: 45px;
	font-weight: bolder;
}

.fLeft_topRZR {
	width: 40%;
	font-size: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

//期刊指标
.fLeft_bottom {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #fff;
}

.fLeft_bottom_zong {
	display: flex;
	line-height: 30px;
	margin: 10px 0px;
}

.fLeft_bottom_zong_left {
	width: 30%;
	font-weight: bolder;
}

.fLeft_bottom_zong_right {
	width: 60%;

}

::v-deep .percentage .el-progress-bar {
	padding-right: 74px;
}

::v-deep .percentage .el-progress__text {
	margin-left: -15px;
}


.fRight {
	flex: 3;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

//前两块结束
//第三块开始，中科院分区
.content_second {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 15px 0;
	background-color: #fff;
}

.content_second_zong {
	height: 300px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0px;
}

.content_second_zong_item {
	width: 20%;
	display: flex;
	text-align: center;
	flex-direction: column;
}

.content_second_zong_item1 {
	height: 60px;
	font-weight: bolder;
}

.content_second_zong_item2 {
	height: 180px;
	display: flex;
	justify-content: center;
}

.smallCategory {
	display: block;
	padding: 0px 0px 10px 20px;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
	background-color: #42a1ff;
	color: white;
}

//金字塔
.triangle {
	width: 0;
	height: 0;
	left: 105px;
	top: 1px;
	border-left: 36px solid transparent;
	/* 左边透明 */
	border-right: 36px solid transparent;
	/* 右边透明 */
	border-bottom: 50px solid hsl(359.76deg 100% 49.02%);
	/* 底边高度和颜色 */
	position: absolute;
}

.trapezoid {
	left: 77px;
	top: 45px;
	width: 70px;
	/* 底边宽度 */
	border-bottom: 40px solid hsl(45.9deg 99.21% 49.61%);
	/* 底边高度和颜色 */
	border-left: 30px solid transparent;
	/* 左边透明 */
	border-right: 30px solid transparent;
	/* 右边透明 */
	height: 0;
	position: absolute;
}

.trapezoid1 {
	left: 39px;
	top: 85px;
	width: 130px;
	/* 底边宽度 */
	border-bottom: 40px solid hsl(147.91deg 97.73% 34.51%);
	/* 底边高度和颜色 */
	border-left: 30px solid transparent;
	/* 左边透明 */
	border-right: 30px solid transparent;
	/* 右边透明 */
	height: 0;
	position: absolute;
}

.trapezoid2 {
	left: 0px;
	top: 124px;
	width: 188px;
	border-bottom: 40px solid hsl(205.76deg 97.95% 38.24%);
	border-left: 30px solid transparent;
	border-right: 30px solid transparent;
	height: 0;
	position: absolute;
}

.triangle,
.trapezoid,
.trapezoid1,
.trapezoid2 {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
}


.bigPartition1 {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	margin-top: 20px;
	// padding: 5px;
	transform: translate(-50%, -50%);
	text-align: center;
	color: black;
	font-size: 0.5em;
	white-space: pre-wrap;
	word-wrap: break-word;
}

.bigPartition {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: 20px;
	transform: translate(-50%, -50%);
	text-align: center;
	color: black;
	font-size: 0.5em;
	white-space: pre-wrap;
	word-wrap: break-word;
}

//第三块结束
//第四块开始

.content_third,
.content_fourth,
.content_fifth,
.content_sixth {
	background-color: #fff;
	margin: 15px 0;
	margin-bottom: 0;
}

//第四块结束
//第五块开始
.content_fourth2 {
	position: relative;
	width: 100%;

}

.content_fourth21 {
	display: flex;
}

/*遮罩层*/


.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.44);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	z-index: 998;
}

.overlay_content {
	width: 36%;
    border-radius: 6px;
	height: 400px;
	margin: 0 auto;
	border: 1px solid #c0d6ff;
	background-color: #ffffff;
	background-image: url(../../assets/img/bi_top.png), url(../../assets/img/bi_bottom.png);
	background-repeat: no-repeat, no-repeat;
	background-position: top, bottom;
	background-size: 100% auto, 100% auto;
	display: flex;
	flex-direction: column;

}

.overlay_content_p1 {
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #262626;
	font-weight: 600;
}

.overlay_content_p2 {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #666;
	font-size: 14px;
	margin: 0px;
}

.loginButton {
	margin: 0 auto;
	margin-top: 10px;
	width: 80px;
	text-align: center;
	color: #ffffff;
	padding: 8px 5px;
	background-color: #bf7c16;
	border: 1px solid #bf7c16;
	border-radius: 4px;
	transition: all .3s;
	font-size: 14px;
	font-weight: 500;
	box-sizing: border-box;
	height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		cursor: pointer;
		background-color: #dcaa5d;
		border: 1px solid #dcaa5d;
	}
}

.gd_btn {
	width: 10%;
	margin: 0;
}

.overlay_bottom {
	margin: 10px 0px 0px 10px;
}

.overlay_bottom img {
	width: 98%;
	height: 200px;
}


/*遮罩层*/


.fourth2_left {
	width: 35%;
	padding: 10px 25px 15px 25px;
	border-right: 1px solid #d2d2d2;
}

.serialNumber {
	font-size: 20px;
}

.fourth2_right {
	width: 65%;
	margin-left: 20px;
}

.el_tab {
	width: 100%;
}

::v-deep .el-tab-pane {
	word-break: break-all;
	display: flex;
	justify-content: center;
}

//第五块结束
//第六块开始
.content_fifth {
	display: flex;
	flex-direction: column;
}

.content_fifth1 {
	margin: 25px 20px;
	margin-bottom: 0;
}

.content_fifth2 {
	margin: 0px 20px 20px 20px;
	display: flex;
	flex-direction: column;
}

.content_fifth21 {
	display: flex;
	flex-direction: column;
	padding: 10px 0px;
	border-bottom: 1px solid #d2d2d2;
}

.content_fifth21_zong {
	display: flex;
}

.content_fifth21_left {
	width: 10%;
	padding-top: 10px;
}

.content_fifth21_right {
	flex: 1;
}

.comment1 {
	margin: 5px 0px;
	display: flex;
	align-items: center;
}

.content_fifth21_right span {
	margin-right: 8px;
}

.content_fifth21_right img {
	width: 18px;
	height: 18px;
	padding: 2px 3px 0px 0px;
}

.user-icon {
	width: 55px;
	height: 55px;
	border-radius: 50%;
}

.fall_user_icon {
	background-color: darkgrey;
	width: 55px;
	height: 55px;
	border-radius: 50%;
}

.replyComment {
	display: flex;
	flex-direction: column;
	margin: 8px 60px;
}

.el-pager li:focus {
	color: #ff5722;
}

// 顶部导航
.page-content-top {
	padding: 30px 0;
	margin: 0 15px;
}
</style>