<template>
    <div class="app_bottom_warp">
        <el-popover placement="top" trigger="hover" v-for="(item, index) in mediaList" :key="index">
            <div :style="{ 'backgroundImage': `url(${item.iconSrc})` }" slot="reference" class="warp_item_icon"
                @mouseover="changeIcon(index, true)" @mouseleave="changeIcon(index, false)"></div>
            <div class="show_warp_image_box">
                <img :src="item.image" alt="" class="show_warp_image_">
            </div>
        </el-popover>
    </div>
</template>

<script>
import { mediaList } from "@/pages/about/data"

export default {
    data() {
        return {
            mediaList
        }
    },
    methods: {
        changeIcon(index, hover) {
            mediaList[index].iconSrc = hover ? mediaList[index].hicon : mediaList[index].icon;
        }
    }
}
</script>

<style lang='scss'>
.app_bottom_warp {
    display: flex;

    .warp_item_icon {
        width: 30px;
        height: 30px;
        margin: 0 10px;
        transition: all .3s;
        border-radius: 50%;
        background-size: contain;

        &:hover {
            cursor: pointer;
        }
    }
}

.show_warp_image_box {
    display: flex;
    justify-content: center;

    .show_warp_image_ {
        width: 150px;
        height: 150px;
    }
}
</style>