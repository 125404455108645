export const serviceList = [
    {
        "icon": require("@/assets/img/about/zixun.png"),
        "path": "/journalSci",
        "titleRichText": "期刊咨询",
        "contentRichText": "提供全面的期刊信息咨询服务。包括不同学科领域内各类期刊的征稿范围、审稿周期、影响因子、发表难度等方面的详细情况。帮助你了解哪些期刊适合你的研究方向和学术水平，以便做出更明智的投稿选择。同时，还可以解答关于期刊投稿流程、格式要求等方面的疑问，为你的论文发表之路提供专业的指导。"
    },
    {
        "icon": require("@/assets/img/about/xuankan.png"),
        "path": "/selective",
        "titleRichText": "选刊服务",
        "contentRichText": "依据研究成果特点和学术水平，为你精准筛选最适合的投稿期刊。专业团队通过综合考虑研究主题、研究方法、创新性等因素，结合不同期刊的收稿范围、影响力、审稿速度等指标，从众多国内外期刊中挑选出最适合论文的期刊。选刊服务不仅节省你的时间和精力，还能提高你的论文被录用的几率。"
    },
    {
        "icon": require("@/assets/img/about/fanyi.png"),
        "path": "/translate",
        "titleRichText": "翻译润色",
        "contentRichText": "由专业的翻译人员和语言专家为你的论文进行高质量的翻译和润色。对于非英语母语的作者来说，语言表达的准确性和流畅性可能会影响论文的质量和接受度。我们的翻译服务确保将你的中文论文准确地翻译成英文，同时保持原文的学术风格和逻辑结构。润色服务则注重改善语言的语法、拼写、标点等方面的错误，提高语言的表达效果，使你的论文更加专业、易读。"
    },
    {
        "icon": require("@/assets/img/about/xiugai.png"),
        "path": "/polishing",
        "titleRichText": "修改辅导",
        "contentRichText": "提供专业的论文修改辅导服务。我们的专家团队会仔细阅读你的论文，指出其中存在的问题和不足之处，并提供具体的修改建议。无论是结构不合理、论证不充分，还是数据处理不当、参考文献不规范等问题，我们都能为你提供有针对性的解决方案。修改辅导服务旨在帮助你提高论文的质量，使其更符合学术规范和期刊要求。"
    },
    {
        "icon": require("@/assets/img/about/paiban.png"),
        "path": "/polishing",
        "titleRichText": "编校排版",
        "contentRichText": "对你的论文进行专业的编辑、校对和排版服务。编辑人员会对论文的内容进行审核和优化，确保语言表达准确、逻辑清晰。校对人员会仔细检查论文中的错别字、语法错误、标点符号等问题，保证论文的质量。排版人员则会根据期刊的要求，对论文进行格式调整和排版设计，使其更加美观、规范。编校排版服务可以让你的论文在形式上更加完美，提高其可读性和专业性。"
    },
    {
        "icon": require("@/assets/img/about/huiyi.png"),
        "path": "/meeting",
        "titleRichText": "国际会议",
        "contentRichText": "组织和承办各类国际学术会议，为你提供一个展示研究成果、交流学术思想的平台。我们的国际会议涵盖多个学科领域，邀请国内外知名专家学者担任演讲嘉宾，吸引了众多学术界人士的参与。参加国际会议可以让你了解最新的学术动态，拓展学术视野，建立学术合作关系。同时，我们还提供优秀会议论文推荐发表服务，为你的学术研究提供更多的机会和渠道。"
    },
    {
        "icon": require("@/assets/img/about/chachong.png"),
        "path": "/difference",
        "titleRichText": "查重报告",
        "contentRichText": "为你提供准确、详细的论文查重报告。通过最新的查重系统，我们可以检测出你的论文与已发表文献的重复率，并标注出重复的内容和来源。查重报告可以帮助你了解自己论文的原创性，避免抄袭和学术不端行为。同时，我们还可以根据查重结果为你提供降低重复率的建议和方法，确保你的论文符合学术规范和期刊要求。"
    },
    {
        "icon": require("@/assets/img/about/jiansuo.png"),
        "path": "/retrieval",
        "titleRichText": "检索报告",
        "contentRichText": "通过精准的检索和查询，确定你的论文、著作或其他学术成果是否被国内外知名数据库收录。对于论文收录情况，我们会明确指出被收录的数据库名称，如 SCI、SSCI、EI 等。同时，会详细列出收录的时间、卷期号、页码、引用次数等具体信息，以便你准确掌握自己成果的传播范围和影响力。"
    }
]

export const businessList = [
    {
        title: "产品业务咨询",
        content: "关于所有产品相关的业务咨询，请联系我们",
        email: "edit@iamset.ac.cn",
        image: require("@/assets/img/xueshuxiaobian.jpg")
    },
    {
        title: "国际会议咨询",
        content: "关于参会及举办会议相关的业务咨询，请联系我们",
        email: "aaliserellie@gmail.com",
        image: require("@/assets/img/wangmengjuan.png")
    },
    {
        title: "期刊/出版社辅助征稿",
        content: "关于期刊的征稿合作咨询，请联系我们",
        email: "cassie.xin20@gmail.com",
        image: require("@/assets/img/genglaoshi.jpg")
    }
]

export const evaluateList = [
    {
        name: 'Hossein Moayedi',
        technical: 'Duy Tân University, Engineering Computer Science Chemistry Environmental Sciences & Ecology Materials Science.',
        content: "",
        // authorImage: require('@/assets/images/team1.png'),
    },
    {
        name: 'AL DIWANIYAH, IRAQ',
        technical: 'University of Al-QadisiyahSch Educ,Science & Technology - Other Topics.',
        content: "",
        // authorImage: require('@/assets/images/team2.png'),
    },
    {
        name: 'Dr. Abdulrahman S Bazaid',
        technical: 'Department of Medical Laboratory Science, College of Applied Medical Sciences, University of Ha’il, Ha’il.',
        content: "",
        // authorImage: require('@/assets/images/team3.png'),
    }
]

export const mediaList = [
    {
        image: require('@/assets/img/service.jpg'),
        icon: require('@/assets/img/bottom_warp/wx.png'),
        hicon: require('@/assets/img/bottom_warp/wx_h.png'),
        title: "IAMSET公众号",
        iconSrc: require('@/assets/img/bottom_warp/wx.png'),
    },
    {
        image: require('@/assets/img/qr_dy.jpg'),
        icon: require('@/assets/img/bottom_warp/dy.png'),
        hicon: require('@/assets/img/bottom_warp/dy_h.png'),
        title: "IAMSET抖音号",
        iconSrc: require('@/assets/img/bottom_warp/dy.png'),
    },
    {
        image: require('@/assets/img/qr_video.jpg'),
        icon: require('@/assets/img/bottom_warp/sph.png'),
        hicon: require('@/assets/img/bottom_warp/sph_h.png'),
        title: "IAMSET视频号",
        iconSrc: require('@/assets/img/bottom_warp/sph.png'),
    },
    {
        image: require('@/assets/img/bzhan.jpg'),
        icon: require('@/assets/img/bottom_warp/bz.png'),
        hicon: require('@/assets/img/bottom_warp/bz_h.png'),
        title: "IAMSET B站",
        iconSrc: require('@/assets/img/bottom_warp/bz.png'),
    },
    {
        image: require('@/assets/img/IAMSETbaijia.png'),
        icon: require('@/assets/img/bottom_warp/bjh.png'),
        hicon: require('@/assets/img/bottom_warp/bjh_h.png'),
        title: "IAMSET百家号",
        iconSrc: require('@/assets/img/bottom_warp/bjh.png'),
    },
    {
        image: require('@/assets/img/IAMSETsouhu.png'),
        icon: require('@/assets/img/bottom_warp/sh.png'),
        hicon: require('@/assets/img/bottom_warp/sh_h.png'),
        title: "IAMSET搜狐号",
        iconSrc: require('@/assets/img/bottom_warp/sh.png'),
    },
    {
        image: require('@/assets/img/IAMSETweibo.png'),
        icon: require('@/assets/img/bottom_warp/wb.png'),
        hicon: require('@/assets/img/bottom_warp/wb_h.png'),
        title: "IAMSET微博",
        iconSrc: require('@/assets/img/bottom_warp/wb.png'),
    },
    {
        image: require('@/assets/img/IAMSETzhihu.jpg'),
        icon: require('@/assets/img/bottom_warp/zh.png'),
        hicon: require('@/assets/img/bottom_warp/zh_h.png'),
        title: "IAMSET知乎",
        iconSrc: require('@/assets/img/bottom_warp/zh.png')
    },
    {
        image: require('@/assets/img/IAMSETtoutiao.png'),
        icon: require('@/assets/img/bottom_warp/jrtt.png'),
        hicon: require('@/assets/img/bottom_warp/jrtt_h.png'),
        title: "IAMSET头条号",
        iconSrc: require('@/assets/img/bottom_warp/jrtt.png'),
    }
]

export const sidebarList = [
    {
        icon: require('@/assets/img/bottom_warp/br_wx.png'),
        image: require("@/assets/img/xueshuxiaobian.jpg")
    },
    {
        icon: require('@/assets/img/bottom_warp/br_dy.png'),
        image: require('@/assets/img/qr_dy.jpg')
    },
    {
        icon: require('@/assets/img/bottom_warp/br_sph.png'),
        image: require('@/assets/img/qr_video.jpg')
    },
    {
        icon: require('@/assets/img/bottom_warp/br_wxgzh.png'),
        image: require('@/assets/img/service.jpg')
    }
]