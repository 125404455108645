<template>
    <div>
        <!-- 常见问题 -->
        <div class='box_question'>
            <serviceTitle :title="'常见问题'"></serviceTitle>
            <el-collapse v-model="activeName" accordion style="margin-top: 20px;">
                <el-collapse-item title="IAMSET（艾慕赛特）协会简介" name="1">
                    <template slot="title">
                        <i class="el-icon-minus title_icon" v-if="activeName == '1'"></i>
                        <i class="el-icon-plus title_icon" v-else></i>
                        IAMSET（艾慕赛特）协会简介
                    </template>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>为什么选择 IAMSET（艾慕赛特）的论文编辑与发表服务？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            IAMSET（艾慕赛特）协会拥有成熟的品牌化的管理团队，专业诚信的运营理念，精于耕耘专业前沿。在过去的多年里，成功达成上万人次学术交流，数百场次国际学术会议，积累若干优秀负责老师与发表经验，通过组织国际学术会议，上万篇论文在国际会议收录，优秀论文被遴选到SCIE、SSCI、EI或ISTP/CPCI检索收录的同行评议的期刊。同时，本协会秉持以作者为本的原则，文章不被检索可退还前期支付的各项服务费用。
                        </div>
                    </div>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>你们的官方联系方式是什么？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div style="line-height: 8px;">
                            <p>协会的官方联系方式如下：</p>
                            <p>官方网站：<el-link :underline='false' href="http://www.iamset.cn">www.iamset.cn（中文版）</el-link>
                            </p>
                            <p style="margin-left: 70px;">
                                <el-link :underline='false' href="http://www.iamset.org">www.iamset.org（英文版）</el-link>
                            </p>
                            <p style="display: flex;">学术服务小编微信：<img src="../../assets/img/xueshuxiaobian.jpg" alt=""
                                    style="width: 120px;">
                            </p>
                            <p>联系电话：0371-61319290 / 0371-63360580</p>
                            <p>咨询QQ：776553636
                            </p>
                        </div>
                    </div>

                </el-collapse-item>
                <el-collapse-item name="2">
                    <template slot="title">
                        <i class="el-icon-minus title_icon" v-if="activeName == '2'"></i>
                        <i class="el-icon-plus title_icon" v-else></i>
                        投稿与发表时间
                    </template>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>你们都有哪些合作期刊？如何提供服务？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            IAMSET（艾慕赛特）不承诺与任何国外多个期刊出版社建立的出版合作机会，不宣传与实际不符的承诺。所有与论文发表的主题：生物、医药、农业、环境、能源、机械、材料、计算机、电子信息、经管、社科、人文、空间科学、物理化学等多个研究领域。这些期刊包括SSCI
                            (Social Science Citation Index) 检索期刊、SCI (Science Citation Index) 检索期刊、EI (Ei
                            Compendex)
                            检索期刊，SCI或EI检索的会议论文集，以及普通英文期刊等。同时，协会也在不断开发拓展新的合作刊源。
                            您可以访问协会官网：<el-link :underline='false' href="http://www.iamset.cn">www.iamset.cn</el-link>
                            ，或者关注协会微信公众号：IAMSET艾慕赛特，获取官方最新动态。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>论文提交后大概需要多久可以发表出来？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            论文的发表周期，主要取决于合作期刊编辑部的工作安排。所有论文，必须经学者自己投稿，出版周期由出版社自行决定。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>提交的稿件是否需要排版，页数有什么要求？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            提交论文初稿的时候，只需要按照科研论文的一般格式排版，但要确保稿件各个部分无缺失，并请特别注意参考文献信息的完整。稿件页数请控制在5-8页，如目标期刊有特殊要求，请与您的责任编辑具体沟通。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>我要怎样投稿？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            协会支持在线投稿，投稿链接：<span style="cursor: pointer;"
                                @click="goToPage('/selective')">https://www.iamset.cn/selective</span>

                            <span style="color: #c00000;display: block;margin: 5px 0;">*
                                如果您在线投稿存在困难，可以扫码添加学术服务小编微信。<br>
                                <img src="../../assets/img/xueshuxiaobian.jpg" alt="" style="width: 120px;margin: 4px 0;"><br>
                                为了及时高效地和您沟通稿件进展，请您在投稿时，务必提供稿件联系人的电子邮箱及手机号码。</span>
                            
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>提交稿件后，需要多久可以得到反馈？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            在您提交稿件后的两个工作日内，我们会将稿件的收稿通知发送到您的预留邮箱，并同时为您分配相应的责任编辑。一般情况下，稿件初审将于投稿后的3-5个工作日完成。如果初审通过，将有责任编辑与您沟通语言编辑等重要问题。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>可以提交中文稿件吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            若英文稿件未完成，可以先将中文稿件提交协会审稿人进行初审，送交的稿件以英文终稿为准。
                        </div>
                    </div>


                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>论文投稿具体流程？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            文章准备好后可直接发给我们的编辑老师，协会这边初审发收稿通知→签订协议→安排送交→期刊部反馈，一般1~3个月出录用结果，顺利的话即可清样→上线→检索。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>投稿前需要注意什么？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            投稿前注意检查作者，单位，基金等重要信息是否提供完整，参考文献的格式是否统一，正文中的参考文献务必在文中标注清楚，切勿一稿多投。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>首次投稿有优惠吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            如果您是新作者，可以扫码关注我们的协会官方微信公众号（IAMSET艾慕赛特），投稿即可享受200元立减优惠；后续如您再次发表文章，也可享受老作者优惠。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>为什么不能告诉我期刊名称？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            期刊名称我们这边不太方便告知您，因为我们需要对期刊保密，以防出现大量的投稿造成检索不稳定或者同行攻击等任何问题，给作者也带来不便之处，还请您理解。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>为什么要提供审稿人这些，不是你们提供的吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            至于同行评审要分期刊，如果期刊这边要求我们提供同行评审的话，我们是需要作者自己来提供的；如果期刊不要求提供，则是期刊部内部专家评审。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>可文章发给你们后不会出现泄漏吧？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            IAMSET艾慕赛特协会从2010年创办至今，10来年处理的文章达上万篇，对作者投稿的文章保密是最基本的职业操守，这一点请您尽管放心。
                        </div>
                    </div>

                </el-collapse-item>
                <el-collapse-item name="3">
                    <template slot="title">
                        <i class="el-icon-minus title_icon" v-if="activeName == '3'"></i>
                        <i class="el-icon-plus title_icon" v-else></i>
                        发表与检索
                    </template>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>通过你们推荐的论文，一定会被期刊录用吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            根据对以往相关数据的统计，协会送交论文的最终录用率为82.7%。为降低拒稿几率，请您在准备稿件时特别注意以下要点:
                            <ul style="list-style: none;margin-left: -40px;">
                                <li>1）文表达要正确、清晰，并尽可能精确地道；</li>
                                <li>2）确保论文逻辑严谨，清晰易读；</li>
                                <li>3）写作中务必突出论文的动机，思路和成果；</li>
                                <li>4）注意文献来源的多样性，并充分反映当前科研动态。</li>
                            </ul>
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>投稿后可以撤稿么？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            若您的论文已经送交期刊编辑部进行终审，在此过程中，禁止撤稿。如果论文尚未送交，作者仍有撤稿的机会，但是需要承担相应的撤稿责任。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>你们是否能够确保论文成功推荐后一定会被检索？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            协会所有合作推荐发表的期刊都是目前的在检期刊，但因为SCI期刊目录每年更新，EI目录更是动态更新，因此难免存在个别期刊在某次目录更新后不再被原检索机构收录的情况。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>论文检索周期是多久？我该如何查询检索情况？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            检索周期一般为论文正式上线后的三到六个月。论文检索后，协会将会及时通知您。届时你可登陆Web of Science或者Ei Compendex进行确认。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>如果我的论文成功推荐后没有被检索，我已经缴纳的费用会被退还吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            如果您在检索前缴纳了全款或者订金，那么当不检索的情况发生时，我们将退还您已缴纳的全部费用；如果您选择的是检索后缴纳全款，您无需缴纳任何费用。
                        </div>
                    </div>



                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>专刊和正刊有什么区别呢？有正规的期卷页码吗？我应该发什么刊？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            专刊和正刊没有特别大的区别，专刊是Special
                            Issue，一般都是有卷期页码的，或者有期页码，具体主要分期刊；另外有的专刊是占正刊版面的专刊，这个和正刊是一样的，检索不带SI字样；有些不占正刊版面，就是带SI，您可以根据自身需求进行选择。如果您的单位没有要求，只要SCI检索就可以的话，发正刊专刊都是可以的。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>我的文章平时发中科院四区的，你们能不能帮我发中科院一区的？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            中科院一区的期刊在文章领域和质量把控上都是比较严格的，如果您的文章完稿的话，可以先发过来我们初审一下是否符合送交标准。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>多长时间能录用/上线/检索？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            不同期刊的进度都会有一定的差别，具体的发表周期根据期刊而定，详情请咨询与您对接的编辑老师。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>文章拒稿了怎么办？能百分之百录用吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            我们的平均录用率一般都在75%-80%左右，如果文章被拒稿，我们会第一时间跟您沟通是否继续处理，如果您确定不再由我们继续处理您的文章，我们会安排退还您已缴纳的订金，送交后的文章都需要经过期刊部的最终审核，所以我们不能百分之百保证录用。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>你们不是和期刊部合作吗，为什么还不能完全保证录用？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            我们虽然与期刊部有合作关系，但也不是任何质量的文章送交后期刊部都可以接收，对文章质量的严格把控也是期刊能够长久稳定运营的关键因素。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>期刊部反馈修改意见后，你们包改吗？你们收的费用这么高，作用体现在哪？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            文章内容上我们不做任何修改，我们收到返修意见后会第一时间反馈给您修改，毕竟文章的情况您自己才是最清楚明白的。<br>
                            我们的费用包含论文推荐服务费、版面费、排版费、查重费、纸质期刊费用等，我们和期刊部一直保持长期稳定的合作关系，论文直接推荐到期刊部终审，审稿周期短，录用率高达80%。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>文章检索后再付费可以吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            协会跟合作的期刊只要论文一录用，我们就需要向期刊部支付费用，所以有的作者选择“协议后一半，检索后一半”这种方式付款；协会是需要先垫付这个费用的，之前是有“检索后付款”的方式，但有时候我们需要贷款去进行正常运转，现在协会合作的期刊特别多，为了保证作者可以自由选择，渠道能够正常运行，才采用的这两种付款方式。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>文章没检索的话怎么办？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            我们都是保证检索的，如果文章没有检索，前期已缴纳的费用都是可以全部退还的，这一点在我们双方签订的发表协议中也都是有体现的，请您放心。
                        </div>
                    </div>

                </el-collapse-item>
                <el-collapse-item title="" name="4">
                    <template slot="title">
                        <i class="el-icon-minus title_icon" v-if="activeName == '4'"></i>
                        <i class="el-icon-plus title_icon" v-else></i>
                        语言编辑服务
                    </template>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>你们提供语言编辑服务吗?</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            IAMSET提供中文翻译及英文润色的编辑服务。国际期刊对论文的英语表述有较为严格的要求，对于非英语国家的作者，语言问题往往是投稿失败的原因之一。我们建议英文写作欠佳的作者，稿件完成后，选择我们提供的语言编辑，文稿进行翻译或润色。
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="" name="5">
                    <template slot="title">
                        <i class="el-icon-minus title_icon" v-if="activeName == '5'"></i>
                        <i class="el-icon-plus title_icon" v-else></i>
                        国际学术会议
                    </template>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>你们都有哪些会议？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            请访问 <span @click="goToPage('/meeting')"
                                style="cursor: pointer;">https://www.iamset.cn/meeting</span>
                            查看IAMSET组织的所有国际学术会议。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>想参加你们的会议，应该怎样注册？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            您可以进入您意向参加的会议的官网，按照注册向导完成注册。如果您是IAMSET的会员作者，对协会主办的会议您都可以免费参加。
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="费用，优惠及发票问题" name="6">
                    <template slot="title">
                        <i class="el-icon-minus title_icon" v-if="activeName == '6'"></i>
                        <i class="el-icon-plus title_icon" v-else></i>
                        费用，优惠及发票问题
                    </template>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>你们都提供哪些缴费方式？费用大概是多少？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            协会提供以下缴费方式：
                            <ul style="list-style: none;margin-left: -40px;">
                                <li>1）签订协议后的5个工作日内，缴纳全款；</li>
                                <li>2）签订协议后的5个工作日内，缴纳订金；通知论文检索后的5个工作日内，缴纳尾款；</li>
                                <li>3）签订协议后的5个工作日内，缴纳订金；论文录用后，版面费用可自行付款到出版社；论文检索后，缴纳剩余服务费用。</li>
                            </ul>
                            发表费用包括论文的编校费、服务费以及纸质期刊等费用，具体金额请届时咨询。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>会员作者可以享受推荐发表优惠吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            协会自2010年成立以来，已累积拥有数千位会员作者。为感谢广大会员对IAMSET一直以来的信任和支持，所有会员作者在投稿时，凭以往已发表论文的编号即可享受推荐发表费用的会员折扣或优惠。同时，协会还提供其他特殊优惠，老作者推荐新作者成功检索一篇SCI或SSCI,给予1000元推荐费；老作者推荐新作者成功检索一篇EI，给予500元推荐费。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>可以向协会推荐其他论文吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            首先协会非常感谢您推荐优秀稿件。为鼓励会员或普通作者荐稿，荐稿人在所推荐的稿件完成检索后，其将会得到一定金额的荐稿奖励。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>你们开具的发票是什么类型的？发票内容是什么？怎样申请？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            我们提供的是国内增值税普通发票，税率为6%。发票可开具内容为会议费、翻译服务费、润色服务费、数据处理费、技术咨询费、编辑服务费等。收到转账或汇款后，您的责任编辑将与您沟通发票开具的具体事宜。
                        </div>
                    </div>


                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>怎么收费？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            收费标准是根据最终给您推荐的期刊而定的，期刊的影响因子、分区、发表形式都不一样，价格也是不一样的；您文章完稿的话，可以先发过来，协会初审后给您推荐对应的期刊，再跟您沟通价格，具体价格请咨询与您对接的编辑老师。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>费用是包含所有的费用吗？后续还有别的费用吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            跟您所说的费用都是包含所有费用的，如果您后续要开具国内发票，只需缴纳所开发票金额对应的税费即可，没有其他任何额外收费项目。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>可以用公务卡吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            可以的，如果您支付宝已绑定了公务卡，直接转账至协会官方指定的支付宝账户就可以；如果没有绑定的话，我们也可以把刷卡码发给您进行扫码支付。
                        </div>
                    </div>
                    <!-- <el-divider></el-divider> --><br>
                    <div class="q">
                        <div style="margin-right: 20px;">Q:</div>
                        <div>发票开具内容都包含哪些？可以报销吗？</div>
                    </div>
                    <div class="a">
                        <div style="margin-right: 20px;">A:</div>
                        <div>
                            我们可以开国内普通增值税发票，也可以开国际发票；报销的时候您可以先确定一下您所在单位的报销流程和所需材料，我们都可以提供给您，报销是没有问题的。
                            发票开具内容具体包含：<br>
                            ① 会议服务费、会务费、会议费<br>
                            ② 技术咨询费<br>
                            ③ 技术服务费<br>
                            ④ 技术开发费<br>
                            ⑤ 翻译服务费、翻译费<br>
                            ⑥ 编辑服务费<br>
                            ⑦ 数据处理费
                        </div>
                    </div>

                </el-collapse-item>
            </el-collapse>
        </div>
        <div style="margin-bottom: 30px;">
            <p style="color: #b70000;font-size: 14px;display: flex;align-items: center;margin-top: 30px;">
                <img src="../../assets/img/ico_notice.png" alt="" style="margin-right: 10px;">
                <span>郑重声明：本站所发布信息来源于网络，仅供科研服务、学习与研究使用，版权与著作权归原作者所有。</span>
            </p>
        </div>
    </div>
</template>

<script>
import serviceTitle from "@/components/common/serviceTitle.vue";

export default {
    components: {
        serviceTitle
    },
    data() {
        return {
            activeName: '0'
        }
    },
    methods: {
        goToPage(page) {
            this.$router.push({ path: page })

            window.scroll({
                top: top,
                left: 0,
            });
        }
    }
}
</script>

<style scoped lang='scss'>
::v-deep .box_question .el-collapse-item__wrap {
    background-color: transparent;
}

::v-deep .el-collapse-item__header {
    color: #252B3A;
    font-size: 16px;
    height: 40px;
    padding: 10px 20px;
    background-color: transparent;

    .is-active {
        color: #18305b;
    }
}

::v-deep .el-collapse-item__header .is-active {
    color: #18305b;
}

.q {
    padding: 0 20px;
    color: #303133;
    font-size: 14px;
    // font-weight: bold;
    display: flex;
}

.a {
    padding: 0 20px;
    color: #7d7d7d;
    font-size: 14px;
    display: flex;
}
</style>