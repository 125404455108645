<template>
    <basic-container>
        <bannerVue :item="2"></bannerVue>
        <service-menus></service-menus>
        <div class="sele_box">
            <div class="main">
                <serviceContent>
                    <template #content>
                        <div class="content_1">
                            <div class="content_title">业务简介</div>
                            <div>
                                <div class="content sel_desc">
                                    IAMSET提供专业的选刊服务，会根据您的文章内容,研究领域以及您的要求选择合适的期刊。
                                </div>
                                <div class="content sel_desc">
                                    关于选刊服务，IAMSET学术服务编辑会帮助您查找选择更合适的期刊；论文领域与期刊更匹配；更符合期刊要求的文章格式；<br>
                                    提高语言质量，让科技论文语言表达更专业；突出文章创新点，让文章价值更凸显。
                                </div>
                            </div>
                        </div>
                        <div class="content_2">
                            <div class="content_title">
                                推荐期刊的排名如何？
                            </div>
                            <div class="content sel_desc">
                                推荐的期刊按相关性顺序进行排名，使用评分系统与您输入的文本相匹配。
                            </div>
                        </div>
                    </template>
                </serviceContent>
            </div>

            <div style="margin: 0 auto;" class="sele_form_box">
                <div style="width: 960px;margin: 10px auto;">
                    <div>
                        <p class="sel_title form_title">在线投稿</p>
                    </div>
                    <div class="loading ">
                        <el-form :model="releaseForm" :inline="true" :rules="rules" ref="releaseForm"
                            class='form-horizontal' id='frm_1' method=POST label-width="120px"
                            action='https://crm.xtcrm.com/uin/submit.xt' target=xtsub>
                            <div class="form_boxs">
                                <el-form-item label="作者姓名" prop="authorName" class="form_item">
                                    <el-input v-model="releaseForm.authorName" autocomplete="off" type="edit"
                                        class="form-box-input" name="L1"></el-input>
                                </el-form-item>
                                <el-form-item label="投稿邮箱" prop="eMail" class="form_item">
                                    <el-input v-model="releaseForm.eMail" autocomplete="off" type="edit"
                                        class="form-box-input" name="L4"></el-input>
                                </el-form-item>
                                <el-form-item label="联系电话" prop="phone" class="form_item">
                                    <el-input v-model="releaseForm.phone" autocomplete="off" type="text"
                                        class="form-box-input" name="L2"></el-input>
                                </el-form-item>
                                <el-form-item label="单位名称" prop="unitName" class="form_item">
                                    <el-input v-model="releaseForm.unitName" autocomplete="off" type="edit"
                                        class="form-box-input" name="L6"></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item label="投稿期刊" prop="periodical" class="form_width">
                                <el-input v-model="releaseForm.periodical" autocomplete="off" class="form-box-input"
                                    type="edit" name="L3"></el-input>
                            </el-form-item>
                            <el-form-item label="研究领域" prop="research" class="form_item form_select">
                                <el-select v-model="releaseForm.research" placeholder="研究领域" name='L8'
                                    class='select_box'>
                                    <el-option label="环境" value="环境"></el-option>
                                    <el-option label="工业工程" value="工业工程"></el-option>
                                    <el-option label="机械" value="机械"></el-option>
                                    <el-option label="心理学" value="心理学"></el-option>
                                    <el-option label="海洋工程" value="海洋工程"></el-option>
                                    <el-option label="生物医学" value="生物医学"></el-option>
                                    <el-option label="高被引" value="高被引"></el-option>
                                    <el-option label="管理科学" value="管理科学"></el-option>
                                    <el-option label="数学" value="数学"></el-option>
                                    <el-option label="其他" value="其他"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="论文标题" prop="title" class="form_width">
                                <el-input v-model="releaseForm.title" autocomplete="off" class="form-box-input"
                                    type="edit" name="L5"></el-input>
                            </el-form-item>
                            <el-form-item label="所有作者姓名" prop="allName" class="form_width">
                                <el-input v-model="releaseForm.allName" autocomplete="off" class="form-box-input"
                                    type="edit" name="L7"></el-input>
                            </el-form-item>
                            <el-form-item label="所有作者邮箱" prop="allEmail" class="form_width">
                                <el-input v-model="releaseForm.allEmail" autocomplete="off" class="form-box-input"
                                    type="edit"></el-input>
                            </el-form-item>

                            <div class="tips_boxs">
                                <el-form-item class="form_item">
                                    <el-upload class="upload-demo" ref="upload" action="/joi/upload/file"
                                        :on-remove="handleRemove" :on-success="onSuccess" multiple :limit="1"
                                        :on-exceed="handleExceed" :file-list="fileList">
                                        <el-button size="medium" class="form_box_btn">上传论文</el-button>
                                        <template #tip>
                                            <div class="el-upload__tip">
                                                注：文件格式仅限于.doc, .docx
                                            </div>
                                        </template>
                                    </el-upload>
                                </el-form-item>
                            </div>
                            <p class="text-center">
                                <input class="form-box-btn" style="display: none;" ref="submitButtonInput" type="submit"
                                    value="  提交  " name="submit" />
                            </p>
                            <el-form-item class="submit_btn">
                                <el-button type="primary" @click="onSubmit('releaseForm', 'open')"
                                    style="width: 150px; background-color: #18305B;">提交</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div style="margin-left: 120px;">
                        <p class="desc_tips_title_">
                            <img src="../../assets/img/tips.gif" alt="">
                            注意事项
                        </p>
                        <div class="desc_tips_">
                            <p>1. 提交成功后如需更改信息，请联系学术服务小编</p>
                            <p>2. 为便于享受优惠折扣，IAMSET会员请提供已发表论文编号；被推荐作者请提供推荐人姓名及论文编号</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </basic-container>
</template>
<script>
import serviceMenus from "@/components/common/serviceMenus"
import serviceTitle from "@/components/common/serviceTitle.vue";
import serviceContent from "@/components/common/serviceContent.vue";
import bannerVue from "./banner.vue"
import {
    translateUp
} from '@/api/service/translate'
import { isValidateEmail, isValidatePhoneNumber } from "@/utils/tools"

export default {
    components: {
        'service-menus': serviceMenus,
        serviceTitle,
        serviceContent,
        bannerVue
    },
    data() {
        //校验邮箱
        const validateEmaill = (rule, value, callback) => {
            const validationResult = isValidateEmail(value); // 使用 validateContactEmail 函数验证邮箱
            if (!validationResult) {
                callback('邮箱格式不正确'); // 如果验证结果不为空，说明验证失败，返回错误信息
            } else {
                callback(); // 验证通过，不返回错误信息
            }
        };
        //校验手机号
        const validatePhone = (rule, value, callback) => {
            const validationResult = isValidatePhoneNumber(value); // 使用 validateContactEmail 函数验证邮箱
            if (!validationResult) {
                callback('手机号格式不正确'); // 如果验证结果不为空，说明验证失败，返回错误信息
            } else {
                callback(); // 验证通过，不返回错误信息
            }
        };
        return {
            activeName: "first",
            fileList: [],
            releaseForm: {
                authorName: "",
                unitName: "",
                eMail: "",
                phone: "",
                periodical: "",
                research: "",
                title: "",
                allName: "",
                allEmail: "",
                spareFieldO: "",
                spareFieldT: "tg",
                spareFieldS: "http://www.iamset.cn/"
            },
            rules: {
                authorName: [{
                    required: true,
                    trigger: "change",
                    message: "请输入作者姓名"
                },],
                unitName: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入单位名称"
                },],
                eMail: [{
                    required: true,
                    trigger: "blur",
                    validator: validateEmaill
                },],
                phone: [{
                    required: true,
                    trigger: "blur",
                    validator: validatePhone
                },],

                research: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入研究领域"
                },],
                title: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入论文标题"
                },],
                allName: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入所有作者姓名"
                }],
                allEmail: [{
                    required: true,
                    trigger: "blur",
                    validator: validateEmaill
                }],
            },
        };
    },
    methods: {
        handleRemove(file, fileList) {
            this.fileList = fileList;
            this.releaseForm.spareFieldO = null
        },
        onSuccess(esponse, file, fileList) {
            this.fileList = fileList;
            this.releaseForm.spareFieldO = this.fileList[0].response.data
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        onSubmit(releaseForm) {
            if (!this.$store.getters.getUserInfo) {
                this.$router.push({ path: '/login' });
                return
            }

            this.$refs[releaseForm].validate((valid) => {
                if (valid) {
                    this.$refs.submitButtonInput.click();
                    let _this = this;
                    translateUp("addOnlineSubmission", this.releaseForm, " form_box")
                        .then(function (res) {
                            console.log(res)
                            if (res.code == 200) {
                                _this.$message.success({
                                    showClose: true,
                                    duration: 1500,
                                    message: res.msg,
                                    type: "success",
                                    onClose: function () {
                                        _this.$refs[releaseForm].resetFields();
                                        _this.$refs['upload'].clearFiles();
                                    },
                                });
                            }

                        })
                        .catch(function () { });
                } else {
                    //   this.$message.error("请检查必填项！");
                    this.$notify.error({
                        title: "错误",
                        message: "请检查必填项！",
                    });
                    return false;
                }
            });
        },
        resetForm(releaseForm) {
            this.$refs[releaseForm].resetFields();
        },
        getCli() {
            this.releaseForm.periodical = this.$route.query.journalTitle
        }
    },
    mounted() {
        this.releaseForm.periodical = this.$route.query.journalTitle
    }
}

</script>

<style lang="scss" scoped>
.sele_form_box {
    background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(../../assets/img/selective_form_bj.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 0;
}

.sele_box {
    min-width: 1200px;
}

.swiper_box {
    min-width: 1200px;
}

.desc_tips_ {
    line-height: 1.2;
    color: rgb(51, 51, 51);
    font-size: 14px;
}

.desc_tips_title_ {
    color: #b70000;
    margin: 16px 0;
}

.sel_title,
.form_title {
    color: #333333;
    text-align: center;
    font-size: 28px;
    margin: 50px 0;
    font-weight: 600;
    margin-top: 0;
}

.form_title {
    font-size: 20px;
    font-weight: 400;
}

.sel_desc {
    width: 75%;
    margin: 0 auto;
    color: #595959;
    font-size: 14px;
    text-align: center;
    padding: 0;
    line-height: 1.8;
}

.main {
    min-width: 1000px;
    width: 1200px;
    box-sizing: border-box;
    padding: 20px;
    padding-top: 0;
    padding-bottom: 40px;
    margin: 0px auto;
    max-width: 1600px;
    background-color: #fff;

    .box_title {
        color: #333333;
        text-align: center;
        font-size: 28px;
        // padding: 20px 0;
        margin: 50px 0;
        margin-top: 20px;
        font-weight: 600;
    }

    .title {
        font-weight: bold;
    }

    .color {
        color: #706b69;
    }

    .content_box {

        .content_1,
        .content_2 {
            margin-top: 40px;
        }
    }
}

.num {
    text-align: center;
    margin-right: 15px;
    color: white;
    width: 22px;
    height: 22px;
    background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%),
        linear-gradient(#d40016, #d40016);
    background-blend-mode: normal, normal;
}

::v-deep .form_boxs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .form_item {
        width: calc(calc(100% - 50px) /2);
    }
}

.form_box_btn {
    background-color: white;
    color: #666666;

    &:hover {
        background-color: #18305b;
        color: white;
    }
}

::v-deep .form_select {
    width: 100%;

    .el-form-item__content {
        width: 60%;

        .el-select {
            width: 52%;
        }
    }
}

.form-horizontal {
    width: 100%;

    .form_width {
        width: 100%;
    }

    .submit_btn {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        ::v-deep .el-form-item__content {
            margin-right: 80px
        }
    }

    .tips_boxs {
        margin-left: 120px;
    }
}

::v-deep .form_width .el-form-item__content {
    width: calc(100% - 190px);
}

::v-deep .form_boxs .el-form-item__content {
    width: calc(100% - 150px);
}
</style>