<template>
    <basic-container>
        <bannerVue :item="0"></bannerVue>

        <service-menus :menusIndex="menusIndex"></service-menus>
        <div class="tra_box">

            <div class="main_translate">
                <div class="rs_box">
                    <div class="outer_box">
                        <div class="title">
                            <div class="title_left">论文翻译润色</div>
                            <div class="title_right">自动报价</div>
                        </div>
                        <div class="tips">请输入您的论文字数，系统自动为您报价</div>
                        <div class="select_box">
                            <div class="member">
                                <el-form ref="form" :inline="true" :model="form" label-width="auto">
                                    <el-form-item label="字数:" prop="number">
                                        <el-input v-model="form.words" placeholder="请输入论文字数" @input="changes"
                                            oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                                    </el-form-item>
                                    <el-form-item label="会员:" style="margin-left: 14px;">
                                        <el-radio v-model="form.member" label="是" class="radio-member">是</el-radio>
                                        <el-radio v-model="form.member" label="否" class="radio-member">否</el-radio>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="dashed"></div> -->
                <div class="business_box_translate">
                    <div class="classify">
                        <el-card class="box-card classify_item" shadow="hover">
                            <div  class="ct_1">
                                <div class="business_herder">
                                    <!-- <el-image src="require('@/assets/img/translation_1.png')"></el-image> -->
                                    <img src="@/assets/img/tr_1.png" alt="" style="width: 36px;height: 36px;">
                                    <div class="carda">
                                        <el-tooltip placement="top">
                                            <div slot="content">
                                                匹配同学科资深译者，必要时与作者沟通确认表达；<br />
                                                责任编辑审校译文，检查漏译、修订翻译失误；<br />
                                                除语言表达和术语准确性检查外，附加语言，逻辑和语句结构润色；<br />
                                                如果作者对某处翻译觉得有妥之处，12月内免费返修一次 <br />
                                            </div>
                                            <div class="card_min">
                                                中译英论文翻译<i class="el-icon-question" style="color: black;"></i>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="business_info">
                                    <div style="color: #333333">
                                        <div class="price_box">
                                            <div class="business_info_title vip">
                                                <p><span>会员</span><span>
                                                        270￥</span><span>/千字</span></p>
                                            </div>
                                            <div class="business_info_title novip">
                                                <p><span>非会员</span><span>
                                                        300￥</span><span>/千字</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="money_title">
                                        <span style="font-size: 20px">￥</span>
                                        <span
                                            style="font-weight: bold;color:#cf9236;font-size: 55px; overflow-wrap: break-word;">{{
                                                money }}</span>
                                    </div>
                                    <div class="content">
                                        <ul>
                                            <li>翻译中文稿件</li>
                                            <li>相近领域留学博士团队翻译</li>
                                            <li>责任编辑审校译文</li>
                                            <li>优质售后服务</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="btn_box">
                                    <el-button type="danger" style="background: #cf9236; border-color: #cf9236;"
                                        class="btn" @click="uploadFile()">
                                        立即上传文稿<i class="el-icon-upload el-icon-right"></i></el-button>
                                </div>
                            </div>
                        </el-card>
                        <el-card class="box-card classify_item classify_two" shadow="hover">
                            <div class="ct_1">
                                <div class="business_herder">
                                    <img src="@/assets/img/translation_1.png" alt="">
                                    <div class="carda">
                                        <el-tooltip placement="top">
                                            <div slot="content">
                                                针对有意问投稿高影响因子期刊的科研作者设计的学术翻译。<br />
                                                经学科专家翻译、双语校对、两名母语编辑润色后的完稿内涵信实、行文优雅、表述畅达，<br />
                                                直达国际期刊接收标准，读起来就如以英文原作一般，<br />
                                                是论文投稿期刊的适合选择 <br />
                                            </div>
                                            <div class="card_min">
                                                中译英论文翻译+润色<i class="el-icon-question" style="color: black;"></i>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="business_info">
                                    <div style="color: #333333;">
                                        <div class="price_box">
                                            <div class="business_info_title vip">
                                                <p><span>会员</span><span>
                                                        650￥</span><span>/千字</span></p>
                                            </div>
                                            <div class="business_info_title novip">
                                                <p><span>非会员</span><span>
                                                        800￥</span><span>/千字</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="money_title">
                                        <span style="font-size: 20px;">￥</span>
                                        <span
                                            style="font-weight: bold;color:#cf9236;font-size: 55px; overflow-wrap: break-word;">{{
                                                money2 }}</span>
                                    </div>
                                    <div class="content">
                                        <ul>
                                            <li>翻译中文稿件</li>
                                            <li>相近领域留学博士团队翻译</li>
                                            <li>欧美母语编辑校对润色</li>
                                            <li>责任编辑审核译文</li>
                                            <li>双编辑机制，确保稿件质量</li>
                                            <li>直接可投SCI期刊和EI期刊</li>
                                            <li>优质售后服务</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="btn_box">
                                    <el-button type="danger" style="background: #cf9236; border-color: #cf9236;"
                                        class="btn" @click="uploadFile()">
                                        立即上传文稿<i class="el-icon-upload el-icon-right"></i></el-button>
                                </div>
                            </div>
                        </el-card>
                    </div>

                </div>

                <div class="business_box_translate">
                    <serviceTitle :title="'服务流程'"></serviceTitle>
                    <serverFlow></serverFlow>
                </div>
                <div class="business_box_translate">
                    <serviceTitle :title="'售后服务'"></serviceTitle>
                    <div class="sh_table">
                        <el-table :data="tableData2" border style="width: 100%" :header-cell-style="headClass">
                            <el-table-column prop="name" label="业务名称" />
                            <el-table-column prop="name2" label="无偿返修条件" />
                        </el-table>
                    </div>
                </div>

                <keepSecret></keepSecret>
            </div>
        </div>

        <el-dialog title="立即上传文稿" :visible.sync="dialogFormVisible" class="loading">
            <div class="journalDetails2-1">
                <p style="padding: 10px">
                    <span>适合中文撰写的本硕博论文或学术论文，留学申请材料及文献资料</span>
                </p>
            </div>
            <div class="journalDetails2-2 loading1">
                <el-form :model="releaseForm" :rules="rules" ref="releaseForm">
                    <el-form-item label="论文标题" label-width="100px" required prop="title">
                        <el-input v-model="releaseForm.title" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" label-width="100px" required prop="authorName">
                        <el-input v-model="releaseForm.authorName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" label-width="100px" required prop="phone">
                        <el-input v-model="releaseForm.phone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="联系邮箱" label-width="100px" required prop="eMail">
                        <el-input v-model="releaseForm.eMail" autocomplete="off"></el-input>
                    </el-form-item>
                    <br />
                    <el-form-item style="width: 60%">
                        <el-upload class="upload-demo" ref="upload" action="/joi/upload/file" :on-remove="handleRemove"
                            :on-success="onSuccess" multiple :limit="1" :on-exceed="handleExceed" :file-list="fileList">
                            <el-button size="medium" type="info">上传附件</el-button>
                            <template #tip>
                                <div class="el-upload__tip" style="color: red">
                                    注：文档支持.zip .rar .doc .docx .txt .pdf等格式
                                </div>
                            </template>
                        </el-upload>
                    </el-form-item>
                    <el-form-item style="text-align: center; width: 100%;">
                        <el-button type="primary" @click="onSubmit('releaseForm')"
                            style="background-color: #1b5faa; width: 282px">立即提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

    </basic-container>
</template>
<script>
import { bannerList } from "./data"
import serviceMenus from "@/components/common/serviceMenus"
import keepSecret from "@/components/common/keepSecret.vue";
import serverFlow from "@/components/common/serverFlow.vue";
import serviceTitle from "@/components/common/serviceTitle.vue";
import bannerVue from "./banner.vue"
import {
    translateUp
} from '@/api/service/translate'
export default {
    components: {
        'service-menus': serviceMenus,
        keepSecret,
        serverFlow,
        serviceTitle,
        bannerVue
    },
    data() {
        return {
            bannerList,
            dialogFormVisible: false,
            fileList: [],
            menusIndex: 0,
            form: {
                words: "",
                member: "是",
                currency: "CNY"
            },
            tableData: [{
                name: "论文翻译（中译英）",
                vip0: "300",
                vip1: "270",
            },
            {
                name: "论文翻译+英文润色",
                vip0: "800",
                vip1: "650",
            },
            ],
            tableData2: [{
                name: "中文论文翻译",
                name2: "如果作者对某处翻译觉得有不妥之处，12个月内免费返修一次"
            },
            {
                name: "论文翻译+英文润色",
                name2: "稿件润色后国际期刊仍要求改进英文语言方面，未经修改的稿件，12月内无偿返修，修改部分超过20%，修改部分按照原价7折收取费用。"
            },
            ],
            headClass: {
                background: '#f1f1f1',
                color: '#252525',
            },
            releaseForm: {
                authorName: "",
                eMail: "",
                phone: "",
                title: "",
                spareFieldO: "",
                spareFieldT: "fy"
            },

            rules: {
                authorName: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入联系人"
                },],

                eMail: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入联系人邮箱"
                },],
                phone: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入联系电话"
                },],
                title: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入论文标题"
                },],

            },

        };
    },
    computed: {
        money() {
            return this.form.words === "" ?
                (this.form.member === "是" ? this.tableData[0].vip1 : this.tableData[0].vip0) :
                (this.form.member === "是" && this.form.currency === "CNY" ?
                    (this.form.words * this.tableData[0].vip1 / 1000).toFixed(2) :
                    (this.form.words * this.tableData[0].vip0 / 1000).toFixed(2));
        },
        money2() {
            return this.form.words === "" ?
                (this.form.member === "是" ? this.tableData[1].vip1 : this.tableData[1].vip0) :
                (this.form.member === "是" && this.form.currency === "CNY" ?
                    (this.form.words * this.tableData[1].vip1 / 1000).toFixed(2) :
                    (this.form.words * this.tableData[1].vip0 / 1000).toFixed(2));
        },
    },
    methods: {
        changes(value) {
            if (value > 999999999999) {
                this.form.words = 999999999999;
            }
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        handleRemove(file, fileList) {
            this.fileList = fileList;
            this.releaseForm.spareFieldO = null
        },
        onSuccess(esponse, file, fileList) {
            this.fileList = fileList;
            this.releaseForm.spareFieldO = this.fileList[0].response.data
        },

        uploadFile() {
            if (!this.$store.getters.getUserInfo) {
                this.$router.push({ path: '/login' });
                return
            }
            this.dialogFormVisible = true
            console.log(this.dialogFormVisible);
        },
        onSubmit(releaseForm) {
            if (!this.$store.getters.getUserInfo) {
                this.$router.push({ path: '/login' });
                return
            }
            this.$refs[releaseForm].validate((valid) => {
                if (valid) {
                    let _this = this;
                    translateUp("addOnlineSubmission", this.releaseForm, "loading")
                        .then(function (res) {
                            console.log(res)
                            if (res.code == 200) {
                                _this.$message.success({
                                    showClose: true,
                                    duration: 1500,
                                    message: res.msg,
                                    type: "success",
                                    onClose: function () {
                                        _this.dialogFormVisible = false
                                        _this.$refs[releaseForm].resetFields();
                                        _this.$refs['upload'].clearFiles();
                                    },
                                });
                            }
                        })
                        .catch(function () { });
                } else {
                    //   this.$message.error("请检查必填项！");
                    this.$notify.error({
                        title: "错误",
                        message: "请检查必填项！",
                    });
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="scss">
.tra_box {
    background-color: white;
    padding-bottom: 10px;
    min-width: 1200px;
}

.main_translate {
    min-width: 1000px;
    width: 1200px;
    box-sizing: border-box;
    padding: 20px;
    margin: 0px auto;
    max-width: 1600px;
    background-color: #fff;

    .rs_box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .outer_box {
            width: 775px;
        }

        .tips {
            color: #7d7d7d;
            font-size: 13px;
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }

    // box-sizing: border-box;
    .title {
        display: flex;
        font-weight: bold;

        .title_left {
            font-size: 24px;
        }

        .title_right {
            transform: translateY(-5px);
            margin-left: 10px;
            padding: 6px 14px;
            background-color: #bf7c16;
            font-size: 14px;
            color: #fff;
            border-radius: 17px 0 17px 0;
        }
    }

    .select_box {
        margin: 0 auto;
        // margin-left: 400px;
        margin-top: 20px;

        .el-form-item__content {
            .is-checked {
                border-color: #282828;

                .el-radio__inner {
                    border-color: #282828;
                    background: #282828;

                    &::after {
                        width: 7px;
                        height: 7px;
                    }
                }
            }

            .el-radio__input.is-checked+.el-radio__label {
                color: #282828;
            }
        }
    }

    .dashed {
        border-style: dashed;
        border-width: 1px;
        border-color: #C4C4C4;
        // margin: 0 200px;
    }
}

.bm_xy {
    text-indent: 35px;
    margin-top: 0px;
    font-size: 14px;
    color: rgb(96, 98, 102);
}

.business_box_translate {
    margin: 20px 0;

    .service_title_ {
        font-size: 28px;
    }

    .steps_box {
        display: flex;
        justify-content: center;

        .step_item {
            margin: 0 40px;
        }
    }

    .classify {
        display: flex;
        justify-content: center;

        .classify_item {
            padding: 20px;
            padding-bottom: 0;
            border: 1px solid rgb(241 241 241);
            box-sizing: border-box;

            .el-card__body{
                height: 100%;
                box-sizing: border-box;

                .ct_1{
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
            }
        }

        .classify_two {
            margin-left: 70px;
        }

        .business_herder {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            margin-bottom: 30px;
            font-size: 20px;

            img {
                margin-right: 0px;
            }

        }

        .card_min {
            height: 33px;
            font-weight: bold;
            color: #18305b;
            text-align: center;
            display: flex;
            align-items: center;
            border: none;
            margin-left: 20px;
        }

        .carda {
            height: 30px;

            .el-card__body {
                padding-right: 0;
            }
        }
    }

    .business_info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .content {
            color: #606060;

            ul {
                padding: 0;
                margin: 0;
                font-size: 15px;
                line-height: 1.8;
            }
        }

        .money_title {
            margin: 30px 0;
        }

        .price_box {
            color: white;
            display: flex;
            align-items: center;
            border-radius: 3px;

            box-sizing: border-box;

            .business_info_title {
                padding: 10px;
                font-size: 14px;

                p {
                    margin: 0;
                }
            }

            .vip {
                border-radius: 3px 0 0 3px;
                background-color: #cf9236;
                border: 1px solid transparent;
                border-right: none;
            }

            .novip {
                border-radius: 0 3px 3px 0;
                border: 1px solid #e5e5e5;
                color: black;
            }
        }
    }

    .btn_box {
        text-align: center;
        margin: 20px;
        margin-top: auto;
        padding-top: 40px;

        .btn {
            width: 300px;
            height: 60px;
            font-size: 16px;
            transition: all .3s;

            &:hover {
                opacity: 0.8;
            }

            i {
                margin-left: 20px;
            }
        }
    }
}
</style>