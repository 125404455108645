<template>
    <basic-container>
        <div class="privacy_page">
            <div class="privacy_page_title">隐私政策</div>
            <div>
                欢迎使用IAMSET（以下简称“本网站”）。通过访问和使用本网站，您（即“用户”）同意遵守以下服务协议（以下简称“本协议”）。如果您不同意这些条款，请勿使用本网站。
            </div>
            <div>
                1. 简介
            </div>
            <div>
                欢迎使用IAMSET（以下简称“本网站”）。我们重视您的隐私，并致力于保护您的个人信息。本隐私政策（“本政策”）描述了我们如何收集、使用、共享和保护您的信息。通过使用本网站，您同意按照本政策的规定处理您的信息。
            </div>
            <div>
                2. 我们收集的信息
            </div>
            <div>
                我们可能会收集和处理以下类型的信息：
            </div>
            <div>
                2.1. 您提供的信息
            </div>
            <div>
                注册信息：当您创建账户时，我们会收集您的用户名、电子邮件地址、密码等信息。
                个人资料信息：您可以选择提供其他个人资料信息，如头像、联系方式、地址等。
                通讯内容：当您与我们联系或参与我们提供的服务时，我们会收集您发送的消息和内容。
            </div>
            <div>
                2.2. 自动收集的信息
            </div>
            <div>
                使用数据：我们会自动收集您使用本网站时的信息，如访问的页面、点击的链接、搜索的内容等。
                设备信息：我们会收集您的设备信息，包括设备类型、操作系统、浏览器类型和IP地址。
            </div>
            <div>
                3. 我们如何使用信息
            </div>
            <div>
                我们收集的信息用于以下目的：
            </div>
            <div>
                提供和维护服务：使用您的信息来提供和改进我们的服务。
                个性化体验：根据您的偏好和行为定制内容和广告。
                通讯：与您联系以提供客户支持、发送重要通知和市场推广信息。
                分析和改进：分析用户行为以改进我们的服务和用户体验。
                法律合规：遵守适用的法律法规，处理和解决法律纠纷。
            </div>
            <div>
                4. 信息共享和披露
            </div>
            <div>
                我们不会向第三方出售您的个人信息。我们可能会在以下情况下共享您的信息：
            </div>
            <div>
                本网站可能包含第三方网站的链接。用户访问这些链接需自担风险。本网站对任何第三方网站的内容、隐私政策或做法不承担责任。
            </div>
            <div>
                <ul style="margin: 0;">
                    <li>服务提供商：与提供服务的第三方服务提供商共享信息，他们仅能按照我们的指示使用信息。</li>
                    <li>法律要求：在法律要求或允许的情况下，向执法机构、政府机构或其他第三方披露信息。</li>
                    <li>业务转让：在业务合并、收购或出售过程中，转让您的信息。</li>
                </ul>
            </div>
            <div>
                本网站及其内容按“现状”提供。本网站不对内容的准确性、完整性或适用性作任何明示或默示的保证。用户使用本网站所产生的任何风险由用户自行承担。
            </div>
            <div>
                5. 信息安全
            </div>
            <div>
                根据适用法律，您可能享有以下权利：
            </div>
            <div>
                访问和更新信息：访问和更新我们持有的您的个人信息。
                删除信息：请求删除您的个人信息。
                限制处理：请求限制我们处理您的个人信息。
                数据可携性：请求将您的个人信息转移给您或其他服务提供商。
                撤回同意：撤回您对我们处理您的个人信息的同意，但不影响撤回前基于同意的处理。
            </div>
            <div>
                如果用户违反本协议的任何条款，本网站有权立即终止用户的账户和使用权限，而无需事先通知。
            </div>
            <div>
                7. 数据保留
            </div>
            <div>
                我们会在实现本政策所述目的所需的时间内保留您的个人信息，除非法律要求或允许更长的保留期。
            </div>
            <div>
                12. 联系信息
            </div>
            <div>
                8. 隐私政策的变更
            </div>
            <div>
                我们可能会不时更新本政策。任何变更将在本网站发布后立即生效。建议您定期查看本政策以了解最新的隐私实践。
            </div>
            <div>
                9. 联系我们
            </div>
            <div>
                如果您对本政策有任何疑问或需要支持，请联系学术服务小编。
            </div>

        </div>
    </basic-container>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.privacy_page {
    width: 1200px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 2;
    padding: 40px 0;

    .privacy_page_title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
    }
}
</style>