<template>
    <div class="content_box">
        <slot name="content">
        </slot>
    </div>
</template>

<script>
export default {
    name: 'serviceContent',
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.content_box {
    background: url(../../assets/img/font_title_bj.png) no-repeat;
    background-size: 100% 100%;

    .content_title {
        text-align: center;
        font-size: 20px;
        margin: 20px;
        color: #3e3e3e;
    }

    .content_desc {
        width: 75%;
        margin: 0 auto;
        color: #595959;
        font-size: 14px;
        text-align: center;
        padding: 0;
        line-height: 1.8;
    }
}
</style>