<template>
    <div class="page_banner_title">
        <div class="w1200">
            <div class="title_cn">{{ title }} </div>
            <span>/</span>
            <div class="title_en"> {{ entitle }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        entitle: {
            type: String,
            default: ''
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.page_banner_title {
    width: 100%;
    display: flex;
    justify-content: center;

    .w1200 {
        margin-top: 100px;
        width: 1200px;
        font-size: 30px;
        display: flex;
        color: white;

        span {
            margin: 0 10px;
        }

        .title_cn {
            height: fit-content;
            padding-bottom: 10px;
            font-weight: bold;
            border-bottom: 1px solid;
        }
    }
}
</style>