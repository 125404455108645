<template>
	<basic-container>
		<div class="box">
			<!-- 第一:轮播图 -->
			<div class="box_carousel">
				<!-- :autoplay="true" -->
				<el-carousel :interval="5000" style="height:100%" :autoplay="true">
					<!-- 第一页 -->
					<el-carousel-item>
						<div class="carousel-item-container">
							<div>
								<div class="carousel-text">
									<p style="font-size: 48px;font-weight: bold;">一站式学术服务 <br><span>助力学术成果转化</span></p>
									<div>
										<el-button class="car_button" @click="tipService">查看相关服务</el-button>
										<el-button class="car_button plus__" @click="openService">立即咨询</el-button>
									</div>
								</div>
								<div style="flex:1;display: flex;justify-content: right; height: 500px;">
									<img :src="require('@/assets/img/zanshi_.png')" alt="">
								</div>
							</div>
						</div>
					</el-carousel-item>
					<el-carousel-item v-for="(item, index) in banne_list" :key="index">
						<div @click="goToToUrl(item.href, item.type)" class="new_banner_">
							<img :src="item.src" alt="">
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<!-- 搜索 -->
			<div class="search">
				<div class="search_box">
					<div class="search_img">
						<img src="@/assets/img/sousuo_.png" alt="">
					</div>
					<div class="search_right">
						<div class="search_title">
							期刊搜索
						</div>
						<div class="content">
							涵盖全球核心学术期刊，海量数据，一键获悉
						</div>
						<div class="search_ipt">
							<el-input class="ipt_" placeholder="请输入期刊名/ISSN/eISSN" v-model="search.input">
								<el-select class="ipt_select" v-model="search.select" slot="prepend" placeholder="请选择"
									style="width: 100px;">
									<el-option label="SCI" value="/journalSci"></el-option>
									<el-option label="AHCI" value="/journalAHCI"></el-option>
									<el-option label="ESCI" value="/journalEsci"></el-option>
								</el-select>
								<!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
								<template slot="append"><el-button style='height:0px' type="primary"
										@click="getToJournal()">检索</el-button></template>
							</el-input>
						</div>
					</div>
				</div>
			</div>
			<!-- 第二:相关服务 -->
			<div class="box_service">
				<div class="box_center">
					<serviceTitle :title="'相关服务'"></serviceTitle>
					<div class="box_box xg_bj">
						<div class="box_box_top">
							<!-- 佘：服务标题，font-size: 22px; color: #18305b;
									期刊索引改为期刊搜索，立即索引改为立即搜索-->
							<el-card class="box-card" shadow="hover">
								<div class="flex_box">
									<el-image style="" :src="require('@/assets/img/qikjuoyn.png')"></el-image>
									<div>
										<p class="card_title_">期刊搜索</p>
										<div class="card_line">
											<p>数万本期刊资源</p>
											<p>期刊信息一键获悉</p>
										</div>
										<div>
											<el-button type="primary" class="qikjuoyn retrieval_btn"
												@click="toUrl('/journalSci')">立即搜索</el-button>
										</div>
									</div>

								</div>
							</el-card>

							<!-- 添加一个空白间隔 -->
							<div class="card-spacing"></div>

							<el-card class="box-card" shadow="hover">
								<div class="flex_box">
									<el-image style="" :src="require('@/assets/img/gojihvyi.png')"></el-image>
									<div>
										<p class="card_title_">学术会议</p>
										<div class="card_line">
											<p>国内外知名专家学者</p>
											<p>多学科领域会议</p>
										</div>
										<div>
											<el-button type="primary" class="gojihvyi retrieval_btn"
												@click="toUrl('/meeting')">立即参会</el-button>
										</div>
									</div>
								</div>
							</el-card>
						</div>
						<div class="box_box_bottom">
							<el-card class="box-card" shadow="hover">
								<el-image style="" :src="require('@/assets/img/index_5.png')"></el-image>
								<p class="card_title_">论文翻译</p>
								<div class="card_line">
									<p>专家博士中译英</p>
									<p>专业学术编辑团队</p>
								</div>
								<div>
									<el-button type="primary" class="retrieval_btn" @click="toUrl('/translate')">立即翻译
									</el-button>
								</div>
							</el-card>
							<!-- 添加一个空白间隔 -->
							<div class="card-spacing"></div>
							<el-card class="box-card" shadow="hover">
								<el-image style="" :src="require('@/assets/img/index_6.png')"></el-image>
								<p class="card_title_">论文润色</p>
								<div class="card_line">
									<p>多年论文润色经验</p>
									<p>欧美母语编辑润色论文</p>
								</div>
								<div>
									<el-button type="primary" class="retrieval_btn" @click="toUrl('/polishing')">立即润色
									</el-button>
								</div>
							</el-card>
							<!-- 添加一个空白间隔 -->
							<div class="card-spacing"></div>
							<el-card class="box-card" shadow="hover">
								<el-image style="" :src="require('@/assets/img/index_7.png')"></el-image>
								<p class="card_title_">查重服务</p>
								<div class="card_line">
									<p>算法精准高效</p>
									<p>支持多种检测功能</p>
								</div>
								<div>
									<el-button type="primary" class="retrieval_btn" @click="toUrl('/difference')">立即查重
									</el-button>
								</div>
							</el-card>
							<!-- 添加一个空白间隔 -->
							<div class="card-spacing"></div>
							<el-card class="box-card" shadow="hover">
								<el-image style="" :src="require('@/assets/img/index_8.png')"></el-image>
								<p class="card_title_">检索证明</p>
								<div class="card_line">
									<p>代开检索证明</p>
									<p>方便快捷又安全</p>
								</div>
								<div>
									<el-button type="primary" class="retrieval_btn" @click="toUrl('/retrieval')">立即查询
									</el-button>
								</div>
							</el-card>
						</div>
					</div>
				</div>
			</div>
			<!-- 第三：推荐会议 -->
			<div class="box_recommend_meeting">
				<div class="box_center">
					<serviceTitle :title="'推荐会议'"></serviceTitle>
					<div class="box_box">
						<div class="box_box_top" style="justify-content: space-between;">
							<el-card class="box-card-meeting" shadow="hover" v-for="(item, index) in meetingList"
								:key="index" :body-style="{ padding: '0px', height: '100%' }">
								<div style="display: flex; flex-direction: column; height: 100%" class="meeting_box">
									<el-link :underline='false' :href="item.fieldB" target="view_window"
										style="width: 100%;">
										<el-image style="width: 100%;height: 140px;cursor: pointer;"
											:src="'https://iamset.cn/file/' + item.imageUrl"
											@click="meetingDetails(item.website)"></el-image></el-link>

									<div style=" flex-grow: 1; display: flex; flex-direction: column;">
										<div
											style="margin: 10px 0; padding: 0 20px;  font-size: 16px; font-weight: 600;  display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis; -webkit-line-clamp: 1;">
											{{ item.meetingName }}
										</div>
										<div v-if="item.dbType"
											style="color: #4a90e2; margin: 10px 0; padding: 0 20px; font-size: 16px;">
											{{ item.dbType }}
										</div>
										<div style=" padding: 0 20px; font-size: 16px;margin: 8px 0;">
											<i class="el-icon-time" style="margin-right: 8px;"></i>
											<span>{{ item.duringTime }}</span>
										</div>
										<div
											style="padding: 0 20px; font-size: 16px; margin-bottom: 10px;padding-bottom: 10px;">
											<i class="el-icon-location-outline" style="margin-right: 8px;"></i>
											<span>{{ item.address }}</span>
										</div>
									</div>
								</div>

							</el-card>
						</div>
						<div class="show_more">
							<span @click='getToMeeting'>更多会议 →</span>
						</div>
					</div>
				</div>
			</div>
			<!-- 第四：学术头条 -->
			<div class="box_academic_headlines">
				<div class="box_center">
					<serviceTitle :title="'学术头条'"></serviceTitle>
					<div class="box_box">
						<div class="box_box_top">
							<div style='flex:2;position: relative; margin-right: 20px'>
								<el-tabs v-model="activeName1" @tab-click="handleClick1" style="margin-top: 3%;">
									<el-tab-pane label="科研要闻" name="科研要闻" style="font-size: 14px;">
										<div v-for="(item, index) in noticeList" :key="index">
											<div class="box_news_line">
												<img :src="item.icon" alt="">
												<div class='box_news_line_two'>
													<div class='firstLines'>
														<el-link v-if="item.fieldD != ''" :underline='false'
															:href="item.fieldD" target="view_window"
															style=" color: #18305b; font-size: 18px; ">
															<div class="nowrap"> {{ item.fieldA }}</div>
														</el-link>
														<el-link v-else :underline='false' href="javascript:void(0)"
															@click="noticeDetails(index)"
															style="color: #18305b;font-size: 16px;">{{
																item.fieldA }}
														</el-link>
													</div>
													<div style="color: #7d7d7d; font-size: 14px;">
														<div class="two">
															{{ item.fieldB }}
														</div>
														<div style="margin-top: 12px;">
															来源：{{ item.fieldC }} 丨 {{ item.createDate }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</el-tab-pane>
									<el-tab-pane label="干货分享" name="干货分享">
										<div v-for="(item, index) in noticeList" :key="index">
											<div class="box_news_line">
												<img :src="item.icon" alt="">
												<div class='box_news_line_two'>
													<div class='firstLines'>
														<el-link v-if="item.fieldD != ''" :underline='false'
															:href="item.fieldD" target="view_window"
															style=" color: #18305b; font-size: 18px; ">
															<div class="nowrap"> {{ item.fieldA }}</div>
														</el-link>
														<el-link v-else :underline='false' href="javascript:void(0)"
															@click="noticeDetails(index)"
															style="color: #18305b;font-size: 16px;">{{
																item.fieldA }}
														</el-link>
													</div>
													<div style="color: #7d7d7d;font-size: 14px;">
														<div class="two">
															{{ item.fieldB }}
														</div>
														<div style="margin-top: 12px;">
															来源：{{ item.fieldC }} 丨 {{ item.createDate }}
														</div>
													</div>
												</div>
											</div>

										</div>
									</el-tab-pane>
									<el-tab-pane label="协会新闻" name="协会新闻">
										<div v-for="(item, index) in noticeList" :key="index">
											<div class="box_news_line">
												<img :src="item.icon" alt="">
												<div class='box_news_line_two'>
													<div class='firstLines'>
														<el-link v-if="item.fieldD != ''" :underline='false'
															:href="item.fieldD" target="view_window"
															style=" color: #18305b; font-size: 18px; ">
															<div class="nowrap"> {{ item.fieldA }}</div>
														</el-link>
														<el-link v-else :underline='false' href="javascript:void(0)"
															@click="noticeDetails(index)"
															style="color: #18305b;font-size: 16px;">{{
																item.fieldA }}
														</el-link>
													</div>
													<div style="color: #7d7d7d;font-size: 14px;">
														<div class="two">
															{{ item.fieldB }}
														</div>
														<div style="margin-top: 12px;">
															来源：{{ item.fieldC }} 丨 {{ item.createDate }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</el-tab-pane>
								</el-tabs>

								<span @click='getToNews' style="cursor: pointer;"><span class="firstSpan">查看更多
										→</span></span>

							</div>
							<div class="tuijian_box">
								<el-card class="tuijian_card" shadow="hover">
									<div class="tuijian">
										<div class="title">
											每日推荐
										</div>

										<div class="img">
											<img :src="Recommend.icon" alt="">
										</div>

										<div class="content">

											{{ Recommend.description }}
										</div>
										<el-button @click="getToWx(Recommend.wxUrl)"
											style="width: 200px;background-color: #18305B; color: #ffffff; font-size: 16px; margin-bottom: 20px">
											查看期刊详情 →
										</el-button>
									</div>
								</el-card>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 第五：友情链接 -->
			<div class="box_links">
				<div class="box_center">
					<serviceTitle :title="'实用链接'"></serviceTitle>
					<div class="box_box">

						<div @click="goToLink($event)">
							<vue-seamless-scroll :data="publisher1" :class-option="classOption" class="seamless-warp">
								<ul class="ul-item">
									<li class="li-item" v-for="(item, index) in publisher1" :key="index">
										<img :src="item.src" alt="" class="li_img" :data-id="item.web"
											:data-index="item.web">
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
					</div>
				</div>
			</div>

			<el-dialog title="" :custom-class="'information_dialog_class'" :visible.sync="informationDialog" width="30%" :top="'22vh'"
				:before-close="() => informationDialog = false">
				<div class="information_dialog">
					<img src="../assets/img/xueshuxiaobian.jpg" alt="">
					<span>扫码添加学术编辑微信，进行一对一专业咨询</span>
				</div>
			</el-dialog>
		</div>
	</basic-container>
</template>

<script>
import serviceTitle from "@/components/common/serviceTitle.vue";
import {
	getBanner1,
	getMeetingList,
	getHotMeetingList,
	getRecommend
} from '@/api/index/index'

export default {
	name: 'index',
	components: {
		serviceTitle
	},
	data() {
		return {
			search: {
				input: '',
				select: ''
			},
			informationDialog: false,
			Recommend: {},//每日推薦
			carouselItems: [], //轮播图
			meetingList: [], //热门会议
			noticeList: [], //学术头条
			activeName1: "科研要闻", //学术头条
			paramMap: {
				page: 1,
				pageSize: 20,
				classification: ""
			},
			banne_list: [
				{
					src: "https://www.iamset.cn/uploadFile/17292155251792002841808_ICAMIS2024%E8%BD%AE%E6%92%AD%E5%9B%BE.jpg",
					href: "http://icamis.conferences.ac.cn/",
					type: "window"
				},
				{
					src: "https://www.iamset.cn/uploadFile/17161670759071682456269_17157450956202127917667_b348664b955d3e8456ac2bb7be280235.jpg",
					href: "/translate",
					type: "router"
				},
				{
					src: "https://www.iamset.cn/uploadFile/171558908153590960807_IF_Banner.jpg",
					href: "/journalSci",
					type: "router"
				}
			],
			publisher1: [{
				src: require('@/assets/img/rjlxLogo.png'),
				web: "http://www.rjlx.com.cn/"
			},
			{
				src: require('@/assets/img/xsgz.png'),
				web: "http://www.acagrid.com/"
			},
			{
				src: require('@/assets/img/doi.png'),
				web: "https://www.doi.org/"
			},
			{
				src: require('@/assets/img/orcid.png'),
				web: "https://orcid.org/"
			},
			{
				src: require('@/assets/img/cro.png'),
				web: "https://www.crossref.org/"
			},
			{
				src: require('@/assets/img/rese.png'),
				web: "https://www.thomsonreuters.com/en.html"
			},
			//one row 分界线--------------------------
			{
				src: require('@/assets/img/elseviep.png'),
				web: "https://www.elsevier.com/",
			},
			{
				src: require('@/assets/img/springerLogo.png'),
				web: "https://link.springer.com/"
			},
			{
				src: require('@/assets/img/wiley.png'),
				web: "https://www.wiley.com/"

			},
			{
				src: require('@/assets/img/tay.png'),
				web: "https://www.tandfonline.com"
			},
			{
				src: require('@/assets/img/sage.png'),
				web: "https://journals.sagepub.com/"
			},
			{
				src: require('@/assets/img/bnj.png'),
				web: "https://www.bmj.com/"
			},
			{
				src: require('@/assets/img/inder.png'),
				web: "https://www.inderscience.com/"
			},

			{
				src: require('@/assets/img/ieeeLogo.png'),
				web: "https://www.ieee.org/publications/index.html"
			},
			{
				src: require('@/assets/img/frontiersinLogo.png'),
				web: "https://www.frontiersin.org/"
			},
			{
				src: require('@/assets/img/mdpiLogo.png'),
				web: "https://www.mdpi.com"
			},
			{
				src: require('@/assets/img/hindawiLogo.png'),
				web: "https://www.hindawi.com/"
			},
			{
				src: require('@/assets/img/cam.png'),
				web: "https://www.cambridge.org/"
			},
			{
				src: require('@/assets/img/ws.png'),
				web: "https://www.worldscientific.com/"
			},
			//two row 分界线-----------------------------
			{
				src: require('@/assets/img/clar.png'),
				web: "https://clarivate.com/"
			},

			{
				src: require('@/assets/img/mjlLogo.png'),
				web: "https://mjl.clarivate.com/home"
			},

			{
				src: require('@/assets/img/villageLogo.png'),
				web: "https://www.engineeringvillage.com/search/quick.url"
			},

			{
				src: require('@/assets/img/scopus.png'),
				web: "https://www.scopus.com/"
			},
			{
				src: require('@/assets/img/acmLogo.png'),
				web: "https://dl.acm.org/journals"
			},

			],
			classOption: {
				direction: 2,
				step: 1
			}
		}
	},
	mounted() {
		this.getBanner()
		this.getMeeting()
		this.getAcademic()
		this.getRecommendInfo()
	},
	methods: {
		goToLink(e) {
			const link = e.target.dataset.id

			if (link == void (0)) return
			window.open(link);
		},
		// 每日推荐
		getRecommendInfo() {
			getRecommend().then((res) => {

				this.Recommend = res.data
			})
		},
		// 每日推荐跳转
		getToWx(url) {
			window.open(url);
		},
		getToJournal() {
			if (!this.search.select || !this.search.input) {
				this.$message({
					message: '请输入必填项',
					type: 'warning'
				});
				return
			}
			let activeName = ''
			if (this.search.select === '/journalSci') {
				activeName = 'SCI'
			} else if (this.search.select === '/journalAHCI') {
				activeName = 'AHCI'
			} else if (this.search.select === '/journalEsci') {
				activeName = 'ESCI'
			}
			this.$router.push({ path: this.search.select, query: { multipleConditions: this.search.input, activeName: activeName } })
		},
		//学术新闻更多跳转news
		getToNews() {
			this.$router.push('/news')
		},
		getToMeeting() {
			this.$router.push('/meeting')
			document.body.scrollTop = document.documentElement.scrollTop = 0
		},
		// 相关服务跳转
		toUrl(url) {
			this.$router.push(url)
			document.body.scrollTop = document.documentElement.scrollTop = 0
		},
		//获取轮播图
		getBanner() {
			this.paramMap.classification = 'indexBanner'
			getBanner1(this.paramMap).then((res) => {
				this.carouselItems = res.data
			})
		},
		//跳相关服务
		tipService() {
			this.$router.push('/translate')
			const targetElement = this.$refs.mySection;
			const targetOffset = targetElement.offsetTop - 190;
			window.scroll({
				top: targetOffset,
				left: 0,
				behavior: 'smooth'
			});
		},
		openService() {
			this.informationDialog = true
		},
		//获取会议
		getMeeting() {
			this.paramMap.classification = 'meeting'
			getHotMeetingList(this.paramMap).then((res) => {
				this.meetingList = res.data
			})
		},
		//打开会议列表图片
		meetingDetails(index) {
			window.open(index);
		},
		handleClick1() {
			this.getAcademic()
		},
		//获取学术头条
		getAcademic() {
			// 佘：请求条数改为4条
			this.paramMap.pageSize = 4
			this.paramMap.fieldH = this.activeName1 == '科研要闻' ? '学术动态' : this.activeName1,
				this.paramMap.classification = 'notice'
			getMeetingList(this.paramMap).then((res) => {

				this.noticeList = res.data
			})
		},
		//处理下载资源
		handleDow(index, row) {
			if (this.$store.state.isLogin == true) {
				window.location.href = "http://103.120.82.111:8066/downloadLocal?fileName=" + row.fieldD;
			} else {
				alert("请您先登录！")
			}
		},
		goToToUrl(url, type) {
			if (type == 'window') {
				window.open(url);
			} else {
				this.$router.push(url)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.box {
	width: 100%;
	min-width: 1200px;
	display: flex;
	flex-direction: column;
	background-color: hsl(240, 33.33%, 98.82%);
}

::v-deep .information_dialog_class {
	.el-dialog__body {
		background: linear-gradient(to bottom, #FFFFFF, #3c99ff36);
	}
}

.information_dialog {
	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		width: 150px;
		margin-bottom: 20px;
		padding: 10px;
		border: 1px solid #18305b;
		border-radius: 10px;
		background-color: white;
		box-sizing: border-box;
	}
}

.seamless-warp {
	width: 1200px;
	height: 129px;
	overflow: hidden;

	ul {
		list-style: none;
		padding: 0;
		margin: 0 auto;

		&.ul-item {
			display: flex;

			.li-item {
				width: 120px;
				height: 120px;
				margin-right: 20px;
				color: #fff;
				text-align: center;
				padding-top: 10px;

				.li_img {
					width: 100%;
					height: 60px;
					object-fit: contain;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
}

// 轮播图
::v-deep .box_carousel {
	width: 100%;
	min-width: 1200px;
	margin: 0 auto;
	height: 400px;
	min-height: 400px;

	.el-carousel__arrow--right {
		right: 96px;
	}

	.el-carousel__arrow--left {
		left: 96px;
	}
}

.box_carousel {
	.new_banner_ {
		&:hover {
			cursor: pointer;
		}
	}
}

::v-deep .el-carousel__container {
	height: 400px;
}

.carousel-item-container {
	width: 100%;
	height: 100%;
	background-image: url('../assets/img/bg.png');
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.carousel-item-container>div {
	width: 1200px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.carousel-text {
	flex: 1;
}

.carousel_title {
	font-size: 40px;
	font-weight: bold;
}

.car_button {
	margin-top: -20px;
	background-color: white;
	color: rgb(24, 48, 91);

	&:hover {
		background-color: rgb(24, 48, 91);
		color: white;
	}
}

.plus__ {
	background-color: #bf7c16;
	color: white;

	&:hover,
	&:focus {
		background-color: #dcaa5d;
	}

	&:focus {
		color: white;
	}

	&:active {
		border-color: transparent;
	}
}

.search {
	background-color: #fff;
}

.search_box {
	background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(../assets/img/font_title_bj.png);
	background-size: 52%;
	background-repeat: no-repeat;
	background-position-x: 580px;
	background-position-y: 130px;
	width: 1200px;
	height: 400px;
	margin: 0 auto;
	padding: 30px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.search_img {
		height: 500px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.search_right {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.search_title {
			font-weight: bold;
			font-size: 24px;
			color: #18305b;
		}

		.content {
			font-size: 16px;
			margin: 20px 0;
		}
	}
}

::v-deep .search_box .el-input-group__append {
	background-color: rgb(24, 48, 91);
	color: #fff;
	border: 1px solid rgb(24, 48, 91);
}

//相关服务
.box_service {
	width: 100%;
	margin: 0 auto;
	padding-bottom: 30px;
	background-color: hsl(240deg, 33.33%, 98.82%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.box_center {
	// width: 65%;
	width: 1200px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title1 {
	font-size: 32px;
	color: #18305b;
}

.box_box {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.box_box_top,
.box_box_bottom {
	display: flex;
	justify-content: space-between;
	margin: 0 0 20px 0;
}

::v-deep .box_box_top {
	.el-tabs__nav-wrap::after {
		background-color: white;
	}
}

.box-card {
	flex: 1;
	text-align: center;
	margin-top: 20px;

	.card_line {
		line-height: 8px;
		color: rgb(125, 125, 125);
		font-size: 14px;
	}

	.card_title_ {
		font-weight: bold;
		font-size: 22px;
		color: #18305b;
		margin: 16px;
	}
}

.card-spacing {
	width: 20px;
}

.el-card-body {
	padding: 35px;
}

.box-card {
	::v-deep .retrieval_btn {
		width: 160px;
		margin-top: 6px;
	}

	&:hover {
		transition: transform .2s, box-shadow .2s;
		transform: translateY(-4px);
		box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, .1);
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		transition: .3s;
		opacity: .2;
		transform-origin: center;
		transform: scaleX(0);
		border-radius: 5px;
	}

	&:hover::after {
		z-index: -1;
		transform: scaleX(1);
		background-image: linear-gradient(135deg, #90c3f7, #ffffff);
	}
}

//推荐会议
.box-card-meeting {
	width: calc(calc(100% - 70px)/4);
	margin-right: 10px;

	::v-deep .el-image__inner {
		transition: all .3s ease;
	}

	.meeting_box {
		&:hover {
			::v-deep .el-image__inner {
				cursor: pointer;
				transition: all .3s;
				transform: scale(1.1);
			}
		}
	}
}

.box-card-meeting:last-child {
	margin-right: 0;
}

.box_recommend_meeting {
	width: 100%;
	margin: 0 auto;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 30px;
}

.box-card-meeting .el-button {
	margin: 10px 0px;
	cursor: auto;
}

::v-deep .box-card-meeting .el-link--inner {
	width: 100%;
}

//学术头条
.box_academic_headlines {
	width: 100%;
	margin: 0 auto;
	background: url(../assets/img/xg_bj.png) no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 30px;
}

::v-deep .box_academic_headlines .el-tabs__item {
	font-size: 18px;
	color: #666666;
}

// 佘：更改颜色
::v-deep .box_academic_headlines .el-tabs__item.is-active {
	color: #18305b;
}

::v-deep .box_academic_headlines .el-tabs__item:hover {
	color: #18305b;
}

::v-deep .box_academic_headlines .el-tabs__active-bar {
	background-color: #18305b;
}

.num {
	float: left;
	text-align: center;
	margin-right: 15px;
	color: white;
	width: 22px;
	height: 22px;
	background-color: #ff5722;
	background-blend-mode: normal, normal;
}

.firstLines {
	display: flex;
	color: #18305b;
	font-weight: normal;

	.nowrap {
		max-width: 500px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 16px;
	}
}

.box_news_line {
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid #ffffff;
	margin-bottom: 10px;
	padding: 15px 0;

	img {
		width: 60px;
		height: 60px;
		margin: 0 20px 0 15px;
		object-fit: cover;
	}

	.box_news_line_two {
		display: flex;
		flex-direction: column;
	}

	.box_news_line_two p {
		margin: 0;
	}
}

.box_academic_headlines .firstSpan {
	position: absolute;
	top: 35px;
	right: 14px;
	margin-right: 2px;
	transition: all .2s;

	&:hover {
		filter: opacity(0.5)
	}
}

.rightImg {
	width: 2%;
	position: absolute;
	top: 38px;
	right: 14px;
}

//友情链接

.box_links {
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 30px;
}

.box_box_top_links {
	display: flex;
	justify-content: center;
}

::v-deep .box_box_top_links .el-image__inner {
	width: 80%;
	height: unset;
}

.box_box_top_links .box-card1 {
	width: 160px;
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
}

.box_box_top_links .el-image {
	width: 100%;
	display: flex;
	justify-content: center;
}

::v-deep .box_links .el-row {
	&:last-child {
		margin-bottom: 0;
	}
}

::v-deep .el-col {
	border-radius: 4px;
	padding: 0 5px !important;
	width: 100% !important;
}

.bg-purple-dark {
	background: #99a9bf;
}

.bg-purple-light {
	background: #e5e9f2;
}

.grid-content {
	border-radius: 4px;
	min-height: 36px;
}

.row-bg {
	padding: 10px 0;
	background-color: #f9fafc;
}

.flex_box {
	height: 252px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.box_box_bottom,
.box_box_top {
	.el-button {
		color: #262626 !important;
		background-color: white !important;
		transition: all .2s;

		&:hover {
			background-color: #18305b !important;
			color: white !important;
		}
	}
}

.tuijian_box {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	.tuijian_card {
		background-image: linear-gradient(135deg, #ebf5ff99, #ffffffab);
		background-color: transparent;
		width: 100%;
		flex: 1;
		text-align: center;
		margin-top: 20px;
	}

	.tuijian {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 !important;
	}

	.title {
		margin-top: 10px;
		margin-bottom: 30px;
		font-size: 22px;
		font-weight: bold;
		border-bottom: 1px solid #000000;
		width: 100%;
		padding-bottom: 20px;
	}

	.content {
		margin: 30px 20px;
		max-width: 300px;
		display: -webkit-box;
		overflow-wrap: break-word;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		border-radius: 3px;
		padding: 10px 0;
		color: white;
		background-color: #bf7c16;
	}

	.img {
		max-width: 300px;
		max-height: 200px;
	}

	img {
		max-width: 300px;
		max-height: 200px;
	}
}

::v-deep .el-carousel__container {
	height: 100%;
}

::v-deep .el-carousel__button {
	background-color: #18305B !important;
}
</style>
<style>
.search_ipt .el-input-group>.el-input__inner {
	width: 400px;
}

.ipt_select .el-input .el-input__inner,
.ipt_select .el-input .el-input__inner::placeholder {
	color: #18305b !important;
}

.show_more {
	text-align: right;
	margin-top: 20px;
}

.show_more span {
	font-size: 16px;
	transition: all .2s;
}

.show_more span:hover {
	filter: opacity(0.5);
	cursor: pointer;
}
</style>