<template>
    <div class="about_service">
        <div class="about_service_title">服务范围</div>
        <div class="about_service_list">
            <div class="about_service_list_item" v-for="(item, index) in serviceList" :key="index">
                <div class="about_service_list_item_top">
                    <img :src="item.icon" alt="" class="about_service_list_item_left_img">
                    <img src="../../assets/img/about/right_top_o.png" alt="" class="about_service_list_item_right_img"
                        @click="goToPage(item.path)">
                </div>
                <div class="about_service_list_item_bottom">
                    <div class="about_service_list_item_title">{{ item.titleRichText }}</div>
                    <div class="about_service_list_item_content">{{ item.contentRichText }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { serviceList } from "./data"
import CollapseList from "@/components/collapseList/index.vue"
import serviceTitle from "@/components/common/serviceTitle.vue";

export default {
    components: {
        serviceTitle,
        CollapseList
    },
    data() {
        return {
            serviceList
        }
    },
    methods: {
        goToPage(path) {
            window.scroll(0, 0)
            this.$router.push(path)
        }
    }
}
</script>

<style lang="scss">
.about_service {
    width: 1200px;

    .about_service_title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .about_service_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .about_service_list_item {
            flex: 0 0 24%;
            padding: 20px;
            box-sizing: border-box;
            border-radius: 4px;
            background-color: white;
            margin-bottom: 14px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .05);
            transition: all .2s;

            &:hover {
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            }

            .about_service_list_item_top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 40px;

                .about_service_list_item_left_img {
                    width: 50px;
                    transform: translateX(-4px);
                }

                .about_service_list_item_right_img {
                    width: 20px;
                    height: 20px;
                    transition: all .2s;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .about_service_list_item_bottom {
                .about_service_list_item_title {
                    font-weight: bold;
                    margin-bottom: 14px;
                }

                .about_service_list_item_content {
                    font-size: 14px;
                    color: #808080;
                    line-height: 1.5;
                }
            }
        }
    }
}
</style>