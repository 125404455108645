
import request from '@/utils/request';

export const getUserInfo = (paramap) => {
    return request({
        url: '/joi/userCenter/getUserInfo',
        method: 'get',
        params: paramap
    })
}
export const updateInfo = (paramap) => {
    return request({
        url: '/joi/userCenter/updateUserInfo',
        method: 'post',
        data: paramap
    })
}
export const updateImg = (paramap) => {
    return request({
        url: '/joi/userCenter/uploadByMinIo',
        method: 'post',
        data: paramap
    })
}
export const setPassword = (paramap) => {
    return request({
        url: '/joi/userCenter/resetPassword',
        method: 'get',
        params: paramap
    })
}
export const setEmail = (paramap) => {
    return request({
        url: '/joi/userCenter/bindUserEmail',
        method: 'get',
        params: paramap
    })
}
export const getPhoneCode = (paramap) => {
    return request({
        url: '/joi/sendVerificationCodeByPhone',
        method: 'post',
        data: paramap
    })
}
export const setPhone = (paramap) => {
    return request({
        url: '/joi/userCenter/checkUserPhone',
        method: 'post',
        data: paramap
    })
}
export const setPhoneTwo = (paramap) => {
    return request({
        url: '/joi/userCenter/bindNewUserPhone',
        method: 'post',
        data: paramap
    })
}