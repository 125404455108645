<template>
	<div class="basic-user-container">
		<div>
			<slot>
			</slot>
		</div>
		<back-top class="classTop"></back-top>
	</div>
</template>

<script>
import influenceHeader from '@/components/common/header'
import influenceBottom from '@/components/common/bottom'
import backTop from '@/components/common/backTop'
export default {
	components: {
		influenceHeader,
		influenceBottom,
		backTop
	},
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>

<style lang="scss" scoped>
.basic-user-container {
	width: 100%;
}

.classTop {
	position: fixed;
	bottom: 280px;
	right: 65px;
	z-index: 10000;
}
</style>