export const bannerList = [
    {
        title: "论文翻译",
        describe: [
            "15年学术翻译经验",
            "同学科国内外编辑团队",
            "符合期刊语言要求",
            "3-5个工作日反馈",
            "全程高度加密更安全"
        ],
        image: require("@/assets/img/fanyi_.png")
    },
    {
        title: "论文润色",
        describe: [
            "15年学术润色经验",
            "同领域专业编辑团队，精准理解学科术语",
            "提高文章语言流畅性和逻辑性，符合国际期刊标准",
            "3-5个工作日内提供润色反馈",
            "严格保密，保障您的学术隐私"
        ],
        image: require("@/assets/img/runse_.png")
    },
    {
        title: "选刊服务",
        describe: [
            "15年学术出版经验",
            "精准分析论文内容和目标期刊的匹配度",
            "推荐符合学术质量要求的国际优质期刊",
            "提供投稿期刊的详细信息和建议",
            "高度保密，确保您的论文安全"
        ],
        image: require("@/assets/img/xuankan_.png")
    },
    {
        title: "查重服务",
        describe: [
            "专业turnitin查重工具，确保论文原创性",
            "提供详细查重报告，识别潜在重复内容",
            "提供具体修改建议，帮助降低相似度",
            "24h内反馈查重结果",
            "全程保密处理，确保您的论文安全无忧"
        ],
        image: require("@/assets/img/chachong_.png")
    },
    {
        title: "检索证明",
        describe: [
            "提供论文的多数据库检索证明",
            "确保论文被权威数据库或期刊正式收录",
            "电子+纸质检索证明，双重备份无忧",
            "核心机构直邮，2-3天抵达",
        ],
        image: require("@/assets/img/jiansuo_.png")
    }
]