<template>
    <div class="server_flow">
        <div class="server_flow_item" v-for="(item, index) in stepList" :key="index">
            <div class="server_flow_item_top">
                <div class="server_flow_item_top_index">{{ index + 1 }}</div>
                <div class="server_flow_item_top_title">{{ item.title }}</div>
            </div>
            <div class="server_flow_item_desc">{{ item.desc }}</div>
        </div>
    </div>
</template>

<script>
import step1 from "@/assets/img/service_1.png"
import step2 from "@/assets/img/service_2.png"
import step3 from "@/assets/img/service_3.png"
import step4 from "@/assets/img/service_4.png"
import step5 from "@/assets/img/service_5.png"

export default {
    name: 'serverFlow',
    data() {
        return {
            stepList: [
                {
                    title: '提交申请',
                    img: step1,
                    desc: '提交稿件并说明需求'
                },
                {
                    title: '确认订单',
                    img: step2,
                    desc: '确认订单并付款'
                },
                {
                    title: '匹配编辑',
                    img: step3,
                    desc: '根据稿件匹配编辑'
                },
                {
                    title: '交付稿件',
                    img: step4,
                    desc: '时间内交付稿件'
                },
                {
                    title: '开具发票',
                    img: step5,
                    desc: '开具发票和售后服务'
                }
            ]
        }
    }
}
</script>
<style lang='scss'>
.server_flow {
    display: flex;
    justify-content: space-between;

    .server_flow_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        filter: hue-rotate(335deg);
        width: 240px;
        height: 135px;
        word-break: break-all;
        background: url(../../assets/img/intro-bg.png) no-repeat center 100%;
        padding-left: 60px;
        margin-right: -20px;
        transition: all .3s;

        &:hover {
            cursor: pointer;
            filter: hue-rotate(135deg);
        }

        .server_flow_item_top {
            display: flex;
            align-items: center;

            .server_flow_item_top_index {
                font-size: 24px;
                font-weight: bold;
                position: relative;
                margin-right: 10px;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 5px;
                    left: -5px;
                    width: 25px;
                    height: 5px;
                    background: rgba(114, 73, 230, 0.7);
                }
            }

            .server_flow_item_top_title {
                font-size: 18px;
            }
        }
    }

    .server_flow_item_desc {
        color: #595e64;
        font-size: 14px;
        margin-left: 26px;
    }
}
</style>