import request from '@/utils/request';

export const getJournalList = (url, paramMap) => {
	return request({
		url: `/joi/${url}`,
		method: 'post',
		data: paramMap
	})
}
// sci详情页
export const getSciJournalList = (paramMap) => {
	return request({
		url: '/joi/iamsetSciSsciJournalsById',
		method: 'post',
		data: paramMap
	})
}

export const getPieEchartSCi = (paramMap) => {
	return request({
		url: '/joi/getPieChart',
		method: 'post',
		data: paramMap
	})
}


export const getCitedTopTenSCi = (paramMap) => {
	return request({
		url: '/joi/getTop10Wos',
		method: 'post',
		data: paramMap
	})
}


export const getPublishAppraiseSCi = (paramMap) => {
	return request({
		url: '/joi/addReply',
		method: 'post',
		data: paramMap
	})
}

export const getCommentSCi = (paramMap) => {
	return request({
		url: '/joi/pageQueryReply',
		method: 'post',
		data: paramMap
	})
}



export const getToggleLikeSCi = (paramMap) => {
	return request({
		url: '/joi/addLikeRecord',
		method: 'post',
		data: paramMap
	})
}


export const getRandomJournalListSCi = (paramMap) => {
	return request({
		url: '/joi/randomIamsetJournalList',
		method: 'post',
		data: paramMap
	})
}

export const getPostErrorSCi = (paramMap) => {
	return request({
		url: '/joi/addErrorMessage',
		method: 'post',
		data: paramMap
	})
}



// AHCI详情页
export const getAhciJournalList = (paramMap) => {
	return request({
		url: '/joi/getAhciJournalDetailById',
		method: 'post',
		data: paramMap
	})
}

// ESCI详情页
export const getEsciJournalList = (paramMap) => {
	return request({
		url: '/joi/getEsciJournalDetailById',
		method: 'post',
		data: paramMap
	})
}