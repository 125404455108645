// 导入axios库
import axios from 'axios';

// 创建axios实例
const axiosInstance = axios.create({
  baseURL: '', // 设置你的API基础路径
  timeout: 50000, // 设置请求超时时间（毫秒）
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    // 在请求发送之前添加token等处理
    /*  const token = localStorage.getItem('token'); // 假设token存储在localStorage中
     if (token) {
       config.headers['Authorization'] = `Bearer ${token}`;
     } */
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    // 在响应数据之前可以进行一些处理
    return response.data;
  },
  (error) => {
    // 可以在这里统一处理错误响应
    if (error.response && error.response.status === 401) {
      // 例如处理未授权错误
      console.error('Unauthorized, please log in again.');
      // 这里可以添加重定向到登录页面的逻辑
    } else if (error.response && error.response.status === 500) {
      // 处理服务器错误
      console.error('Server error, please try again later.');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
