<template>
	<basic-container>
		<div class='box'>
			<div class="page-content about_swiper_box">
				<pageBannerTitle :title="'关于我们'" :entitle="'ABOUT US'"></pageBannerTitle>
			</div>
			<div class="about_box_container">
				<!-- 协会简介 -->
				<div>
					<abouMeVue></abouMeVue>
				</div>
			</div>

			<div class="page_about_box_content" id="server">
				<!-- 服务范围 -->
				<scopeServiceVue></scopeServiceVue>
			</div>

			<div class="about_box_container">
				<!-- 服务宗旨 -->
				<div>
					<guaranteeVue></guaranteeVue>
				</div>

				<!-- 业务咨询 -->
				<div class="yewu_b">
					<div class="yewu1">
						<div class="email_left">
							<businessVue></businessVue>
						</div>
					</div>
				</div>

				<questionVue id="question"></questionVue>
			</div>
		</div>
	</basic-container>
</template>

<script>
import { mediaList } from "./about/data";
import serviceTitle from "@/components/common/serviceTitle.vue";
import abouMeVue from "./about/abouMe.vue";
import scopeServiceVue from "./about/scopeService.vue";
import guaranteeVue from "./about/guarantee.vue";
import caseVue from "./about/case.vue";
import businessVue from "./about/business.vue";
import questionVue from "./about/question.vue";
import pageBannerTitle from '@/components/common/pageBannerTitle.vue';

export default {
	components: {
		serviceTitle,
		abouMeVue,
		scopeServiceVue,
		guaranteeVue,
		caseVue,
		businessVue,
		questionVue,
		pageBannerTitle
	},
	data() {
		return {
			mediaList,
		}
	}
}
</script>

<style lang="scss">
.yewu_b {
	.yewu1 {
		display: flex;

		.email_left {
			width: 100%;
		}

		.yewu1_1 {
			display: flex;
			height: 100%;
			flex-wrap: wrap;

			.yewu_1_1_1 {
				margin-bottom: 10px;
				flex: 0 0 33%;

				.yewu1_1_1_1 {
					width: 128px;
					height: 128px;
					margin: 20px 0;
				}
			}
		}
	}
}

.page_about_box_content {
	display: flex;
	justify-content: center;
	background-color: #f2f3f6;
	padding: 30px 0;
}

.box {
	width: 100%;
	min-width: 1200px;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
}

.about_swiper_box {
	height: 400px;
	color: white;
	background: url(../assets/img/aboutus_banner_bj.jpg) no-repeat;
	background-size: 100% 100%;
	display: flex;
	justify-content: center;

	.swiper_container {
		font-size: 60px;
		font-weight: bold;
		color: #fffffd;
		text-align: center;

		.title_en {
			font-size: 24px;
		}
	}
}

.about_box_container {
	width: 1200px;
	margin: 0 auto;
}

.que_title {
	font-size: 28px;
	font-weight: 600;
	text-align: center;
	margin: 40px 0;
	color: #252B3A;
}

.title_icon {
	color: #797979;
	font-weight: 600;
	margin-right: 20px;
	font-size: 20px;
}

.qrcode_right {
	width: 100%;

	.qr_code_box_box {
		display: flex;
		flex-wrap: wrap;

		.qr_code_box {
			display: flex;
			flex: 0 0 16%;
			flex-direction: column;
			margin-bottom: 20px;
		}
	}
}

.num {
	text-align: center;
	margin-right: 15px;
	color: white;
	width: 22px;
	height: 22px;
	background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%),
		linear-gradient(#d40016, #d40016);
	background-blend-mode: normal, normal;
}
</style>