<template>
    <div class="about_business_">
        <div class="ab_b_title">
            业务咨询
        </div>
        <div class="about_business_box">
            <div class="bus_b_item" v-for="(item, index) in businessList" :key="index">
                <div class="bus_b_item_img">
                    <img :src="item.image" alt="">
                </div>
                <div class="bus_b_item_line">
                    <div class="bus_b_item_line_title">{{ item.title }}</div>
                    <div class="bus_b_item_line_content">{{ item.content }}</div>
                    <div class="bus_b_item_line_email">
                        <a :href="`mailto:${item.email}?subject=&body=`">{{ item.email }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { businessList } from "./data"

export default {
    data() {
        return {
            businessList
        }
    }
}
</script>

<style lang='scss'>
.about_business_ {
    .ab_b_title {
        font-size: 20px;
        font-weight: bold;
        margin: 30px 0;
    }

    .about_business_box {
        display: flex;
        justify-content: space-between;

        .bus_b_item {
            flex-shrink: 0;
            width: 380px;
            box-sizing: border-box;
            padding: 32px;
            background: #FFFFFF;
            box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #EBEFF6;
            display: flex;
            gap: 25px;

            .bus_b_item_img {
                img {
                    width: 100px;
                }
            }

            .bus_b_item_line {
                .bus_b_item_line_title {
                    font-weight: 500;
                    font-size: 16px;
                    color: #21262C;
                    margin-bottom: 8px;
                }

                .bus_b_item_line_content {
                    font-size: 14px;
                    color: #616873;
                    margin-bottom: 16px;
                }

                .bus_b_item_line_email {
                    a {
                        font-size: 16px;
                        color: #18305b;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
</style>