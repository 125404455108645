<template>
    <div class="service_title_">
        {{ title }}
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: {
        title: {
            type: String,
            default: ""
        },
    }
}
</script>
<style lang='scss'>
.service_title_ {
    color: #333333;
    text-align: center;
    font-size: 34px;
    margin: 50px 0;
    font-weight: 600;

    &::before {
        left: -20px;
        content: "";
        width: 40px;
        height: 1px;
        background-color: #180844;
        display: inline-block;
        position: relative;
        top: -7px;
    }

    &::after {
        left: 20px;
        content: "";
        width: 40px;
        height: 1px;
        background-color: #180844;
        display: inline-block;
        position: relative;
        top: -7px;
    }
}
</style>