<template>
	<basic-container>
		<div class="box">
			<div id="" class="swiper_box">
				<pageBannerTitle :title="'期刊搜索'" :entitle="'PUBLICATIONS'"></pageBannerTitle>
			</div>

			<div class="page-content">
				<div class="page-content-top">
					<el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: 14px;">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>期刊搜索</el-breadcrumb-item>
						<el-breadcrumb-item>{{ getTabName(activeName) }}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="page-content-bottom">
					<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top: 3%" :stretch="true">

						<el-tab-pane label="SCI期刊&SSCI期刊" name="SCI" style="font-size: 20px;">
							<div class="pane_first">
								<!-- <div class="chooseSearchStyle">
									<el-radio-group v-model="chooseSearch" @change="handleSearchStyleChange">
										<el-radio-button label="基本检索"></el-radio-button>
										<el-radio-button label="高级检索"></el-radio-button>
									</el-radio-group>
								</div> -->
								<div style="margin: 20px 0;" v-if="chooseSearch == '基本检索'" class="basic_box">
									<el-input placeholder="请输入期刊名/ISSN/eISSN" v-model="sciSearch.multipleConditions">
										<template slot="append"><el-button
												style='height:0px;width: 150px;font-size: 16px;' type="primary"
												@click="getSearching">检索</el-button></template>
									</el-input>
									<el-button type="text" style="margin-left: 10px;color:#18305b;font-size: 16px"
										@click="handleSearchStyleChange('高级检索')">高级检索</el-button>
								</div>
								<div class="sciForm" v-if="chooseSearch == '高级检索'">
									<el-form :label-position="labelPosition" label-width="80px" :model="sciSearch">
										<el-form-item label="期刊名称">
											<el-input v-model="sciSearch.journalName" placeholder="请输入期刊名称"></el-input>
										</el-form-item>
										<!-- 新加字段 -->
										<el-form-item label="ISSN/eISSN">
											<el-input v-model="sciSearch.issn" placeholder="请输入ISSN/eISSN"></el-input>
										</el-form-item>
										<el-form-item label="JCR分区">
											<el-select style="width: 100%;" v-model="sciSearch.jciQuartile" filterable
												placeholder="所有" @change="sciSubjectChange">
												<el-option v-for="item in subregionKeyValue" :key="item.value"
													:label="item.key" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="国家">
											<el-input v-model="sciSearch.country" placeholder="请输入国家"></el-input>
										</el-form-item>
										<!-- 新加字段 -->
										<el-form-item label="领域">
											<el-input v-model="sciSearch.mixPpf" placeholder="请输入领域"></el-input>
										</el-form-item>
										<el-form-item class="form_btn">
											<el-button type="primary" @click="getSearching">检索</el-button>
											<el-button type="default" @click="sciSearchReset">重置</el-button>
											<el-button type="text" style="color:#18305b"
												@click="handleSearchStyleChange('基本检索')">返回</el-button>
										</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>

						<el-tab-pane label="AHCI期刊" name="AHCI">

							<div class="pane_first">
								<!-- <div class="chooseSearchStyle">
									<el-radio-group v-model="chooseSearch" @change="handleSearchStyleChange">
										<el-radio-button label="基本检索"></el-radio-button>
										<el-radio-button label="高级检索"></el-radio-button>
									</el-radio-group>
								</div> -->
								<div style="margin: 20px 0;" v-if="chooseSearch == '基本检索'" class="basic_box">
									<el-input placeholder="请输入期刊名/ISSN/eISSN" v-model="ahciSearch.multipleConditions">
										<template slot="append">
											<el-button type="primary" size="medium" @click="getSearching"
												style="width: 150px;;font-size: 16px;">检索</el-button>
										</template>
									</el-input>
									<el-button type="text" style="margin-left: 10px;color:#18305b;font-size: 16px"
										@click="handleSearchStyleChange('高级检索')">高级检索</el-button>
								</div>
								<div class="sciForm" v-if="chooseSearch == '高级检索'">
									<el-form :label-position="labelPosition" label-width="80px" :model="ahciSearch">
										<el-form-item label="期刊名称">
											<el-input v-model="ahciSearch.journalName" placeholder="请输入期刊名称"></el-input>
										</el-form-item>
										<!-- 新加字段 -->
										<el-form-item label="ISSN/eISSN">
											<el-input v-model="ahciSearch.issn" placeholder="请输入ISSN/eISSN"></el-input>
										</el-form-item>
										<el-form-item label="JCR分区">
											<el-select style="width: 100%;" v-model="ahciSearch.jciQuartile" filterable
												placeholder="所有" @change="sciSubjectChange">
												<el-option v-for="item in subregionKeyValue" :key="item.value"
													:label="item.key" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="国家">
											<el-input v-model="ahciSearch.country" placeholder="请输入国家"></el-input>
										</el-form-item>
										<!-- 新加字段 -->
										<el-form-item label="领域">
											<el-input v-model="ahciSearch.mixPpf" placeholder="请输入领域"></el-input>
										</el-form-item>
										<el-form-item class="form_btn">
											<el-button type="primary" @click="getSearching">检索</el-button>
											<el-button type="default" @click="ahciSearchReset">重置</el-button>
											<el-button type="text" style="color:#18305b"
												@click="handleSearchStyleChange('基本检索')">返回</el-button>
										</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="ESCI期刊" name="ESCI">
							<div class="pane_first">
								<!-- <div class="chooseSearchStyle">
									<el-radio-group v-model="chooseSearch" @change="handleSearchStyleChange">
										<el-radio-button label="基本检索"></el-radio-button>
										<el-radio-button label="高级检索"></el-radio-button>
									</el-radio-group>
								</div> -->
								<div style="margin: 20px 0;" v-if="chooseSearch == '基本检索'" class="basic_box">
									<el-input placeholder="请输入期刊名/ISSN/eISSN" v-model="esciSearch.multipleConditions">
										<template slot="append"><el-button type="primary" @click="getSearching"
												style="width: 150px;;font-size: 16px;">检索</el-button></template>
									</el-input>
									<el-button type="text" style="margin-left: 10px;color:#18305b;font-size: 16px;"
										@click="handleSearchStyleChange('高级检索')">高级检索</el-button>
								</div>
								<div class="sciForm" v-if="chooseSearch == '高级检索'">
									<el-form :label-position="labelPosition" label-width="80px" :model="esciSearch">
										<el-form-item label="期刊名称">
											<el-input v-model="esciSearch.journalName" placeholder="请输入期刊名称"></el-input>
										</el-form-item>
										<!-- 新加字段 -->
										<el-form-item label="ISSN/eISSN">
											<el-input v-model="esciSearch.issn" placeholder="请输入ISSN/eISSN"></el-input>
										</el-form-item>
										<el-form-item label="JCR分区">
											<el-select style="width: 100%;" v-model="esciSearch.jciQuartile" filterable
												placeholder="所有" @change="sciSubjectChange">
												<el-option v-for="item in subregionKeyValue" :key="item.value"
													:label="item.key" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="国家">
											<el-input v-model="esciSearch.country" placeholder="请输入国家"></el-input>
										</el-form-item>
										<!-- 新加字段 -->
										<el-form-item label="领域">
											<el-input v-model="esciSearch.mixPpf" placeholder="请输入领域"></el-input>
										</el-form-item>
										<el-form-item class="form_btn">
											<el-button type="primary" @click="getSearching">检索</el-button>
											<el-button type="default" @click="esciSearchReset">重置</el-button>
											<el-button type="text" style="color:#18305b"
												@click="handleSearchStyleChange('基本检索')">返回</el-button>
										</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
				<el-divider class="divider"></el-divider>
				<div class="content_list" v-loading="loading">
					<div class="journal" v-for="(journal, journalIndex) in journalList" :key="journalIndex"
						@mouseover="mouseOver(journalIndex)" @mouseleave="mouseLeave(journalIndex)"
						@click="journalDetails(journalIndex)">
						<div class="journal5" :class="active == journalIndex ? 'journal3' : ''"
							style="width: 100%; height: 100%;">
							<el-row :gutter="20" style="margin-left: -20px;display: flex;align-items: center;">
								<el-col :span="9" style="padding: 14px 0 0 35px;">
									<div v-if="journal.imageLoadFailed">
										<img style="width: 168px; height: 222px" :src="journal.indexFaceHref"
											@error="checkImageAvailabilityTwo(journal, journalIndex)" alt="Image" />
									</div>
									<div v-else class="fallback-bg">
										<div class="fallback-bg-text">
											<div class="three">
												{{ journal.journalName }}
											</div>
											<div style="" class="eight">
												{{ journal.publisherName }}
											</div>
										</div>
									</div>

								</el-col>
								<el-col :span="15">
									<!-- <p class="two title">{{ journal.journalTitle }}</p> -->
									<div>
										<p style="font-weight: bold;line-height: 26px;">{{ journal.journalName }}</p>
										<p class="jou_p">
											<span style="color: #666666">ISSN：</span>
											<span style="color: #262626">{{ journal.issn }}</span>
										</p>
										<p class="jou_p">
											<span style="color: #666666">E-ISSN：</span>
											<span style="color: #262626">{{ journal.eissn }}</span>
										</p>
										<p class="two jou_p" :title="journal.publisher">
											<span style="color: #666666">JCR分区：</span><span style="color: #262626">{{
												journal.jciQuartile }}</span>
										</p>
										<p class="two jou_p" :title="journal.publisher">
											<span style="color: #666666">国家：</span><span style="color: #262626">{{
												journal.country }}</span>
										</p>

									</div>
								</el-col>
							</el-row>
						</div>
					</div>
					<div v-if="show" style="margin: auto;">
						<el-empty description="暂无数据"></el-empty>
					</div>

				</div>
			</div>

		</div>
		<div class="pagination" :class="lastNone ? 'lastNone' : 'lastBlock'">
			<el-pagination layout="prev, pager, next, sizes,jumper" @current-change="handleCurrentChange"
				@size-change="handleSizeChange" :current-page="paramap.pageNo" :page-size="paramap.pageSize - 0"
				:pager-count="5" :page-sizes="[10, 20, 30, 40]" :total="paramap.total">
			</el-pagination>
		</div>
	</basic-container>
</template>

<script>
import { Loading } from 'element-ui';
import {
	getJournalList
} from '@/api/journal/journal'
import pageBannerTitle from '@/components/common/pageBannerTitle.vue';

export default {
	props: ["activeNameF"],
	name: 'journal',
	components: {
		pageBannerTitle
	},
	data() {
		return {
			active: "false",
			activeName: this.activeNameF,
			loading: false, //加载框
			show: false, //没数据展示暂无
			labelPosition: 'top', //表单位置
			chooseSearch: '基本检索', //选择基本检索还是高级检索
			sciSearch: {
				multipleConditions: '', //基本检索的值
				journalName: '', //期刊名称
				country: '', //国家
				jciQuartile: '', //JCR分区
				mixPpf: '',//领域
				issn: '',//

			}, //sci检索参数

			ahciSearch: {
				multipleConditions: '', //基本检索的值
				journalName: '', //期刊名称
				country: '', //国家
				jciQuartile: '', //JCR分区
				mixPpf: '',//领域
				issn: '',//
			}, //ahci检索参数

			esciSearch: {
				multipleConditions: '', //基本检索的值
				journalName: '', //期刊名称
				country: '', //国家
				jciQuartile: '', //JCR分区
				mixPpf: '',//领域
				issn: '',//
			}, //ahci检索参数

			paramap: {
				pageNo: 1,
				total: 0,
				pageSize: 10
			},
			subregionKeyValue: [{
				key: "所有",
				value: ""
			},
			{
				key: "Q1",
				value: "Q1"
			},
			{
				key: "Q2",
				value: "Q2"
			},
			{
				key: "Q3",
				value: "Q3"
			},
			{
				key: "Q4",
				value: "Q4"
			},
			],
			journalList: [], //期刊数据
			isImageAvailable: true,
			imageLoadFailed: false, //期刊图片加载是否失败
			lastNone: ''

		}
	},
	created() {
		window.addEventListener('keydown', this.handkeyCode, true) //开启监听键盘按下事件
		const category = this.$route.query.category;
		if (category) {
			this.activeName = category
		}
		let { multipleConditions, activeName } = this.$route.query
		if (multipleConditions || activeName) {
			console.log(multipleConditions, activeName);
			this.activeName = activeName
			if (this.activeName == "SCI") {
				this.sciSearch.multipleConditions = multipleConditions
			} else if (this.activeName == "AHCI") {
				this.ahciSearch.multipleConditions = multipleConditions
			} else if (this.activeName == "ESCI") {
				this.esciSearch.multipleConditions = multipleConditions
			}
			this.getData()
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		getTabName(tabName) {
			if (tabName == "SCI") return "SCI期刊&SSCI期刊"
			else if (tabName == "AHCI") return "AHCI期刊"
			else return "ESCI期刊"
		},
		sciSearchReset() {
			Object.keys(this.sciSearch).forEach(key => {
				this.sciSearch[key] = '';
			});
		},
		esciSearchReset() {
			Object.keys(this.esciSearch).forEach(key => {
				this.esciSearch[key] = '';
			});
		},
		ahciSearchReset() {
			Object.keys(this.ahciSearch).forEach(key => {
				this.ahciSearch[key] = '';
			});
		},
		lastLi() {
			this.$nextTick(_ => {
				this.lastNone = !!document.getElementsByClassName('btn-quicknext')[0]
			})
		},
		getSearching() {
			this.getData()
		},
		//获取期刊数据
		getData() {
			// if (!this.loading) this.loading = true
			let loadingInstance = Loading.service({
				lock: true,
				text: '加载中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.1)'
			});
			let url = "journalList";
			let paramap = {}
			if (this.activeName == "SCI") {
				url = "getSciSsciJournalPage";
				paramap = { ...this.paramap, ...this.sciSearch };
			} else if (this.activeName == "AHCI") {
				url = "getAhciJournalPage";
				paramap = { ...this.paramap, ...this.ahciSearch };
			} else if (this.activeName == "ESCI") {
				url = "getEsciJournalPage";
				paramap = { ...this.paramap, ...this.esciSearch };
			}
			/* this.paramMap = {
				pageNo: this.paramap.pageNo,
				pageSize: this.paramap.pageSize,
				// sci检索
				sciSsciJournalName: this.sciSearch.sciSsciJournalTitle,
				sciSsciCountry: this.sciSearch.sciSsciCotegory,
				sciSsciQuartile: this.sciSearch.jciQuartile,
				// esci检索
				esciJournalName: this.esciSearch.journalName,
				esciCountry: this.esciSearch.country,
				esciQuartile: this.esciSearch.jciQuartile,
				isOaKey: this.isOaKey,
				//ahci检索
				ahciJournalName: this.ahciSearch.ahciJournalTitle,
				ahciCountry: this.ahciSearch.ahciCotegory,
				ahciQuartile: this.ahciSearch.jciQuartile,
				journalType: this.activeName
			}; */
			getJournalList(url, paramap).then((res) => {
				if (res.data.length === 0) {
					this.show = true;
				} else {
					this.show = false;
				}
				this.paramap.total = res.dataCount - 0;
				res.data.forEach(item => {
					if (item.indexFaceHref) {
						item.imageLoadFailed = true
					} else {
						false
					}
				});
				this.journalList = res.data;
				this.$forceUpdate();
				this.loading = false
				loadingInstance.close();
				this.lastLi()
			})
		},
		//去详情页
		journalDetails(index) {
			this.journalList[index].activeName = this.activeName

			if (this.activeName == "SCI") {
				this.$router.push({
					name: "journalDetailsSCI",
					query: this.journalList[index]
				})
			} else if (this.activeName == "AHCI") {
				this.$router.push({
					name: "journalDetailsAHCI",
					query: this.journalList[index]
				})

			} else if (this.activeName == "ESCI") {
				this.$router.push({
					name: "journalDetailsESCI",
					query: this.journalList[index]

				})
			}

			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'  // 可选，实现平滑滚动
			});
		},
		//更改页码
		handleCurrentChange(currentPage) {
			this.paramap.pageNo = currentPage;
			this.getData()
		},
		handleSizeChange(val) {
			this.paramap.pageSize = val
			this.getData()
		},

		//切换tab
		handleClick(tab, event) {
			this.getData()
		},
		//sci的切换搜索
		handleSearchStyleChange(value) {
			console.log(value);

			this.chooseSearch = value
		},
		//sci的JCR分区
		sciSubjectChange() {

		},


		mouseOver(journalIndex) {
			this.active = journalIndex;
		},
		mouseLeave() {
			this.active = "false";
		},
		//回车和空格键查询
		handkeyCode(e) {
			this.currentPage = 1
			if (e.keyCode === 13) {
				this.getData()
			}
		},
		//检查推荐期刊图片是否加载成功
		checkImageAvailabilityTwo(item, index) {
			// item.isNotImageAvailable = true
			// this.journalList[index] = item
			this.journalList[index].imageLoadFailed = false
		},



	}
}
</script>

<style scoped lang="scss">
.divider {
	margin: 30px 0;
}

.box {
	width: 100%;
	min-width: 1200px;
	background-color: white;
}

::v-deep .el-tabs__nav {
	padding-bottom: 10px;
}

.form_btn {
	display: flex;
	justify-content: right;
	align-items: flex-end;

	::v-deep .el-form-item__content {
		width: 100%;

		.el-button--default,
		.el-button--primary {
			width: 40%;
		}

		.el-button--text {
			width: 14%;
		}
	}
}

.swiper_box {
	background: url(../../assets/img/qk.jpg);
	height: 400px;
}

.page-content {
	width: 1200px;
	min-width: 1200px;
	margin: 0 auto;
	margin-top: 30px;

}

.el-icon-s-grid {
	position: absolute;
	padding-top: 5px;
	font-size: 30px;
	color: #1b5faa;
}

::v-deep .page-content-bottom .el-tabs__item {
	font-size: 24px;
}

::v-deep .page-content-bottom .el-tabs__item.is-active {
	color: #1b5faa;

}

::v-deep .page-content-bottom .el-tabs__item:hover {
	color: #1b5faa;
}

::v-deep .page-content-bottom .el-tabs__active-bar {
	background-color: #1b5faa;
}

// sci
.pane_first {
	width: 100%;
	margin: 0 auto;
	margin-top: 30px;
}

.pane_first .basic_box {
	display: flex;
}

.sciForm {
	box-sizing: border-box;
	width: 100%;
	margin: 20px 0;
	display: flex;
	border: 1px solid #DCDFE6;
	border-radius: 5px;
}

::v-deep .sciForm .el-form {
	width: 100%;
	margin: 0 auto;
	padding: 20px;
	display: flex !important;
	flex-wrap: wrap;
	justify-content: space-between;
}

::v-deep .sciForm .el-form-item {
	width: 32%;
	margin-bottom: 5px;

}

::v-deep .sciForm .el-form--label-top .el-form-item__label {
	padding: 0px;
	line-height: 30px;
}

::v-deep .sciForm .el-input__inner {
	height: 32px;
	line-height: 32px;
}

.eiSubject {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.eiSubject1:not(:nth-child(4n)) {
	margin-right: calc(46% / 2);
}

.journal {
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	// width: 730px;
	width: calc(calc(100% - 100px)/2);
	height: 250px;
	margin-bottom: 2%;
	transition: all .5s;

	::v-deep .el-col-9 {
		transition: all .3s;
	}

	&:hover {
		cursor: pointer;
		background-color: #f5f5f5;

		::v-deep .el-col-9 {
			transform: scale(1.05);
		}
	}
}

.content_list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 20px;
}

.pagination {
	margin: 0 auto;
	min-width: 1200px;
	text-align: center;
	margin-top: 2%;
	margin-bottom: 2%;
}

.journal1 {
	padding: 14px 0px 0px 10px;
	float: left;
	width: 30%;
}

.journal2 {
	float: right;
	width: 68%;
}

.one {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

.two {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

.title {
	color: #333333;
	font-size: 20px;
}

.jou_p {
	margin: 8px 0;
}

.fallback-bg {
	height: 222px;
	width: 168px;
	background-image: url('../../assets/img/no_photo.png');
	background-repeat: no-repeat;
	background-size: cover;
	color: white;

	.three {
		height: 42px;
		max-width: 150px;
		padding: 40px 5px 0px 24px;
		overflow: hidden;
		font-weight: bold;
	}

	.eight {
		max-width: 150px;
		padding: 50px 5px 0px 24px;
		margin-top: 10px;
		font-style: oblique;
		font-size: 12px;
	}
}

::v-deep .pane_first .basic_box .el-input .el-input__inner {
	height: 45px;
}

::v-deep .pane_first .el-input-group__append {
	background-color: rgb(24, 48, 91);
	color: #fff;
	border: 1px solid rgb(24, 48, 91);
}

::v-deep .sciForm .el-form-item__content .el-button--primary {
	background-color: rgb(24, 48, 91);
	border: 1px solid rgb(24, 48, 91);
	color: #fff;
}

::v-deep .is-active .el-radio-button__orig-radio:checked+.el-radio-button__inner {
	background-color: rgb(24, 48, 91);
	border: 1px solid rgb(24, 48, 91);
}

.lastNone {
	::v-deep .el-pagination {
		.el-pager {
			li:nth-last-child(1) {
				display: none;
			}
		}
	}
}

.lastBlock {
	::v-deep .el-pagination {
		.el-pager {
			li:nth-last-child(1) {
				display: inlibe-block;
			}
		}
	}
}
</style>