<template>
	<basic-container>
		<div class="box_news">
			<div id="" class="swiper_box">
				<pageBannerTitle :title="'新闻动态'" :entitle="'NEWS'"></pageBannerTitle>
			</div>

			<div class="box_news_container">
				<div class="page-content-top">
					<el-breadcrumb separator-class="el-icon-arrow-right" style="font-size: 14px;">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>新闻动态</el-breadcrumb-item>
						<el-breadcrumb-item>{{ activeName1 }}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="box_news_container_bottom">
					<div class="box_news_container_bottom_left">
						<el-tabs v-model="activeName1" @tab-click="handleClick1" style="margin-top: 3%;">
							<el-tab-pane label="科研要闻" name="科研要闻">
								<div v-for="(item, index) in noticeList" :key="index">
									<!-- <div class="num">{{index+1}}</div> -->
									<div class="box_news_line">
										<!-- <div class="num" style="background-color: #18305b;">{{ index + 1 }}
										</div> -->
										<img :src="item.icon" alt="">
										<div class='box_news_line_two'>
											<p class='firstLines'>
												<el-link v-if="item.fieldD != ''" :underline='false' :href="item.fieldD"
													target="view_window"
													style="color: #18305b;font-weight: normal;font-size: 16px;">{{
														item.fieldA }}
												</el-link>
												<el-link v-else :underline='false' href="javascript:void(0)"
													@click="noticeDetails(index)"
													style="color: #18305b;font-weight: normal;font-size: 16px;">{{
														item.fieldA }}
												</el-link>
											</p>
											<div style="color: #7d7d7d;font-size: 14px;">
												<div class="two">
													{{ item.fieldB }}
												</div>
												<div style="margin-top: 12px;">
													来源：{{ item.createDate }} 丨 {{ item.fieldC }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="干货分享" name="干货分享">
								<div v-for="(item, index) in noticeList" :key="index">
									<!-- <div class="num">{{index+1}}</div> -->
									<div class="box_news_line">
										<!-- <div class="num" style="background-color: #18305b;">{{ index + 1 }}
										</div> -->
										<img :src="item.icon" alt="">
										<div class='box_news_line_two'>
											<p class='firstLines'>
												<el-link v-if="item.fieldD != ''" :underline='false' :href="item.fieldD"
													target="view_window"
													style="color: #18305b;font-weight: normal;font-size: 16px;">{{
														item.fieldA }}
												</el-link>
												<el-link v-else :underline='false' href="javascript:void(0)"
													@click="noticeDetails(index)"
													style="color: #18305b;font-weight: normal;font-size: 16px;">{{
														item.fieldA }}
												</el-link>
											</p>
											<div style="color: #7d7d7d;font-size: 14px;">
												<div class="two">
													{{ item.fieldB }}
												</div>
												<div style="margin-top: 12px;">
													来源：{{ item.createDate }} 丨 {{ item.fieldC }}
												</div>
											</div>
										</div>
									</div>

								</div>
							</el-tab-pane>
							<el-tab-pane label="协会新闻" name="协会新闻">
								<div v-for="(item, index) in noticeList" :key="index">
									<!-- <div class="num">{{index+1}}</div> -->
									<div class="box_news_line">
										<!-- <div class="num" style="background-color: #18305b;">{{ index + 1 }}
										</div> -->
										<img :src="item.icon" alt="">
										<div class='box_news_line_two'>
											<p class='firstLines'>
												<el-link v-if="item.fieldD != ''" :underline='false' :href="item.fieldD"
													target="view_window"
													style="color: #18305b;font-weight: normal;font-size: 16px;">{{
														item.fieldA }}
												</el-link>
												<el-link v-else :underline='false' href="javascript:void(0)"
													@click="noticeDetails(index)"
													style="color: #18305b;font-weight: normal;font-size: 16px;">{{
														item.fieldA }}
												</el-link>
											</p>
											<div style="color: #7d7d7d;font-size: 14px;">
												<div class="two">
													{{ item.fieldB }}
												</div>
												<div style="margin-top: 12px;">
													来源：{{ item.createDate }} 丨 {{ item.fieldC }}
												</div>
											</div>
										</div>
									</div>

								</div>
							</el-tab-pane>
							<el-tab-pane label="资源下载" name="资源下载">
								<el-table :data="noticeList" style="width: 100%;margin-bottom: 40px">
									<el-table-column>
										<template #default="scope">
											<div style="display: flex; align-items: center">
												<img style="width: 20px;" :src="scope.row.fieldC">
												<span style="margin-left: 10px">{{ scope.row.fieldA }}</span>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="fieldB" />
									<el-table-column align="right">
										<template #default="scope">
											<el-button size="small" type="danger" style="background-color: #bf7c16;border-color: #bf7c16;"
												@click="handleDow(scope.$index, scope.row)">下载</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-tab-pane>
						</el-tabs>
						<div class="pagination" :class="lastNone ? 'lastNone' : 'lastBlock'">
							<el-pagination layout="  prev, pager, next, sizes,jumper"
								:current-page.sync="paramMapN.page" :page-size="paramMapN.pageSize"
								:page-sizes="[10, 20, 30, 40]" :total="paramMapN.total - 0"
								@current-change="handleCurrentChange" @size-change="handleSizeChange" :pager-count="5"
								style="padding-bottom: 15px;">
							</el-pagination>
						</div>
					</div>
					<div class="box_news_container_bottom_right">
						<h2 style=" padding-left: 145px;margin-bottom: 10px;color: #262626;">推荐会议</h2>
						<div class="top metting-card card-b meetingLoading">
							<el-card shadow="hover" class="box-card card-c" :body-style="{ padding: '0px' }"
								v-for="(item, index) in meetintFilterList" :key="index">
								<el-image style="width: 280px;height: 140px; cursor: pointer;" :src="item.fieldA"
									@click="meetingDetails(item.fieldB)"></el-image>
								<div style="padding: 14px; position: relative;">
									<div class="two" style="margin: 10px 0;">
										<span style="font-weight: bold;">{{ item.fieldC }}</span>
										<el-image :src="require('@/assets/img/hot.gif')" class="index-meeting"
											v-if="item.fieldG == '火'">
										</el-image>
									</div>
									<div class="two" style="margin: 8px 0;">
										<i class="el-icon-time" style="margin-right: 8px;"></i>
										<span>{{ item.fieldD }}</span>
									</div>
									<div class="two" style="margin-bottom: 10px;">
										<i class="el-icon-location-outline" style="margin-right: 8px;"></i>
										<span>{{ item.fieldE }}</span>
									</div>
								</div>
							</el-card>

						</div>
						<!-- <span style="cursor: pointer;text-align: right;" @click="goMeeting">查看更多></span> -->
					</div>

				</div>

			</div>
		</div>

	</basic-container>
</template>

<script>
import {
	getMeetingList
} from '@/api/index/index'
import pageBannerTitle from '@/components/common/pageBannerTitle.vue';
export default {
	components: {
		pageBannerTitle
	},
	data() {
		return {
			noticeList: [], //学术头条
			meetintList: [], //会议列表
			meetintFilterList: [],
			activeName1: "科研要闻", //学术头条
			paramMapN: {
				page: 1,
				pageSize: 10,
				total: 0,
				classification: ""
			},//新闻参数
			paramMapM: {
				page: 1,
				pageSize: 4,
				total: 0,
				classification: ""
			},//会议参数
			lastNone: ''

		}
	},
	mounted() {
		this.getTab()
		this.getAcademic()
		this.getMeeting()
	},
	methods: {
		// 获取地址栏的tab参数，如果有则赋值 activeName1 ，如果没有就默认为科研要闻
		getTab() {
			let tab = this.$route.query.tab
			if (tab) {
				this.activeName1 = tab
			} else {
				this.activeName1 = '科研要闻'
			}
		},
		lastLi() {
			this.$nextTick(_ => {
				this.lastNone = !!document.getElementsByClassName('btn-quicknext')[0]
			})
		},
		handleClick1(tab) {
			this.getAcademic()
		},
		//获取学术头条
		async getAcademic() {

			this.paramMapN.fieldH = this.activeName1 == '科研要闻' ? '学术动态' : this.activeName1,
				this.paramMapN.classification = 'notice'
			await getMeetingList(this.paramMapN).then((res) => {
				this.noticeList = res.data
				this.paramMapN.total = res.dataCount - 0
				this.lastLi()
			})
		},
		//更改页码
		handleCurrentChange(currentPage) {
			this.paramMapN.page = currentPage;
			this.getAcademic()
		},

		handleSizeChange(val) {
			this.paramMapN.pageSize = val
			this.getAcademic()
		},
		//处理下载资源
		handleDow(index, row) {
			if (this.$store.state.isLogin == true) {
				window.location.href = "http://103.120.82.111:8066/downloadLocal?fileName=" + row.fieldD;
			} else {
				alert("请您先登录！")
			}

		},

		//获取会议列表
		getMeeting() {

			this.paramMapM.classification = 'meeting'
			this.paramMapM.fieldH = ''
			getMeetingList(this.paramMapM).then((res) => {
				this.meetintList = res.data;
				this.meetintFilterList = res.data;
			})
		},
		//筛选会议类型
		createFilter() {
			let _this = this
			let nowDate = new Date()
			let filter1 = new Array();
			let filter2 = new Array();
			let filter3 = new Array();
			if (_this.tagIndex != 0) {
				_this.meetintList.filter(function (item) {
					if (item.fieldF == _this.meetingType[_this.tagIndex]) {
						filter1.push(item)
					}
				})
			} else {
				filter1 = _this.meetintList
			}
			_this.meetintFilterList = []
			_this.meetintFilterList = filter1
		},
		//打开会议列表图片
		meetingDetails(index) {
			window.open(index);
		},
		//去会议页
		goMeeting() {
			this.$router.push('/meeting')
		}

	}
}
</script>

<style scoped lang="scss">
.num {
	float: left;
	text-align: center;
	margin-right: 15px;
	color: white;
	width: 22px;
	height: 22px;
	background-color: #ff5722;
	background-blend-mode: normal, normal;
}

.swiper_box {
	min-width: 1200px;
	background: url(../assets/img/news_banner_bj.jpg);
	height: 400px;
	background-size: 100% 100%;
}

.firstLines {
	display: flex;
	color: #18305b;
	font-weight: normal;
}

.box_news {
	width: 100%;
	min-width: 1200px;
	display: flex;
	box-sizing: border-box;
	flex-direction: column;

	.box_banner {
		background: url(../assets/img/news_banner_bj.jpg) no-repeat;
		height: 400px;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.swiper_container {
			font-size: 60px;
			font-weight: bold;
			color: #fffffd;
			text-align: center;

			.title_en {
				font-size: 24px;
			}
		}
	}
}

.box_news_container {
	width: 1200px;
	min-width: 1200px;
	margin: 0 auto;
	margin-top: 30px;
}

.box_news_container_bottom {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

.box_news_container_bottom_left {
	flex: 2;
}


.box_news_container_bottom_right {
	flex: 1;
	display: flex;
	flex-direction: column;

}

.box_news_line {
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid #E4E7ED;
	margin-bottom: 10px;
	padding: 15px 0;
}

.box_news_line_two {
	display: flex;
	flex-direction: column;
}

.box_news_line_two p {
	margin: 0;
}

.box_news_line img {
	width: 60px;
	height: 60px;
	margin: 0 20px 0 15px;
	// border: 1px solid black;
	object-fit: cover;
}


::v-deep .el-table thead {
	display: none;
}

::v-deep .box_news_container_bottom .el-tabs__item {
	font-size: 18px;
}

.metting-card {
	display: flex;
	flex-wrap: wrap;
	padding-left: 145px;
	padding-bottom: 20px;
}

.tagC {
	height: 32px;
	border: none;
	background-color: #ededed;
	border-radius: 4px;
	color: #464646;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	line-height: 21px;
	padding: 5px 15px;
	font-weight: 700;
	box-sizing: border-box;
	white-space: nowrap;
}

.tagChecked {
	background-color: #375691;
	color: white;
}


.card-b {
	.el-card {

		width: 280px;
	}

	::v-deep .el-image {
		transition: all .3s;
	}

	.card-c {
		margin-top: 20px;
		margin-right: 15px;

		&:hover {
			cursor: pointer;

			::v-deep .el-image {
				transform: scale(1.1);
			}
		}
	}

}

.index-meeting {
	position: absolute;
	right: 10px;
	top: 0;
}

.pagination {
	margin: 20px 0;
}

.lastNone {
	::v-deep .el-pagination {
		.el-pager {
			li:nth-last-child(1) {
				display: none;
			}
		}
	}
}

.lastBlock {
	::v-deep .el-pagination {
		.el-pager {
			li:nth-last-child(1) {
				display: inlibe-block;
			}
		}
	}
}
</style>