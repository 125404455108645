import request from '@/utils/request';

export const getBanner1 = (paramMap) => {
	return request({
		url: '/joi/queryIamsetOtherData',
		method: 'post',
		data: paramMap
	})
}

export const getMeetingList = (paramMap) => {
	return request({
		url: '/joi/queryIamsetOtherData',
		method: 'post',
		data: paramMap
	})
}
export const getHotMeetingList = (paramMap) => {
	return request({
		url: '/joi/getRecommendMeeting',
		method: 'get',
		params: paramMap
	})
}
export const getRecommend = (paramMap) => {
	return request({
		url: '/joi/getRecommendInfo',
		method: 'get',
		params: paramMap
	})
}