<template>
	<div class="box">
		<div class="header">
			<div class="logo">
				<el-image :src="require('@/assets/img/logo.png')" style="width: 80%;"></el-image>
			</div>
			<div class="header_title">
				个人中心
			</div>
		</div>
		<div class="back pointer" @click="goBack">
			<img class="back_icon" src="@/assets/img/back.png" alt="">
			<div>
				返回至IAMSET
			</div>
		</div>
		<div class="main">
			<div class="user">
				<el-upload class="avatar-uploader" action="/joi/uploadByMinIo" :show-file-list="false"
					:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :data="{ fileName: fileName }"
					name="multipartFile">
					<div class="head_img">
						<img v-if="userInfo.avatar" :src="'https://iamset.cn/file/' + userInfo.avatar"
							alt="">
						<div v-else class="portrait" :style="{ backgroundColor: selectedColor }">
							{{ defaultPortrait }}
						</div>
					</div>
				</el-upload>
				<div class="phone">
					{{ userInfo.nickName ? userInfo.nickName : userInfo.userPhone }}
				</div>
			</div>
			<div class="user_box">
				<div class="user_title">
					基本信息
				</div>
				<div class="user_info">
					<div class="info_item">
						<div class="info_title">
							用户昵称
						</div>
						<div class="info_name">
							<div>
								{{ userInfo.nickName ? userInfo.nickName : '暂无' }}
							</div>
						</div>
					</div>
					<div class="info_item">
						<div class="info_title">
							性别
						</div>
						<div class="info_name">
							<div>
								{{ userInfo.sex ? userInfo.sex : '暂无' }}
							</div>
						</div>
					</div>
					<div class="info_item">
						<div class="info_title">
							年龄
						</div>
						<div class="info_name">
							{{ userInfo.bir ? userInfo.bir : '暂无' }}
						</div>
					</div>
					<div class="info_item">
						<div class="info_title">
							单位
						</div>
						<div class="info_name">
							{{ userInfo.unit ? userInfo.unit : '暂无' }}
						</div>
					</div>
					<div class="info_item">
						<div class="info_title">
							职业
						</div>
						<div class="info_name">
							{{ userInfo.occupation ? userInfo.occupation : '暂无' }}
						</div>
					</div>
					<div class="info_item">
						<div class="info_title">
							主要研究领域
						</div>
						<div class="info_name">
							{{ userInfo.researchAreas ? userInfo.researchAreas : '暂无' }}
						</div>
					</div>
					<div class="user_btn">
						<el-button type="primary" @click="getAmend">修改</el-button>
					</div>
				</div>
			</div>
			<div class="user_box">
				<div class="user_title">
					账号设置
				</div>
				<div class="user_idItem">
					<div class="id_title">
						密码
					</div>
					<div class="id_info">
						<div class="info">
							******
						</div>
						<div class="change pointer" @click="changePass('修改密码')">
							修改密码
						</div>
					</div>
				</div>
				<div class="user_idItem">
					<div class="id_title">
						手机
					</div>
					<div class="id_info">
						<div class="info">
							{{ userInfo.userPhone }}
						</div> 
						<div class="change pointer" @click="changePass('修改手机')">
							修改手机
						</div>
					</div>
				</div>
				<div class="user_idItem">
					<div class="id_title">
						邮箱
					</div>
					<div class="id_info">
						<div class="info">
							{{ userInfo.email ? userInfo.email : '暂无' }}
						</div>
						<div class="change pointer" @click="changePass('修改邮箱')">
							修改邮箱
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 修改个人信息 -->
		<el-dialog title="修改个人信息" :visible.sync="userVisible" width="30%" center>
			<div class="user_form">
				<el-form :model="userForm" status-icon :rules="rules" ref="userForm" label-width="100px">
					<el-form-item label="昵称">
						<el-input type="text" v-model="userForm.nickName" autocomplete="off"
							placeholder="请输入昵称"></el-input>
					</el-form-item>
					<el-form-item label="性别">
						<el-select v-model="userForm.sex" placeholder="请选择性别">
							<el-option label="男" value="男"></el-option>
							<el-option label="女" value="女"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="年龄">
						<el-date-picker type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
							placeholder="请选择日期" v-model="userForm.bir" style="width: 100%;"></el-date-picker>
					</el-form-item>
					<el-form-item label="单位">
						<el-input type="text" v-model="userForm.unit" autocomplete="off" placeholder="请输入单位"></el-input>
					</el-form-item>
					<el-form-item label="职业">
						<el-input type="text" v-model="userForm.occupation" autocomplete="off"
							placeholder="请输入职业"></el-input>
					</el-form-item>
					<el-form-item label="研究领域">
						<el-input type="text" v-model="userForm.researchAreas" autocomplete="off"
							placeholder="请输入研究领域"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit(userForm)">立即修改</el-button>
						<el-button @click="resetForm('userForm')">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog :title="dialog" :visible.sync="centerDialogVisible" width="30%" center>
			<div>
				<el-form :model="passForm" status-icon :rules="rules" ref="passForm" label-width="100px"
					class="demo-passForm">
					<el-form-item label="旧密码" prop="oldPassword">
						<el-input type="password" show-password v-model="passForm.oldPassword"
							autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="newPassword">
						<el-input type="password" show-password v-model="passForm.newPassword"
							autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="affirm">
						<el-input type="password" show-password v-model="passForm.affirm" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('passForm')">提交</el-button>
						<el-button @click="resetForm('passForm')">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog :title="dialog" :visible.sync="emailVisible" width="30%" center>
			<div>
				<el-form :model="emailForm" status-icon :rules="emailRules" ref="emailForm" label-width="100px"
					class="demo-emailForm">
					<el-form-item label="旧邮箱" prop="oldEmail">
						<el-input type="text" v-model="emailForm.oldEmail" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="新邮箱" prop="newEmail">
						<el-input type="text" v-model="emailForm.newEmail" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="确认邮箱" prop="affirm">
						<el-input type="text" v-model="emailForm.affirm" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitEmailForm('emailForm')">提交</el-button>
						<el-button @click="resetForm('emailForm')">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog :title="dialog" :visible.sync="phoneVisible" width="30%" center v-loading="phoneLoding">
			<div>
					<el-steps :active="phoneActive" finish-status="success" align-center>
					<el-step title="原手机号验证"></el-step>
					<el-step title="新手机号验证"></el-step>
					<el-step title="完成修改"></el-step>
				</el-steps>
		
				<div class="phone_box">
					<el-form :model="phoneForm" status-icon :rules="phoneRules" ref="phoneForm" label-width="auto"
						class="demo-emailForm" v-if="phoneActive === 0">
						<el-form-item label="手机号" prop="phoneNum">
							<el-input type="text" v-model="phoneForm.phoneNum" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item class="code" prop="code">
							<el-input style="width: 70%;margin-right: 30px;" prefix-icon="el-icon-receiving"
								v-model="phoneForm.code" placeholder="验证码" clearable></el-input>
							<el-button type='primary' style="width: 30%;" @click="getCode1('form')"
								:disabled="getCodeDisabled1">
								{{ getCodeText }}</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitPhoneForm('phoneForm')">下一步</el-button>
							<el-button @click="resetForm('phoneForm')">重置</el-button>
						</el-form-item>
					</el-form>
					<el-form :model="phoneForm" status-icon :rules="phoneRules" ref="phoneForm" label-width="auto"
						class="demo-emailForm" v-if="phoneActive === 1">
						<el-form-item label="手机号" prop="phoneNum">
							<el-input type="text" v-model="phoneForm.phoneNum" autocomplete="off"></el-input>
						</el-form-item>
						<el-form-item class="code" prop="code">
							<el-input style="width: 70%;margin-right: 30px;" prefix-icon="el-icon-receiving"
								v-model="phoneForm.code" placeholder="验证码" clearable></el-input>
							<el-button type='primary' style="width: 30%;" @click="getCode1('form')"
								:disabled="getCodeDisabled1">
								{{ getCodeText }}</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="setPhoneForm('phoneForm')">下一步</el-button>
							<el-button @click="lastStep('phoneForm')">上一步</el-button>
						</el-form-item>
					</el-form>
					<div v-if="phoneActive === 2" style="display: flex;justify-content: center; align-items: center;">
						<el-button type="primary" @click="close(true)">完成修改</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>


</template>

<script>
import { getUserInfo, updateInfo, setPassword, setEmail, getPhoneCode, setPhone, setPhoneTwo } from '@/api/personal'
const TIME_COUNT = 120;
export default {
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				// 在这里添加其他的密码验证逻辑
				// 比如检查密码长度、复杂度等
				// 如果验证通过则调用 callback()，否则调用 callback(new Error('错误信息'))
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.passForm.newPassword) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		var validEmail = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入邮箱'));
			} else if (value !== this.emailForm.newEmail) {
				callback(new Error('两次输入邮箱不一致!'));
			} else {
				callback();
			}
		};
		var validOldEmail = (rule, value, callback) => {
			// 如果 userInfo.email 为空，直接通过验证
			if (!this.userInfo.email) {
				callback();
				return;
			}
			// 检查邮箱是否为空
			if (value === '') {
				callback(new Error('请输入邮箱'));
			} else {
				// 使用正则表达式验证邮箱格式
				const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
				if (!emailPattern.test(value)) {
					callback(new Error('请输入有效的邮箱地址'));
				} else {
					callback(); // 验证通过
				}
			}
		};
		//校验手机号和邮箱
		var ValidPhone = (rule, value, callback) => {
			const phoneReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
			if (!value) {
				return callback(new Error('请输入手机号'))
			}
			setTimeout(() => {
				if (phoneReg.test(value)) {
					callback()
				} else {
					callback(new Error('请输入正确手机号'))
				}
			}, 100)
		};
		//校验验证码
		var code1 = (rule, value, callback) => {
			if (value == "") {
				callback(new Error("请输入验证码"));
			} else {
				callback();
			}
		};
		return {
			phoneLoding: false,
			phoneActive: 0,
			getCodeDisabled1: false,
			getCodeText: "获取验证码",
			timer: null,
			phoneVisible: false, //手机
			emailVisible: false,//邮箱弹出
			fileList: [],
			fileName: '',
			imageUrl: '', // 用于显示当前头像的 URL
			userForm: {
				unit: '',//单位
				occupation: '',//职业
				researchAreas: '',// 领域
				bir: '',//生日
				nickName: '',//昵称
				userIcon: '',//用户头像
				id: '',
			},
			selectedColor: '',
			backList: [
				"#5470c6",
				"#91cc75",
				"#fac858",
				"#ee6666",
				"#73c0de",
				"#3ba272",
				"#fc8452",
				"#9a60b4",
				"#ea7ccc"
			],
			defaultPortrait: '',//默认头像
			originalUserForm: {},// 保存原始数据
			show: false,
			active: true,
			nickname: 'Loss',
			input: '',
			centerDialogVisible: false,
			userVisible: false,
			dialog: '',
			userInfo: {},
			emailForm: {
				oldEmail: '',
				newEmail: '',
				affirm: '',
			},
			passForm: {
				oldPassword: '',
				newPassword: '',
				affirm: '',
			},
			phoneForm: {
				phoneNum: '',
				subject: 'iamset',
				business: 'RESRT',
				code: '',
				proofCode: ''
			},
			rules: {
				oldPassword: [
					{ required: true, message: '请输入旧密码', trigger: 'blur' },
					{ validator: this.validatePass, trigger: 'blur' }
				],
				newPassword: [
					{ required: true, message: '请输入新密码', trigger: 'blur' },
					{ validator: this.validatePass, trigger: 'blur' }
				],
				affirm: [
					{ required: true, message: '请确认新密码', trigger: 'blur' },
					{ validator: this.validatePass2, trigger: 'blur' }
				]
			},
			emailRules: {
				newEmail: [
					{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
					{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
				],
				affirm: [
					{ required: true, message: '请输入邮箱地址',validator: validEmail, trigger: 'blur' },
					{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
				],
			},
			phoneRules: {
				phoneNum: [{
					required: true,
					trigger: "blur",
					validator: ValidPhone,
				}],
				code: [{
					required: true,
					trigger: "blur",
					validator: code1,
				}],
			}
		}
	},
	mounted() {
		this.getInfo()
	},
	methods: {
		// 上一步
		lastStep(form) {
			this.$refs[form].resetFields();
			this.phoneActive = this.phoneActive - 1
		},
		// 手机验证第二步
		setPhoneForm(form) {
			this.$refs[form].validate((valid) => {
				if (valid) {
					this.phoneLoding = true
					setPhoneTwo(this.phoneForm).then((res) => {
		
						if (res.code === 200) {
							this.$message.success({
								showClose: true,
								duration: 3000,
								message: res.msg,
							});
							// this.phoneForm.proofCode = res.data
							this.resetForm('phoneForm')
							this.phoneLoding = false
							this.phoneActive = 2
						} else {
							this.$message.success({
								showClose: true,
								duration: 3000,
								message: res.msg,
							});
							this.resetForm('phoneForm')
							this.phoneLoding = false
						}
					})
				}
			})
		},
		// 手机验证第一步
		submitPhoneForm(form) {
			this.$refs[form].validate((valid) => {
				if (valid) {
					this.phoneLoding = true
					setPhone(this.phoneForm).then((res) => {
	
						if (res.code === 200) {
							this.$message.success({
								showClose: true,
								duration: 3000,
								message: res.msg,
							});
							// this.close()
							this.resetForm('phoneForm')
							this.phoneForm.proofCode = res.data
							this.phoneLoding = false
							this.phoneActive = 1
							this.getCodeDisabled = false;
							clearInterval(this.timer);
							this.getCodeText = "获取验证码";
							this.timer = null;
						} else {
							this.$message.success({
								showClose: true,
								duration: 3000,
								message: res.msg,
							});
							// this.close()
							this.resetForm('phoneForm')
							this.phoneLoding = false
						}
					})
				}
			})
		},
		//获取手机号验证码
		getCode1(form) {
			let _this = this
			getPhoneCode(_this.phoneForm).then((res) => {
				if (res.code == 200) {
					_this.$message.success({
						showClose: true,
						duration: 3000,
						message: "发送成功，请注意查收",
					});
					if (!_this.timer) {
						_this.getCodeText = TIME_COUNT;
						_this.getCodeDisabled = true;
						_this.timer = setInterval(() => {
							if (_this.getCodeText > 0 && _this.getCodeText <= TIME_COUNT) {
								_this.getCodeText--;
							} else {
								_this.getCodeDisabled = false;
								clearInterval(_this.timer);
								_this.getCodeText = "获取验证码";
								_this.timer = null;
							}
						}, 1000);
					}
				} else {
					_this.$message.error({
						showClose: true,
						duration: 1500,
						message: res.msg
					});
				}
			})
		},

		beforeAvatarUpload(file) {
			// 在此方法中可以添加文件类型、大小等的校验逻辑
			const isJPG = file.type === 'image/jpeg';
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isJPG) {
				this.$message.error('上传头像图片只能是 JPG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 2MB!');
			}

			return isJPG && isLt2M;
		},
		handleAvatarSuccess(response, file) {
		
			// 在这里可以将上传成功后的图片地址发送给后端保存
			this.userForm.userIcon = response.data
			this.onSubmit(this.userForm)
		},
		onSubmit(form) {
			if (JSON.stringify(this.userForm) !== JSON.stringify(this.originalUserForm)) {
				// 用户做出了修改
				updateInfo(form).then((res) => {
		
					if (res.code == 200) {
						this.$message({
							message: '修改成功',
							type: 'success'
						});
						this.getInfo()
					} else {
						this.$message({
							message: '修改失败，请稍后再试',
							type: 'error'
						});

					}
					this.close()
					// this.resetForm('userForm')
				})
			} else {
				// 用户未做出修改
				this.$message('信息未改变');
			}
		},
		getAmend() {
			this.originalUserForm = { ...this.userForm }
			this.userVisible = true
		},
		getInfo() {
			getUserInfo().then((res) => {
				this.userForm = res.data
				this.userForm = JSON.parse(JSON.stringify(res.data));
				this.userInfo = res.data
				this.fileName = res.data.avatar
				localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
				if (res.data.bir) {
					res.data.bir = this.calculateAge(res.data.bir)
				}
				this.getName()
			})
		},
		// 修改密码
		changePass(text) {
	
			if (text === '修改密码') {
				this.centerDialogVisible = true
			} else if (text === '修改邮箱') {
				this.emailVisible = true
			} else if (text === '修改手机') {
				this.phoneVisible = true
			}
		},
		goBack() {
			this.$router.go(-1);
		},
		revamp(index) {
		
			this.active = index
		},
		// 修改密码
		submitForm(formName) {

			this.$refs[formName].validate((valid) => {
				if (valid) {
					setPassword(this.passForm).then((res) => {
						console.log(res);
						if (res.code === 200) {
							this.$message({
								message: res.msg,
								type: 'success'
							});
						} else {
							this.$message.error(res.msg);
						}
					})

					this.close()
					this.resetForm('passForm')
				} else {
					console.log('error submit!!');
					// alert('失败')
					return false;
				}
			});
		},
		//修改邮箱
		submitEmailForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					setEmail(this.emailForm).then((res) => {
						console.log(res);
						if (res.code === 200) {
							this.$message({
								message: res.msg,
								type: 'success'
							});
							this.getInfo()
						} else {
							this.$message.error(res.msg);
						}
						this.close()
						this.resetForm('emailForm')
					})

					this.close()
				} else {
					console.log('error submit!!');
					// alert('失败')
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 关闭弹窗
		close(text) {
			this.userVisible = false
			this.emailVisible = false
			this.centerDialogVisible = false
			this.phoneVisible = false
			if (text) {
				this.phoneActive = 0
			}
		},
		// 默认头像
		randomSelect() {
			const randomIndex = Math.floor(Math.random() * this.backList.length); // 随机生成一个索引
			this.selectedColor = this.backList[randomIndex]; // 通过索引选取一个颜色
		},
		getName() {
			console.log(this.userInfo);
			if (!this.userInfo.avatar) {
				if (this.userInfo.nickName) {
				
					this.defaultPortrait = this.userInfo.nickName.substr(0, 1);
				} else {
					// 如果 this.users.name 不存在或为空的处理逻辑
				}
				this.randomSelect();
			}
		},
		calculateAge(birthDateString) {
			// 解析出生日期字符串
			const birthDate = new Date(birthDateString);
			// 获取当前日期
			const today = new Date();
			// 计算年龄
			let age = today.getFullYear() - birthDate.getFullYear();
			const monthDifference = today.getMonth() - birthDate.getMonth();

			// 如果当前月份还没有到生日月份，或者已经到了生日月份但是还没有到生日，则减去一岁
			if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		}
	}
}
</script>

<style lang="scss" scoped>
.box {
	width: 100%;
	min-width: 1200px;
	display: flex;
	flex-direction: column;

	.header {
		width: 1200px;
		margin:  0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px;
		border-bottom: 2px solid #070707;

		.logo {
			width: 14%;
		}

		.header_title {
			font-size: 32px;
			font-weight: bold;
		}
	}

	.back {
		// margin: 20px 200px;
		width: 1200px;
		margin: 20px auto;
		display: flex;
		align-items: center;
		color: #7C9DC6;

		.back_icon {
			width: 30px;
			height: 30px;
			display: flex;
		}
	}

	.main {
		margin: 0 auto;
		width: 1200px;
		padding: 30px 0;
		.user {
			display: flex;
			align-items: center;
			padding: 20px;
			font-size: 32px;
			font-weight: bold;

			.head_img {
				width: 100px;
				height: 100px;
				margin-right: 50px;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
			}

			.portrait {
				// background-color: #000;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
			}
		}

		.user_box {
			border: 3px solid #F5F6F7;
			padding: 20px;

			.user_title {
				border-bottom: 2px solid #F5F6F7;
				font-weight: bold;
				padding: 20px 0;
			}

			.user_info {
				.info_item {
					display: flex;
					align-items: center;
					padding: 20px 0;

					.info_title {
						min-width: 100px;
						text-align: justify;
						/* 文本水平分散 */
						color: #64748E;
						margin-right: 20px;
					}

					.info_name {
						font-weight: bold;
					}

					.revamp {
						color: #5CB6FF;
						margin-left: 20px;
						display: flex;
						align-items: center;
					}

					.save {
						display: flex;

						div {
							margin-left: 20px;
						}
					}

				}
			}
		}

		.user_btn {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.user_idItem {
			padding: 20px 0;
			display: flex;
			;
			justify-content: space-between;
			border-bottom: 1px solid #F5F6F7;

			.id_info {
				display: flex;
				align-items: center;

				.info {
					margin-right: 20px;
				}

				.change {
					font-size: 12px;
					color: #5CB6FF;
				}
			}
		}
	}

}

.phone_box {
	padding: 50px;
}

::v-deep .code .el-form-item__content {
	display: flex;
}
</style>