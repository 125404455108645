<template>
    <div class="segment-tabs">
        <div class="tabs">
            <button v-for="(tab, index) in tabs" :key="index" :class="{ active: activeTab === index }"
                @click="selectTab(index)">
                {{ tab.label }}
            </button>
        </div>
        <div class="content">
            <slot :activeTab="activeTab">
                <div v-for="(tab, index) in tabs" v-if="activeTab === index" :key="index">
                    {{ tab.content }}
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Array,
            required: true,
            default: () => [],
        },
        defaultActive: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            activeTab: this.defaultActive,
        };
    },
    methods: {
        selectTab(index) {
            this.activeTab = index;
            this.$emit('update:activeTab', index);
        },
    },
};
</script>

<style lang="scss">
.segment-tabs {
    .tabs {
        display: flex;
        justify-content: space-around;
        margin-bottom: 1rem;
        background-color: #f0f0f0;
        border-radius: 8px;

        button {
            flex: 1;
            font-size: 16px;
            padding: 0.7rem 0.5rem;
            background: #f0f0f0;
            border: none;
            cursor: pointer;

            &.active {
                background: #bf7c16 !important;
                color: white;
                font-weight: bold;
                border-radius: 4px;
            }

            &:hover {
                background: #ddd;
            }
        }
    }

    .content {
        padding: 1rem;
        border-radius: 5px;
        background: #ffffff;
    }
}
</style>