<template>
	<div class="box">
		<div class="loginBox">
			<div style="text-align: center;margin-bottom: 40px;"><img :src="require('@/assets/img/logo2-1.png')" alt=""
					style='width: 32%;' @click="goToHome"></div>
			<!-- 登录页 -->
			<div class="loginBoxCenter" v-if="type == '/login'" v-loading="loading">
				<div class="logo_title_">
					<p class="login_title">账号登录</p>
					<p class="title_scape_">/</p>
					<p @click="codeLogin">验证码登录</p>
				</div>
				<el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
					<el-form-item prop="userName">

						<el-input prefix-icon="el-icon-user" v-model="form.userName" autocomplete="off"
							placeholder="手机号码" clearable @keyup.enter.native="submitForm('form')"></el-input>
					</el-form-item>
					<el-form-item prop="passWord">
						<el-input prefix-icon="el-icon-unlock" type="password" v-model="form.passWord"
							autocomplete="off" placeholder="密码" show-password clearable
							@keyup.enter.native="submitForm('form')"></el-input>
					</el-form-item>
					<el-form-item style='margin-bottom: 15px;'>
						<el-button type="primary" @click="submitForm('form')" class="submit_btn">登录</el-button>
					</el-form-item>

					<div class='lastRows new_login_lastRows'>
						<div style="display: flex; padding: 10px 0;"><el-checkbox
								v-model="form.rememberMe">记住密码</el-checkbox></div>
						<div>
							<span class="hover_login_text" @click="forget">忘记密码</span> |
							<span class="hover_login_text" @click="register">新用户注册</span>
						</div>
					</div>
				</el-form>
			</div>
			<!-- 验证码登录 -->
			<div class="loginBoxCenter" v-if="type == '/codeLogin'" v-loading="loading">
				<div class="logo_title_">
					<p class="login_title">验证码登录</p>
					<p class="title_scape_">/</p>
					<p @click="login">账号登录</p>
				</div>
				<el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
					<el-form-item prop="phoneNum">
						<el-input prefix-icon="el-icon-user" v-model="form.phoneNum" autocomplete="off"
							placeholder="手机号码" clearable @keyup.enter.native="submitForm('form')"></el-input>
					</el-form-item>
					<el-form-item class="code" prop="code">
						<el-input style="width: 70%;margin-right: 30px;" prefix-icon="el-icon-receiving"
							v-model="form.code" placeholder="验证码" clearable
							@keyup.enter.native="submitLoginByPhone('form')"></el-input>
						<el-button type='primary' style="width: 30%;" @click="getCode1('RESET')"
							:disabled="getCodeDisabled1">
							{{ getCodeText }}</el-button>
					</el-form-item>
					<el-form-item style='margin-bottom: 15px;'>
						<el-button type="primary" @click="submitLoginByPhone('form')" class="submit_btn">登录</el-button>
					</el-form-item>
					<div class='lastRows'>
						<div>
							<span class="hover_login_text" @click="forget">忘记密码</span> |
							<span class="hover_login_text" @click="register">新用户注册</span>
						</div>
					</div>
				</el-form>
			</div>
			<!-- 注册页 -->
			<div class="loginBoxCenter" v-if="type == '/register'" v-loading="loading">
				<p class="login_title">注册</p>
				<el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
					<el-form-item prop="phoneNum">

						<el-input prefix-icon="el-icon-user" v-model="form.phoneNum" autocomplete="off"
							placeholder="手机号码 " clearable></el-input>
					</el-form-item>
					<el-form-item class="code" prop="code">
						<el-input style="width: 70%;margin-right: 30px;" prefix-icon="el-icon-receiving"
							v-model="form.code" placeholder="验证码" clearable></el-input>
						<el-button type='primary' style="width: 30%;" @click="getCode1()"
							:disabled="getCodeDisabled1">
							{{ getCodeText }}</el-button>
					</el-form-item>
					<el-form-item prop="passWord">
						<el-input prefix-icon="el-icon-unlock" type="password" v-model="form.passWord"
							autocomplete="off" placeholder="至少6位字母或数字" show-password clearable></el-input>
					</el-form-item>
					<el-form-item prop="againPassWord">
						<el-input prefix-icon="el-icon-unlock" type="password" v-model="form.againPassWord"
							autocomplete="off" placeholder="确认密码" show-password clearable></el-input>
					</el-form-item>
					<el-form-item prop="email">
						<el-input prefix-icon="el-icon-message" v-model="form.email" autocomplete="off"
							placeholder="邮箱地址(选填)" clearable></el-input>
					</el-form-item>
					<el-form-item style='margin-bottom: 15px;'>
						<el-button type="primary" @click="register1('form')" class="submit_btn">注册</el-button>
					</el-form-item>
					<div>
						<div style="text-wrap: nowrap; font-size: 14px;padding:20px 0;text-align: right;"><span>已有账号,去
								<span @click="login" style="color: #409EFF;">登录</span> </span></div>
						<div style="padding: 10px; font-size: 14px"> 注册代表您已阅读并同意<span class="hover_login_text"
								@click="getAgreement()">《用户协议》</span>和<span class="hover_login_text"
								@click="getAgreement1()">《隐私政策》</span></div>

					</div>
				</el-form>
			</div>
			<!-- 忘记密码 -->
			<div class="loginBoxCenter" v-if="type == '/forget'" v-loading="loading">
				<p class="login_title">重置密码</p>
				<el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
					<el-form-item prop="phoneNum">

						<el-input prefix-icon="el-icon-user" v-model="form.phoneNum" autocomplete="off"
							placeholder="手机号码 " clearable></el-input>
					</el-form-item>
					<el-form-item class="code" prop="code">
						<el-input style="width: 70%;margin-right: 30px;" prefix-icon="el-icon-receiving"
							v-model="form.code" placeholder="验证码" clearable></el-input>
						<el-button type='primary' style="width: 30%;" @click="getCode('form')"
							:disabled="getCodeDisabled1">
							{{ getCodeText }}</el-button>
					</el-form-item>
					<el-form-item prop="passWord">
						<el-input prefix-icon="el-icon-unlock" type="password" v-model="form.passWord"
							autocomplete="off" placeholder="至少6位字母或数字" show-password clearable></el-input>
					</el-form-item>
					<el-form-item prop="againPassWord">
						<el-input prefix-icon="el-icon-unlock" type="password" v-model="form.againPassWord"
							autocomplete="off" placeholder="确认密码" show-password clearable></el-input>
					</el-form-item>

					<el-form-item style='margin-bottom: 15px;'>
						<el-button type="primary" @click="RetrievePW('form')" class="submit_btn">重置</el-button>
					</el-form-item>
					<div class='lastRows'>
						<div
							style="text-wrap: nowrap; padding: 20px 0;font-size:14px;text-align: right;color: #409EFF;">
							<span @click="login">去登录</span>
						</div>
					</div>
				</el-form>

			</div>

		</div>
		<Agrrrment ref="agreement"></Agrrrment>
	</div>
</template>

<script>
import {
	login,
	getRegister,
	getVerificationCode,
	reset,
	loginByPhone
} from '@/api/login/login.js'
import Agrrrment from "@/components/common/agreement.vue"

const TIME_COUNT = 120;
export default {
	name: 'login',
	components: {
		Agrrrment
	},
	data() {
		//校验手机号和邮箱
		var userName2 = (rule, value, callback) => {
			const phoneReg = /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/;
			const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;

			if (!value) {
				return callback(new Error('请输入账号'))
			}

			setTimeout(() => {
				if (phoneReg.test(value) || emailReg.test(value)) {
					callback()
				} else {
					callback(new Error('请输入正确手机号'))
				}
			}, 100)
		};
		//校验验证码
		var code1 = (rule, value, callback) => {
			if (value == "") {
				callback(new Error("请输入验证码"));
			} else {
				callback();
			}
		};
		//校验密码
		var validatePass = (rule, value, callback) => {
			if (value == "") {
				callback(new Error("请输入密码"));
			} else if (value.length < 6) {
				callback(new Error("请输入6位以上的密码（包含6位）"));
			} else {
				callback();
			}
		};
		//再次校验密码
		var validatePass2 = (rule, value, callback) => {
			if (value == "") {
				callback(new Error("请输入密码"));
			} else if (value !== this.form.passWord) {
				callback(new Error("两次输入密码不一致!"));
			} else {
				callback();
			}
		};
		return {
			loading: false,
			type: '/login', //确定在登录注册忘记的页面
			timer: null,
			getCodeText: "获取验证码",
			getCodeDisabled1: false,
			form: {
				userName: "",
				code: "",
				passWord: "",
				againPassWord: "",
				phone: "iamset",
				phoneNum: "",
				subject: "iamset",
				business: '',
				email: '', //邮箱
				rememberMe: false, //是否记住密码
			},

			rules: {
				email: [{
					required: false,
					message: '请输入邮箱',
					trigger: 'blur'
				},
				{
					type: 'email',
					message: '请输入有效的邮箱地址',
					trigger: 'blur'
				}
				],
				code: [{
					required: true,
					trigger: "blur",
					validator: code1,
				}],
				userName: [{
					required: true,
					trigger: "blur",
					validator: userName2,
				}],
				passWord: [{
					required: true,
					trigger: "blur",
					validator: validatePass,
				}],
				againPassWord: [{
					required: true,
					trigger: "blur",
					validator: validatePass2,
				}],
			}, //校验规则
		}
	},
	mounted() {
		// 获取 localStorage 中的记住密码状态
		const rememberMe = localStorage.getItem('rememberMe');
		if (rememberMe) {
			this.form.rememberMe = JSON.parse(rememberMe);
		}
		// 如果记住密码，则从 localStorage 中获取用户名和密码
		if (this.form.rememberMe) {
			this.form.rememberMe = localStorage.getItem('username');
			this.form.rememberMe = localStorage.getItem('password');
		}
	},
	methods: {
		goToHome() {
			this.$router.push({ path: '/' })
		},
		// 用电户协议
		getAgreement() {
			// this.$refs.agreement.showDialog();
			this.$router.push({ path: '/userAgreement' })
		},
		// 隐私协议
		getAgreement1() {
			// this.$refs.agreement.showDialog1();
			this.$router.push({ path: '/privacy' })
		},
		//获取手机号验证码
		getCode1(type = 'REGISTER') {
			let _this = this
			_this.form.business = type
			getVerificationCode(_this.form).then((res) => {
				if (res.code == 200) {
					_this.$message.success({
						showClose: true,
						duration: 3000,
						message: "发送成功，请注意查收",
					}); 
					if (!_this.timer) {
						_this.getCodeText = TIME_COUNT;
						_this.getCodeDisabled = true;
						_this.timer = setInterval(() => {
							if (_this.getCodeText > 0 && _this.getCodeText <= TIME_COUNT) {
								_this.getCodeText--;
							} else {
								_this.getCodeDisabled = false;
								clearInterval(_this.timer);
								_this.getCodeText = "获取验证码";
								_this.timer = null;
							}
						}, 1000);
					}
				} else {
					_this.$message.error({
						showClose: true,
						duration: 1500,
						message: res.msg == '改用户已注册' ? '该用户已注册' : res.msg
					});
				}
			})
		},

		//重置手机号验证码
		getCode(form) {
			let _this = this
			_this.form.business = 'RESET'
			getVerificationCode(_this.form).then((res) => {
				if (res.code == 200) {
					_this.$message.success({
						showClose: true,
						duration: 3000,
						message: "发送成功，请注意查收",
					});

					if (!_this.timer) {
						_this.getCodeText = TIME_COUNT;
						_this.getCodeDisabled = true;
						_this.timer = setInterval(() => {
							if (_this.getCodeText > 0 && _this.getCodeText <= TIME_COUNT) {
								_this.getCodeText--;
							} else {
								_this.getCodeDisabled = false;
								clearInterval(_this.timer);
								_this.getCodeText = "获取验证码";
								_this.timer = null;
							}
						}, 1000);
					}
				} else {
					_this.$message.error({
						showClose: true,
						duration: 1500,
						message: res.msg
					});
				}
			})
		},

		//登录
		submitForm(formName) {
			const _this = this
			_this.form.phoneNum = _this.form.userName

			_this.$refs[formName].validate((valid) => {
				if (valid && !_this.loading) {
					_this.loading = true
					login(_this.form).then((res) => {
						console.log(1111);
						if (res.code == 200) {
							_this.loading = false
							_this.$message.success({
								showClose: true,
								duration: 1500,
								message: res.msg == 'ok' ? '登录成功' : res.msg,
								type: "success",
								onClose: function () {
									_this.$store.commit("setUserInfo", res.data.admin)
									_this.$store.commit("setTokenInfo", res.data.tokenInfo)
									_this.$store.commit('setIsLogin', "true")
									if (_this.form.rememberMe) {
										localStorage.setItem('username', _this.form
											.userName);
										localStorage.setItem('password', _this.form
											.password);
										localStorage.setItem('rememberMe', _this.form
											.rememberMe);
									} else {
										// 如果不记住密码，清除 localStorage 中的数据
										localStorage.removeItem('username');
										localStorage.removeItem('password');
										localStorage.removeItem('rememberMe');
									}

									window.history.go(-1)
									// _this.$router.push('/')
								},
							});
						} else {
							_this.loading = false
							_this.$message.error({
								showClose: true,
								duration: 1500,
								message: res.msg
							});
						}
					})
				} else {
					return false;
				}
			})
		},
		submitLoginByPhone(formName) {
			const _this = this
			_this.form.business = 'RESET'

			_this.$refs[formName].validate((valid) => {
				if (valid && !_this.loading) {
					_this.loading = true
					loginByPhone(_this.form).then((res) => {
						console.log(1111);
						if (res.code == 200) {
							_this.loading = false
							_this.$message.success({
								showClose: true,
								duration: 1500,
								message: res.msg == 'ok' ? '登录成功' : res.msg,
								type: "success",
								onClose: function () {
									_this.$store.commit("setUserInfo", res.data.admin)
									_this.$store.commit("setTokenInfo", res.data.tokenInfo)
									_this.$store.commit('setIsLogin', "true")
									if (_this.form.rememberMe) {
										localStorage.setItem('username', _this.form
											.userName);
										localStorage.setItem('password', _this.form
											.password);
										localStorage.setItem('rememberMe', _this.form
											.rememberMe);
									} else {
										// 如果不记住密码，清除 localStorage 中的数据
										localStorage.removeItem('username');
										localStorage.removeItem('password');
										localStorage.removeItem('rememberMe');
									}

									window.history.go(-1)
									// _this.$router.push('/')
								},
							});
						} else {
							_this.loading = false
							_this.$message.error({
								showClose: true,
								duration: 1500,
								message: res.msg
							});
						}
					})
				} else {
					return false;
				}
			})
		},
		//注册
		register1(form) {
			const _this = this
			_this.form.business = 'REGISTER'
			_this.$refs[form].validate((valid) => {
				if (valid) {
					getRegister(_this.form).then((res) => {
						if (res.code == 200) {
							_this.$message.success({
								showClose: true,
								duration: 1500,
								message: res.msg == 'ok' ? '注册成功' : res.msg,
								type: "success",
								onClose: function () {
									_this.type = "/login";
									_this.form.userName = "";
									_this.form.userPhone = "",
										_this.form.code = "";
									_this.form.passWord = "";
									_this.form.againPassWord = "";
								},
							});
						} else {
							_this.$message.error({
								showClose: true,
								duration: 1500,
								message: res.msg == '改用户已注册' ? '该用户已注册' : res.msg
							});
						}
					})
				}
			})
		},
		login() {
			this.type = '/login'
		},
		codeLogin() {
			this.type = '/codeLogin'
		},
		//重置密码
		RetrievePW(form) {
			let _this = this;
			_this.form.business = 'RESET'
			this.$refs[form].validate((valid) => {
				if (valid) {
					reset(_this.form)
						.then(function (res) {
							if (res.code == 200) {
								_this.$message.success({
									showClose: true,
									duration: 1500,
									message: res.msg,
									type: "success",
									onClose: function () {
										_this.type = "/login";
										_this.form.userName = "";
										_this.form.phoneNum = "";
										_this.form.code = "";
										_this.form.passWord = "";
										_this.form.againPassWord = "";
									},
								});
							} else {
								_this.$message.error({
									showClose: true,
									duration: 1500,
									message: res.msg
								});
							}
						})
						.catch(function () { });
				} else {
					this.$notify.error({
						title: "错误",
						message: "请检查必填项！",
					});
					return false;
				}
			});
		},
		//去忘记密码页
		forget() {
			this.type = '/forget'
			this.form.userName = "";
			this.form.code = "";
			this.form.phoneNum = "";
			this.form.passWord = "";
			this.form.againPassWord = "";
		},
		//去注册页
		register() {
			this.type = '/register'
			this.form.userName = "";
			this.form.code = "";
			this.form.phoneNum = "";
			this.form.passWord = "";
			this.form.againPassWord = "";
		},

	}
}
</script>

<style scoped lang="scss">
.box {
	width: 100%;
	height: 100vh;
	// background-color: rgb(24, 48, 91);
	background: url(../../assets/img/login-bj.png);
	background-repeat: no-repeat;
	background-size: 150%;
	background-position-x: 60%;
	background-position-y: 30%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hover_login_text {
	font-size: 14px;
	transition: all .3s;

	&:hover {
		color: #409EFF;
		cursor: pointer;
	}
}

.loginBox {
	width: 50%;
	display: flex;
	align-items: center;
	flex-direction: column;

	&:hover {
		cursor: pointer;
	}
}

.loginBoxCenter {
	width: 54%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 0px;
	background-color: #fff;
}

.logo_title_ {
	display: flex;
	align-items: baseline;
}

.title_scape_ {
	margin: 0 4px;
}

.el-form {
	width: 75%;
	margin: 0 auto;
	margin: 20px 0px;
	text-align: center;
}

.el-form-item {
	margin-bottom: 35px;
}

::v-deep .el-form-item>div {
	margin-left: 0px !important;
}

::v-deep .el-form .el-button {
	width: 100%;
}

::v-deep .el-form .submit_btn {
	height: 48px;
	font-size: 16px;
}

.lastRows {
	text-align: unset;
	display: flex;
	justify-content: flex-end;
}

.new_login_lastRows{
	justify-content: space-between;
	align-items: center;
}

::v-deep .code .el-form-item__content {
	display: flex;
}

.login_title {
	font-size: 20px;
	font-weight: bold;
}
</style>