<template>
    <div class="about_guarantee">
        <div class="about_guarantee_title">
            服务宗旨及保障
        </div>

        <div class="about_guarantee_content">
            <div class="gu_content_box content_left">
                <div class="gu_title">多样化定制方案</div>
                <div class="gu_text_">从客户需求出发，一对一答疑，多样化定制方案，先进的技术工具，切实解决问题！</div>
            </div>
            <div class="content_right">
                <div class="content_right_top">
                    <div class="gu_content_box right_top2">
                        <div class="gu_title">保证推荐文章检索</div>
                        <div class="gu_text_">所有推荐期刊均为质量有保障、稳定检索期刊，保证论文检索！</div>
                    </div>
                    <div class="gu_content_box right_top1">
                        <div class="gu_title">精准定位期刊</div>
                        <div class="gu_text_">采用最新技术手段时刻保持期刊数据更新，提供专业精准的期刊数据，精准定位期刊。</div>
                    </div>

                </div>
                <div class="content_right_bottom">
                    <div class="gu_content_box bottom_top1">
                        <div class="gu_title">支持全额退款</div>
                        <div class="gu_text_">若推荐期刊被剔除目标数据库，全额退款！</div>
                    </div>
                    <div class="gu_content_box bottom_top2">
                        <div class="gu_title">守护科研生态</div>
                        <div class="gu_text_">支持学术研究项目、举办学术活动，秉承科研诚信，守护科研生态。</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import serviceTitle from "@/components/common/serviceTitle.vue";

export default {
    components: {
        serviceTitle,
    },
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.about_guarantee {
    margin: 30px 0;

    .about_guarantee_title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .about_guarantee_content {
        display: flex;

        .gu_content_box {
            color: white;
            margin-right: 20px;
            padding: 47px 60px 47px 40px;
            background-image: url(../../assets/img/about/fuwu_3.png);
            background-repeat: no-repeat;
            background-size: 260px 220px;
            background-position: 100% 100%;
            background-color: #397ed8;
            border-radius: 4px;
            transition: all .5s;

            &:hover {
                transform: translateY(-4px);
                z-index: 9;
                box-shadow: 0 1px 3px 0 rgba(37, 48, 68, .12), 0 8px 16px -4px rgba(37, 48, 68, .16);
                background-size: 270px 230px;
            }

            .gu_title {
                font-size: 24px;
                margin-bottom: 28px;
            }

            .gu_text_ {
                line-height: 1.8;
            }
        }

        .content_left {
            flex-basis: 387px;
        }

        .content_right {
            display: flex;
            flex-direction: column;

            .right_top2,
            .bottom_top2 {
                margin-right: 0;
            }

            .content_right_top {
                display: flex;
                margin-bottom: 20px;

                .right_top1 {
                    margin-right: 0;
                    background-color: #8592a2;
                    background-image: url(../../assets/img/about/fuwu_4.png);
                }

                .right_top2 {
                    margin-right: 20px;
                    background-position-x: 196px;
                    background-position-y: 68px;
                    background-color: #b42108;
                    background-image: url(../../assets/img/about/dun1.png);
                    background-size: 220px 220px;
                }

                .right_top1,
                .right_top2 {
                    flex: 0 1 auto;
                    flex-basis: 387px;
                    height: 170px;
                }
            }
        }

        .content_right_bottom {
            margin-bottom: 0;
            display: flex;

            .bottom_top1 {
                margin-right: 20px;
                background-color: #aeaeae;
                background-image: url(../../assets/img/about/tuikuan.png);
            }

            .bottom_top2 {
                background-color: #4bbe8e;
                background-image: url(../../assets/img/about/fuwu_2.png);
            }

            .bottom_top1,
            .bottom_top2 {
                flex: 0 1 auto;
                flex-basis: 387px;
                height: 170px;
            }
        }
    }
}
</style>