<template>
    <div class="about_us">
        <div class="us_left">
            <div class="left_title">协会简介</div>
            <div class="left_content">国际管理科学与工程技术协会（International Association of Management Science and Engineering
                Technology,
                简称IAMSET），发源于2010年，2011年在香港政府注册成立（注册号：63604471），存续为合法运营的专业机构。
                2015年，经国家工商总局批复，IAMSET®正式成为合法存续的注册商标。
                IAMSET（艾慕赛特）协会拥有成熟而品牌化的运营团队，依托专业诚信的运营理念，精于耕耘科学研究前沿。过去几年，协会成功举办数百场国际学术会议，参与会议交流人数上万，达成的学术交流硕果累累。协会曾成功邀请国家“千人计划”专家、中国工程院院士、IEEE
                Fellow Member、IEEE Senior
                Member、国家杰出青年基金获得者，WOS高被引科学家，知名期刊主编等各类知名专家学者与会交流，为会议做主讲嘉宾报告，探索学术热点及学科前沿。并通过中外学术交流专家人才储备库，拓宽引智途径，达成国际高层次人才引进等深度合作。
                同时，会议遴选的优秀论文或被会议论文集收录，或被成功推荐至经同行评议的国际EI & SCI & SSCI期刊发表。</div>
        </div>
        <div class="us_right">
            <div class="image_top">
                <img src="../../assets/img/logo_top.jpg" alt="">
            </div>
            <div class="image_buttom">
                <img src="../../assets/img/logo_buttom.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import serviceTitle from "@/components/common/serviceTitle.vue";

export default {
    components: {
        serviceTitle
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.about_us {
    display: flex;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 50px;

    .us_left {
        .left_title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .left_content {
            line-height: 1.8;
            padding-right: 100px;
            font-size: 14px;
            color: #545454;
        }
    }

    .us_right {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image_top {
            img {
                width: 150px;
            }
        }

        .image_buttom {
            img {
                width: 200px;
            }
        }
    }
}
</style>